import React, { Component } from "react";
import { AnimatePresence, motion } from "framer-motion";
import withRouter from "../../../withRouter";
import { connect } from "react-redux";
import { getDetailPemohonobyek } from "../../../actions/pemohonobyeks";
import {
  openPemohonobyek,
  closePemohonobyek,
} from "../../../actions/pemohonobyeks";
import {
  openTindakanpemohon,
  closeTindakanpemohon,
  openOrderTindakanpemohon,
  closeOrderTindakanpemohon,
} from "../../../actions/tindakanpemohons";

// import CardTablePemohonobyekobyeks from "../../../components/Cards/Pemohons/CardTablePemohonobyeks";
import AddPemohonobyekModal from "../../../components/Modals/Pemohon/AddPemohonobyekModal";
import { getDetailObyek } from "../../../actions/obyeks";
import CardListPemohonobyek from "../../../components/Cards/Pemohons/CardListPemohonobyek";
import EditTindakanPemohonobyek from "../../../components/Modals/Pemohon/EditTindakanPemohonobyek";
import UpdateOrderTindakanpemohon from "../../../components/Modals/Pemohon/UpdateOrderTindakanpemohon";

export class DetailObyek extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPemohonobyek: {},
      selectedTindakanpemohon: {},
      mode: "add",
      reload: false,
      isOpenOrderPemohonobyek: false,
      tindakanpemohons: [],
    };
    this.closeModalPemohonobyek = this.closeModalPemohonobyek.bind(this);
    this.openModalPemohonobyek = this.openModalPemohonobyek.bind(this);
    this.closeModalTindakanpemohon = this.closeModalTindakanpemohon.bind(this);
    this.openModalTindakanpemohon = this.openModalTindakanpemohon.bind(this);
    this.closeModalOrderTindakanpemohon =
      this.closeModalOrderTindakanpemohon.bind(this);
    this.openModalOrderTindakanPemohon =
      this.openModalOrderTindakanPemohon.bind(this);
  }
  closeModalPemohonobyek = () => {
    this.props.closePemohonobyek();
  };

  // closeModalPemohon = () => {
  //   this.props.closeModalPemohonobyek();
  // };

  closeModalTindakanpemohon = () => {
    this.props.closeTindakanpemohon();
  };

  openModalPemohonobyek = ({
    mode = "add",
    selectedPemohonobyek = {},
  } = {}) => {
    if (selectedPemohonobyek && selectedPemohonobyek.obyek) {
    } else {
      selectedPemohonobyek.obyek = this.props.params
        ? this.props.params.obyid
        : null;
    }
    this.setState({ selectedPemohonobyek, mode });
    this.props.openPemohonobyek();
  };

  openModalTindakanpemohon = ({
    mode = "add",
    selectedTindakanpemohon = {},
  } = {}) => {
    this.setState({ selectedTindakanpemohon, mode });
    this.props.openTindakanpemohon();
  };

  openModalOrderTindakanPemohon = ({ tindakanpemohons = [] }) => {
    if (tindakanpemohons.length > 0) {
      this.setState({ tindakanpemohons });
      this.props.openOrderTindakanpemohon();
    }
  };
  closeModalOrderTindakanpemohon = () => {
    this.props.closeOrderTindakanpemohon();
  };

  //   static propTypes = {};
  render() {
    // const {
    //   getDetailPemohonobyekLoading,
    //   getDetailPemohonobyekResult,
    //   getDetailPemohonobyekError,
    // } = this.props.pemohonobyeks;
    // let obyekLength = getDetailPemohonobyekResult.jenispermohonans
    //   ? getDetailPemohonobyekResult.jenispermohonans.length + 1
    //   : 1;
    const obyId = this.props.params.obyid;
    return (
      <motion.div
        className="flex flex-wrap"
        initial={{ opacity: 0, scale: 0.7 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 1.2 }}
        transition={{ duration: 0.3 }}
      >
        {/* <div className="w-full lg:w-1/4 px-4 mt-8 relative">
          <CardDetailObyek obyeks={this.props.obyeks} />
        </div> */}
        {/* <div className="w-full lg:w-3/4 px-4 mt-8 relative"> */}
        {/* <CardTablePemohonobyekobyeks
            obyId={obyId}
            openModalPemohonobyek={this.openModalPemohonobyek}
          /> */}
        <CardListPemohonobyek
          obyId={obyId}
          openModalPemohonobyek={this.openModalPemohonobyek}
          openModalTindakanpemohon={this.openModalTindakanpemohon}
          openModalOrderTindakanPemohon={this.openModalOrderTindakanPemohon}
        />
        {/* </div> */}

        <AnimatePresence
          initial={false}
          mode="wait"
          onExitComplete={() => null}
        >
          {this.props.isOpenPemohonobyek && (
            <AddPemohonobyekModal
              handleClose={this.closeModalPemohonobyek}
              mode={this.state.mode}
              pemohonobyek={this.state.selectedPemohonobyek}
              permohonan={
                this.props.obyeks.getDetailObyekResult
                  ? this.props.obyeks.getDetailObyekResult.permohonan
                  : null
              }
            />
          )}
          {this.props.isOpenTindakanpemohon && (
            <EditTindakanPemohonobyek
              handleClose={this.closeModalTindakanpemohon}
              mode={this.state.mode}
              tindakanpemohon={this.state.selectedTindakanpemohon}
            />
          )}
          {this.props.isOpenOrderTindakanpemohon && (
            <UpdateOrderTindakanpemohon
              handleClose={this.closeModalOrderTindakanpemohon}
              tindakanpemohons={this.state.tindakanpemohons}
            />
          )}
        </AnimatePresence>
      </motion.div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isOpenPemohonobyek: state.pemohonobyeks.isOpenPemohonobyek,
    tambahPemohonobyekResult: state.pemohonobyeks.tambahPemohonobyekResult,
    updatePemohonobyekResult: state.pemohonobyeks.updatePemohonobyekResult,
    obyeks: state.obyeks,
    isOpenTindakanpemohon: state.tindakanpemohons.isOpenTindakanpemohon,
    isOpenOrderTindakanpemohon:
      state.tindakanpemohons.isOpenOrderTindakanpemohon,
  };
};

const mapDispatchToProps = {
  getDetailPemohonobyek,
  openPemohonobyek,
  closePemohonobyek,
  getDetailObyek,
  openTindakanpemohon,
  closeTindakanpemohon,
  openOrderTindakanpemohon,
  closeOrderTindakanpemohon,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DetailObyek));
