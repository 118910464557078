import {
  GET_LIST_PEMOHON,
  TAMBAH_PEMOHON,
  UPDATE_PEMOHON,
  DELETE_PEMOHON,
  CLOSE_PEMOHON,
  OPEN_PEMOHON,
  GET_DETAIL_PEMOHON,
  GET_PEMOHON_NIK,
  CLOSE_PEMOHON_NIK
} from "../actions/pemohons";

const initialState = {
  getListPemohonLoading: false,
  getListPemohonResult: false,
  getListPemohonError: false,

  tambahPemohonLoading: false,
  tambahPemohonResult: false,
  tambahPemohonError: false,

  getDetailPemohonLoading: false,
  getDetailPemohonResult: false,
  getDetailPemohonError: false,

  getPemohonNikLoading: false,
  getPemohonNikResult: false,
  getPemohonNikError: false,

  updatePemohonLoading: false,
  updatePemohonResult: false,
  updatePemohonError: false,

  deletePemohonLoading: false,
  deletePemohonResult: false,
  deletePemohonError: false,

  isOpenPemohon: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_PEMOHON:
      return {
        ...state,
        getListPemohonLoading: action.payload.loading,
        getListPemohonResult: action.payload.data,
        getListPemohonError: action.payload.errorMessage,
      };
    case GET_DETAIL_PEMOHON:
      return {
        ...state,
        getDetailPemohonLoading: action.payload.loading,
        getDetailPemohonResult: action.payload.data,
        getDetailPemohonError: action.payload.errorMessage,
      };

      case GET_PEMOHON_NIK:
        return {
          ...state,
          getPemohonNikLoading: action.payload.loading,
          getPemohonNikResult: action.payload.data,
          getPemohonNikError: action.payload.errorMessage,
        };

        case CLOSE_PEMOHON_NIK:
          return {
            ...state,
            getPemohonNikLoading: false,
            getPemohonNikResult: false,
            getPemohonNikError: false,
          };
    
    case TAMBAH_PEMOHON:
      return {
        ...state,
        tambahPemohonLoading: action.payload.loading,
        tambahPemohonResult: action.payload.data,
        tambahPemohonError: action.payload.errorMessage,
      };

    case UPDATE_PEMOHON:
      return {
        ...state,
        updatePemohonLoading: action.payload.loading,
        updatePemohonResult: action.payload.data,
        updatePemohonError: action.payload.errorMessage,
      };

    case DELETE_PEMOHON:
      return {
        ...state,
        deletePemohonLoading: action.payload.loading,
        deletePemohonResult: action.payload.data,
        deletePemohonError: action.payload.errorMessage,
      };
    case OPEN_PEMOHON:
      return {
        ...state,
        isOpenPemohon: true,
        tambahPemohonResult: false,
        updatePemohonResult: false,
      };
    case CLOSE_PEMOHON:
      return {
        ...state,
        isOpenPemohon: false,
        updatePemohonError: false,
        tambahPemohonError: false,
      };
    default:
      return state;
  }
}
