import {
  GET_LIST_PEMOHONOBYEK,
  TAMBAH_PEMOHONOBYEK,
  UPDATE_PEMOHONOBYEK,
  DELETE_PEMOHONOBYEK,
  CLOSE_PEMOHONOBYEK,
  OPEN_PEMOHONOBYEK,
  GET_DETAIL_PEMOHONOBYEK,
} from "../actions/pemohonobyeks";

const initialState = {
  getListPemohonobyekLoading: false,
  getListPemohonobyekResult: false,
  getListPemohonobyekError: false,

  tambahPemohonobyekLoading: false,
  tambahPemohonobyekResult: false,
  tambahPemohonobyekError: false,

  getDetailPemohonobyekLoading: false,
  getDetailPemohonobyekResult: false,
  getDetailPemohonobyekError: false,

  updatePemohonobyekLoading: false,
  updatePemohonobyekResult: false,
  updatePemohonobyekError: false,

  deletePemohonobyekLoading: false,
  deletePemohonobyekResult: false,
  deletePemohonobyekError: false,

  isOpenPemohonobyek: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_PEMOHONOBYEK:
      return {
        ...state,
        getListPemohonobyekLoading: action.payload.loading,
        getListPemohonobyekResult: action.payload.data,
        getListPemohonobyekError: action.payload.errorMessage,
      };
    case GET_DETAIL_PEMOHONOBYEK:
      return {
        ...state,
        getDetailPemohonobyekLoading: action.payload.loading,
        getDetailPemohonobyekResult: action.payload.data,
        getDetailPemohonobyekError: action.payload.errorMessage,
      };

    case TAMBAH_PEMOHONOBYEK:
      return {
        ...state,
        tambahPemohonobyekLoading: action.payload.loading,
        tambahPemohonobyekResult: action.payload.data,
        tambahPemohonobyekError: action.payload.errorMessage,
      };

    case UPDATE_PEMOHONOBYEK:
      return {
        ...state,
        updatePemohonobyekLoading: action.payload.loading,
        updatePemohonobyekResult: action.payload.data,
        updatePemohonobyekError: action.payload.errorMessage,
      };

    case DELETE_PEMOHONOBYEK:
      return {
        ...state,
        deletePemohonobyekLoading: action.payload.loading,
        deletePemohonobyekResult: action.payload.data,
        deletePemohonobyekError: action.payload.errorMessage,
      };
    case OPEN_PEMOHONOBYEK:
      return {
        ...state,
        isOpenPemohonobyek: true,
      };
    case CLOSE_PEMOHONOBYEK:
      return {
        ...state,
        isOpenPemohonobyek: false,
        updatePemohonobyekError: false,
        tambahPemohonobyekError: false,
      };
    default:
      return state;
  }
}
