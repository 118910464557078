import React, { useEffect } from "react";
import { listLatestPermohonan } from "../../../actions/permohonans";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from "react-router-dom";
import { format } from "date-fns";

export const CardTableLatestPermohonan = ({
  getListPermohonanError,
  getListPermohonanLoading,
  getListPermohonanResult,
  listLatestPermohonan,
}) => {
  const color = "light";
  useEffect(() => {
    listLatestPermohonan();
  }, []);

  if (getListPermohonanError) {
    return (
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <h1>{getListPermohonanError.message}</h1>
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="text-center flex justify-between w-full">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                Latest Permohonan
              </h3>
              <div className="relative flex flex-wrap items-stretch mb-3">
                <Link
                  className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  to={`/admin/permohonans/`}
                >
                  More
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {getListPermohonanLoading && (
            <div className="w-full absolute justify-center flex z-30 m-auto flex-col items-center bg-white h-32">
              <BeatLoader color="#36d7b7" size={20} />
            </div>
          )}
          {getListPermohonanResult ? (
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Tanggal
                  </th>
                  <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Nama Pemohon
                  </th>
                  <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Identitas
                  </th>
                  <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Jenis Permohonan
                  </th>
                  <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Pilihan
                  </th>
                </tr>
              </thead>
              <tbody>
                {getListPermohonanResult.length > 0 &&
                  getListPermohonanResult.map((permohonan, index) => {
                    return (
                      <tr key={permohonan.id}>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          {format(new Date(permohonan.created), "dd-MM-yyyy")}
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          <i className="fas fa-circle text-orange-500 mr-2"></i>{" "}
                          {permohonan.nama_pemohon}
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          {permohonan.identitas}
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 flex-row flex-wrap justify-between items-start md:w-80">
                          {permohonan.jenispermohonans &&
                            permohonan.jenispermohonans.map((d, k) => (
                              <span
                                key={k}
                                className="text-xs font-semibold inline-block py-1 px-2 rounded text-lightBlue-600 bg-lightBlue-200 uppercase last:mr-0 mr-1 mb-1"
                              >
                                {d.nama_jenis_permohonan}
                              </span>
                            ))}
                        </td>

                        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right flex flex-row justify-around content-center items-center w-24">
                          <Link
                            className="text-lightBlue-500 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            to={`/admin/permohonans/${permohonan.id}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-5 h-5"
                            >
                              <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                              <path
                                fillRule="evenodd"
                                d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getListPermohonanError: state.permohonans.getListPermohonanError,
  getListPermohonanLoading: state.permohonans.getListPermohonanLoading,
  getListPermohonanResult: state.permohonans.getListPermohonanResult,
});

export default connect(mapStateToProps, { listLatestPermohonan })(
  CardTableLatestPermohonan
);
