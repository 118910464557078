import * as React from "react";
import { useMotionValue, Reorder, useDragControls } from "framer-motion";
import { useRaisedShadow } from "../Surats/use-raised-shadow";
import { ReorderIcon } from "../Surats/Icon";

export const ItemTindakanpemohon = ({ tindakanpemohon }) => {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const dragControls = useDragControls();

  return (
    <Reorder.Item
      value={tindakanpemohon}
      id={tindakanpemohon.id}
      style={{ boxShadow, y }}
      dragListener={false}
      dragControls={dragControls}
      as="tr"
    >
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap py-2">
        <ReorderIcon dragControls={dragControls} />
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap py-2">
        {tindakanpemohon.tindakan.uraian}
      </td>
    </Reorder.Item>
  );
};
