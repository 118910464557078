import { dispatchError, dispatchLoading, dispatchSuccess } from "../utils";
import axios from "axios";

export const GET_LIST_PERMOHONAN = "GET_LIST_PERMOHONAN";
export const TAMBAH_PERMOHONAN = "TAMBAH_PERMOHONAN";
export const GET_DETAIL_PERMOHONAN = "GET_DETAIL_PERMOHONAN";
export const UPDATE_PERMOHONAN = "UPDATE_PERMOHONAN";
export const DELETE_PERMOHONAN = "DELETE_PERMOHONAN";
export const OPEN_PERMOHONAN = "OPEN_PERMOHONAN";
export const CLOSE_PERMOHONAN = "CLOSE_PERMOHONAN";

export const listPermohonan =
  ({
    page = 1,
    order = "id",
    search = "",
    startDate = "",
    endDate = "",
    active=1
  } = {}) =>
  (dispatch) => {
    // &startDate=${startDate}&endDate=${endDate}
    dispatchLoading(dispatch, GET_LIST_PERMOHONAN);
    axios
      .get(
        `/api/permohonans/?page=${page} ${
          order ? "&order_by=" + order : ""
        }&startDate=${startDate}&endDate=${endDate}${
          search ? "&nama_pemohon=" + search + "&identitas=" + search : ""
        }&active=${active}`,
        tokenConfig()
      )
      .then((res) => {
        dispatchSuccess(dispatch, GET_LIST_PERMOHONAN, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_LIST_PERMOHONAN, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };
export const listLatestPermohonan = () => (dispatch) => {
  dispatchLoading(dispatch, GET_LIST_PERMOHONAN);
  axios
    .get(`/api/permohonans/latest`, tokenConfig())
    .then((res) => {
      dispatchSuccess(dispatch, GET_LIST_PERMOHONAN, res.data);
    })
    .catch((err) => {
      dispatchError(dispatch, GET_LIST_PERMOHONAN, {
        msg: err.response.data,
        status: err.response.status,
      });
    });
};

export const getDetailPermohonan =
  ({ id }) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_DETAIL_PERMOHONAN);
    axios
      .get(`/api/permohonans/detail/${id}`, tokenConfig())
      .then((res) => {
        dispatchSuccess(dispatch, GET_DETAIL_PERMOHONAN, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_DETAIL_PERMOHONAN, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const openPermohonan = () => (dispatch) => {
  dispatchLoading(dispatch, OPEN_PERMOHONAN);
};
export const closePermohonan = () => (dispatch) => {
  dispatch({ type: CLOSE_PERMOHONAN });
};

export const addPermohonan =
  ({ jenispermohonans, nama_pemohon, identitas, active }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({ jenispermohonans, nama_pemohon, identitas, active });
    dispatchLoading(dispatch, TAMBAH_PERMOHONAN);
    dispatch({ type: OPEN_PERMOHONAN });
    axios
      .post("/api/permohonans/", body, config)
      .then((res) => {
        dispatchSuccess(dispatch, TAMBAH_PERMOHONAN, res.data);
        dispatch({ type: CLOSE_PERMOHONAN });
      })
      .catch((err) => {
        dispatchError(dispatch, TAMBAH_PERMOHONAN, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_PERMOHONAN });
      });
  };

export const updatePermohonan =
  ({ id, jenispermohonans, nama_pemohon, identitas, active }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({ jenispermohonans, nama_pemohon, identitas, active });
    dispatchLoading(dispatch, TAMBAH_PERMOHONAN);
    dispatch({ type: OPEN_PERMOHONAN });
    axios
      .patch(`/api/permohonans/${id}/`, body, config)
      .then((res) => {
        dispatchSuccess(dispatch, UPDATE_PERMOHONAN, res.data);
        dispatch({ type: CLOSE_PERMOHONAN });
      })
      .catch((err) => {
        dispatchError(dispatch, UPDATE_PERMOHONAN, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_PERMOHONAN });
      });
  };

export const deletePermohonan = (id) => (dispatch) => {
  dispatchLoading(dispatch, DELETE_PERMOHONAN);
  axios
    .delete(`/api/permohonans/${id}/`, tokenConfig())
    .then((res) => {
      dispatchSuccess(dispatch, DELETE_PERMOHONAN, "DATA BERHASIL DIHAPUS");
    })
    .catch((err) =>
      dispatchError(dispatch, DELETE_PERMOHONAN, {
        msg: err.response.data,
        status: err.response.status,
      })
    );
};

export const tokenConfig = () => {
  // Get token from state

  // Headers
  const config = {
    // baseURL: "http://127.0.0.1:8000",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  // if (token) {
  //   config.headers["Authorization"] = `Token ${token}`;
  // }

  return config;
};
