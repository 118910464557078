// import "./assets/styles/index.css";

// function App() {
//   return (
//     <div className="App">
//       <h1 className="text-3xl font-bold underline text-red-400">
//         Hello world!
//       </h1>
//     </div>
//   );
// }

// export default App;

import axios from "axios";
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./assets/styles/index.css";

import "@fortawesome/fontawesome-free/css/all.min.css";
import { useEffect } from "react";
import { loadUser } from "./actions/auth";
import { Provider } from "react-redux";
import store from "./store";
import { AnimatePresence } from "framer-motion";
import AnimatedRoutes from "./AnimatedRoutes";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <AnimatedRoutes />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
