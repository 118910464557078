import { dispatchError, dispatchLoading, dispatchSuccess } from "../utils";
import axios from "axios";

export const GET_LIST_SURATOBYEK = "GET_LIST_SURATOBYEK";
export const GET_LIST_SURAT_IN_SURATOBYEK = "GET_LIST_SURAT_IN_SURATOBYEK";
export const GET_LIST_SURAT_NOT_IN_SURATOBYEK =
  "GET_LIST_SURAT_NOT_IN_SURATOBYEK";

export const TAMBAH_SURATOBYEK = "TAMBAH_SURATOBYEK";
export const GET_DETAIL_SURATOBYEK = "GET_DETAIL_SURATOBYEK";
export const UPDATE_SURATOBYEK = "UPDATE_SURATOBYEK";
export const DELETE_SURATOBYEK = "DELETE_SURATOBYEK";
export const OPEN_SURATOBYEK = "OPEN_SURATOBYEK";
export const CLOSE_SURATOBYEK = "CLOSE_SURATOBYEK";
export const DO_CETAK = "DO_CETAK";
export const STOP_CETAK = "STOP_CETAK";
export const stopCetakSurat = () => (dispatch) => {
  dispatch({ type: STOP_CETAK });
};

export const listTableSuratobyek =
  ({ oby_id, page = 1, order = "urutan", search = "" } = {}) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_SURATOBYEK);
    axios
      .get(
        `/api/suratobyeks/obyeks/${oby_id}?page=${page} ${
          order ? "&order_by=" + order : ""
        }${search ? "&nama_surat=" + search : ""}`,
        tokenConfig()
      )
      .then((res) => {
        dispatchSuccess(dispatch, GET_LIST_SURATOBYEK, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_LIST_SURATOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const listSuratobyek =
  ({ oby_id }) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_SURATOBYEK);
    axios
      .get(`/api/suratobyeks/obyeks/${oby_id}/list`, tokenConfig())
      .then((res) => {
        dispatchSuccess(dispatch, GET_LIST_SURATOBYEK, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_LIST_SURATOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };
export const listSuratInSuratobyek = (oby_id) => (dispatch) => {
  dispatchLoading(dispatch, GET_LIST_SURAT_IN_SURATOBYEK);
  axios
    .get(`/api/suratinsuratobyek/obyeks/${oby_id}/`, tokenConfig())
    .then((res) => {
      dispatchSuccess(dispatch, GET_LIST_SURAT_IN_SURATOBYEK, res.data);
    })
    .catch((err) => {
      dispatchError(dispatch, GET_LIST_SURAT_IN_SURATOBYEK, {
        msg: err.response.data,
        status: err.response.status,
      });
    });
};
export const listSuratNotInSuratobyek = (oby_id) => (dispatch) => {
  dispatchLoading(dispatch, GET_LIST_SURAT_NOT_IN_SURATOBYEK);
  axios
    .get(`/api/suratnotinsuratobyek/obyeks/${oby_id}/`, tokenConfig())
    .then((res) => {
      dispatchSuccess(dispatch, GET_LIST_SURAT_NOT_IN_SURATOBYEK, res.data);
    })
    .catch((err) => {
      dispatchError(dispatch, GET_LIST_SURAT_NOT_IN_SURATOBYEK, {
        msg: err.response.data,
        status: err.response.status,
      });
    });
};

export const getDetailSuratobyek =
  ({ id }) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_DETAIL_SURATOBYEK);
    axios
      .get(`/api/suratobyeks/${id}`, tokenConfig())
      .then((res) => {
        dispatchSuccess(dispatch, GET_DETAIL_SURATOBYEK, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_DETAIL_SURATOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const openSuratobyek = () => (dispatch) => {
  dispatchLoading(dispatch, OPEN_SURATOBYEK);
};
export const closeSuratobyek = () => (dispatch) => {
  dispatch({ type: CLOSE_SURATOBYEK });
};

export const addSuratobyek =
  ({ surat, obyek }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      surat,
      obyek,
    });
    dispatchLoading(dispatch, TAMBAH_SURATOBYEK);
    dispatch({ type: OPEN_SURATOBYEK });
    axios
      .post("/api/suratobyeks/", body, config)
      .then((res) => {
        dispatchSuccess(dispatch, TAMBAH_SURATOBYEK, res.data);
        dispatch({ type: CLOSE_SURATOBYEK });
      })
      .catch((err) => {
        dispatchError(dispatch, TAMBAH_SURATOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_SURATOBYEK });
      });
  };

export const updateSuratobyek =
  ({
    id,
    surat,
    obyek,
    urutan,
    nomor_surat,
    tanggal_surat,
    customfields,
    saksis,
    stafs
  }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      id,
      obyek,
      surat,
      urutan,
      nomor_surat,
      tanggal_surat,
      customfields,
      saksis,
      stafs
    });
    dispatchLoading(dispatch, UPDATE_SURATOBYEK);
    dispatch({ type: OPEN_SURATOBYEK });
    axios
      .patch(`/api/suratobyeks/${id}/`, body, config)
      .then((res) => {
        dispatchSuccess(dispatch, UPDATE_SURATOBYEK, res.data);
        dispatch({ type: CLOSE_SURATOBYEK });
        dispatch({ type: DO_CETAK });
      })
      .catch((err) => {
        dispatchError(dispatch, UPDATE_SURATOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_SURATOBYEK });
      });
  };
export const updateOrderSuratobyekDb = (suratobyeks) => (dispatch) => {
  updateOrderId(suratobyeks.results).then((list) => {
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const xbody = [...list];
    const body = xbody.map((d, i) => ({ ...d, ["surat"]: d.surat.id }));
    axios
      .post(`/api/suratobyekords/updateorder/`, body, config)
      .then((res) => {
        dispatchSuccess(dispatch, GET_LIST_SURATOBYEK, suratobyeks);
      })
      .catch((err) => {
        dispatchError(dispatch, UPDATE_SURATOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });

    // console.log(body);
    // body.surat = body.surat.id;
    //bulk-update sebelumnya
    // axios
    //   .put(`/api/suratobyeks-bulkupdate/`, body, config)
    //   .then((res) => {
    //     dispatchSuccess(dispatch, GET_LIST_SURATOBYEK, suratobyeks);
    //   })
    //   .catch((err) => {
    //     dispatchError(dispatch, UPDATE_SURATOBYEK, {
    //       msg: err.response.data,
    //       status: err.response.status,
    //     });
    //   });

    // dispatchSuccess(dispatch, GET_LIST_SURATOBYEK, suratobyeks)
  });
  // updateOrderId(suratobyeks, null, dispatch).then(
  //   (list) => console.log(list)
  // dispatchSuccess(dispatch, GET_LIST_SURATOBYEK, suratobyeks)
  // );
};

export const updateListSuratobyek = (obyid, surats) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  //dispatchLoading(dispatch, GET_LIST_SURAT_IN_SURATOBYEK);
  const body = { surats };
  axios
    .put(`/api/obyeks/updlistsuratobyek/${obyid}`, body, config)
    .then((res) => {
      //  dispatchSuccess(dispatch, GET_LIST_SURAT_IN_SURATOBYEK, surats);
    })
    .catch((err) => {
      dispatchError(dispatch, GET_LIST_SURAT_IN_SURATOBYEK, {
        msg: err.response.data,
        status: err.response.status,
      });
    });
};

export const updateOrderSuratobyek = (suratobyeks) => (dispatch) => {
  dispatchSuccess(dispatch, GET_LIST_SURATOBYEK, suratobyeks);
  const config = {
    // baseURL: "http://127.0.0.1:8000",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // // Request Body
  const body = JSON.stringify(suratobyeks);
  //  dispatchLoading(dispatch, GET_LIST_SURATOBYEK);
  axios
    .patch(`/api/suratobyeks/updateorder/`, body, config)
    .then((res) => {
      dispatchSuccess(dispatch, UPDATE_SURATOBYEK, res.data);
    })
    .catch((err) => {
      dispatchError(dispatch, UPDATE_SURATOBYEK, {
        msg: err.response.data,
        status: err.response.status,
      });
    });
};
export const updateOrderId = (list) => {
  return new Promise((resolve, reject) => {
    let pmobjects = [];
    for (let index = 0; index < list.length; index++) {
      let obj = {
        id: list[index].id,
        urutan: index,
        surat: list[index].surat,
      };
      pmobjects.push(obj);
    }
    resolve(pmobjects);
  });
};

export const updateOrder = (list, id, dispatch) => {
  return new Promise((resolve, reject) => {
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    for (let index = 0; index < list.length; index++) {
      if (list[index].children.length > 0) {
        updateOrder(list[index].children, list[index].id, dispatch);
      } else {
        const body = {
          urutan: index,
          parent: id,
        };
        //  dispatchLoading(dispatch, GET_LIST_SURATOBYEK);
        axios
          .patch(`/api/suratobyeks/updaktif/${list[index].id}`, body, config)
          .catch((err) => {
            dispatchError(dispatch, UPDATE_SURATOBYEK, {
              msg: err.response.data,
              status: err.response.status,
            });
          });
      }
    }
    resolve(list);
  });
};
export const updateAktifSuratobyek =
  ({ id, aktif }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      aktif: aktif ? 1 : 0,
    });
    dispatchLoading(dispatch, UPDATE_SURATOBYEK);
    axios
      .patch(`/api/suratobyeks/updaktif/${id}`, body, config)
      .then((res) => {
        dispatchSuccess(dispatch, UPDATE_SURATOBYEK, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, UPDATE_SURATOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const deleteSuratobyek = (id) => (dispatch) => {
  dispatchLoading(dispatch, DELETE_SURATOBYEK);
  axios
    .delete(`/api/suratobyeks/${id}/`, tokenConfig())
    .then((res) => {
      dispatchSuccess(dispatch, DELETE_SURATOBYEK, "DATA BERHASIL DIHAPUS");
    })
    .catch((err) =>
      dispatchError(dispatch, DELETE_SURATOBYEK, {
        msg: err.response.data,
        status: err.response.status,
      })
    );
};

export const tokenConfig = () => {
  // Get token from state

  // Headers
  const config = {
    // baseURL: "http://127.0.0.1:8000",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  // if (token) {
  //   config.headers["Authorization"] = `Token ${token}`;
  // }

  return config;
};
