import React from "react";
import { Link } from "react-router-dom";

const CardDetailPermohonan = ({ permohonan }) => {
  const color = "light";
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center mb-5">
            <div className="text-center flex justify-between w-full">
              <h3
                className={
                  "font-semibold text-lg uppercase " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                Detail Permohonan
              </h3>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-12/12">
              <div className="relative w-full mb-4">
                <label
                  className="block uppercase text-blueGray-800 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Nama Pemohon
                </label>
                <label
                  className="block uppercase text-blueGray-600 text-xs mb-2"
                  htmlFor="grid-password"
                >
                  {permohonan.nama_pemohon}
                </label>
                <label
                  className="block uppercase text-blueGray-800 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Identitas
                </label>
                <label
                  className="block uppercase text-blueGray-600 text-xs mb-2"
                  htmlFor="grid-password"
                >
                  {permohonan.identitas}
                </label>
              </div>
              <div className="text-center my-6 flex">
                <Link
                  to={`/admin/permohonans/`}
                  className="bg-blueGray-800 w-full text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:scale-105 hover:shadow-slate-700 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                >
                  PERMOHONAN
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDetailPermohonan;
