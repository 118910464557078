import React, { useEffect, useState } from "react";
import {
  deleteSuratobyek,
  listTableSuratobyek,
  updateOrderSuratobyekDb,
  updateOrderSuratobyek,
  stopCetakSurat,
} from "../../../actions/suratobyeks";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import BarLoader from "react-spinners/BarLoader";
import { Reorder, useDragControls } from "framer-motion";
import { ItemSuratobyek } from "./ItemSuratobyek";
import axios from "axios";

export const CardTableSuratObyek = ({
  obyId,
  openModalSuratobyek,
  getListSuratobyekError,
  getListSuratobyekLoading,
  getListSuratobyekResult,
  deleteSuratobyekResult,
  listTableSuratobyek,
  deleteSuratobyek,
  isOpenSuratobyek,
  updateSuratobyekResult,
  updateOrderSuratobyekDb,
  is_cetak,
  stopCetakSurat,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const color = "light";
  const [order, setOrder] = useState("urutan");
  const [search, setSearch] = useState("");
  const controls = useDragControls();
  const [error, setError] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    let params = { oby_id: obyId, page: currentPage + 1, order, search };
    listTableSuratobyek(params);
  }, [currentPage, order, search, obyId, updateSuratobyekResult]);

  useEffect(() => {
    if (is_cetak) {
      setDownloadLoading(true);
      unduhSurat(updateSuratobyekResult).then((e) => {
        setDownloadLoading(false);
      });
    }
  }, [is_cetak]);
  useEffect(() => {
    if (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        timer: 2000,
        timerProgressBar: true,
      });

      Toast.fire("Error!", `${error}`, "error");
    }
  }, [error]);

  const unduhSurat = async (suratobyek) => {
    setError(null);
    stopCetakSurat();
    return axios
      .get(`/api/suratobyeks/${obyId}/get_surat/${suratobyek.surat.id}/`, {
        responseType: "blob",
      })
      .then((response) => {
        const [, filename] =
          response.headers["content-disposition"].split("filename=");
        let url = window.URL.createObjectURL(response.data);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      })
      .catch(async (e) => {
        const isJsonBlob = (data) =>
          data instanceof Blob && data.type === "application/json";
        const responseData = isJsonBlob(e?.response?.data)
          ? await e?.response?.data?.text()
          : e?.response?.data || {};
        const responseJson =
          typeof responseData === "string"
            ? JSON.parse(responseData)
            : responseData;
        setError(responseJson.message);
      });
  };
  const searchItem = (txt) => {
    setCurrentPage(0);
    setSearch(txt);
  };
  // const deleteConfirmDialog = ({ suratobyek }) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       deleteSuratobyek(suratobyek.id);
  //       const Toast = Swal.mixin({
  //         toast: true,
  //         position: "top-end",
  //         timer: 2000,
  //         timerProgressBar: true,
  //       });
  //       Toast.fire(
  //         "Deleted!",
  //         `${suratobyek.surat__nama_surat} telah dihapus`,
  //         "success"
  //       );
  //     }
  //   });
  // };

  if (getListSuratobyekError) {
    return (
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <h1>{getListSuratobyekError.message}</h1>
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full h-full shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="text-center flex justify-between w-full">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                Daftar Surat
              </h3>
              <div className="relative flex flex-wrap items-stretch mb-3">
                <>
                  <span className="z-10 h-full leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-2 py-1">
                    <i className="fas fa-search"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Cari Surat"
                    className="px-2 py-1 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline pl-10 mr-5"
                    onChange={(e) => {
                      setTimeout(() => {
                        // setSearch(e.target.value);
                        searchItem(e.target.value);
                      }, 500);
                    }}
                  />
                </>
              </div>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {downloadLoading && (
            <div className="bg-opacity-50 fixed inset-0 z-30 bg-black flex justify-center items-center flex-col">
              <div className="w-1/2 absolute justify-center flex z-40 m-auto flex-col items-center bg-white h-32">
                <BarLoader color="#4689e2" height={10} width={400} />
                <h2 className="text-lightBlue-600 p-2">
                  Tunggu!, sedang proses surat
                </h2>
              </div>
            </div>
          )}

          {getListSuratobyekLoading && (
            <div className="w-full absolute justify-center flex z-30 m-auto flex-col items-center bg-white h-32">
              <BeatLoader color="#36d7b7" size={20} />
            </div>
          )}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  <span>#</span>
                </th>
                <th
                  className={
                    "px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  <span>Nama Surat</span>
                </th>
                <th
                  className={
                    "px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  <span>Jenis Surat</span>
                </th>
                <th
                  className={
                    "px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Menu
                </th>
              </tr>
            </thead>
            {getListSuratobyekResult.results && (
              <Reorder.Group
                axis="y"
                values={getListSuratobyekResult.results}
                as="tbody"
                onReorder={(e) => {
                  const newitem = { ...getListSuratobyekResult };
                  newitem.results = e;
                  updateOrderSuratobyekDb(newitem);
                }}
              >
                {getListSuratobyekResult.results.map((suratobyek, index) => {
                  return (
                    <ItemSuratobyek
                      key={suratobyek.id}
                      suratobyek={suratobyek}
                      openModalSuratobyek={openModalSuratobyek}
                      setError={setError}
                    />
                  );
                })}
              </Reorder.Group>
            )}
          </table>
        </div>
        {getListSuratobyekResult ? (
          <>
            <div className="py-4 px-2 justify-end flex bg-white">
              <nav className="block">
                <ReactPaginate
                  previousLabel={<i className="fas fa-chevron-left -ml-px"></i>}
                  nextLabel={<i className="fas fa-chevron-right -mr-px"></i>}
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={Math.ceil(getListSuratobyekResult.count / 10)}
                  pageRangeDisplayed={4}
                  marginPagesDisplayed={2}
                  onPageChange={(e) => {
                    setCurrentPage(e.selected);
                  }}
                  containerClassName="flex pl-0 rounded list-none flex-wrap"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  activeClassName="page-link-active"
                  forcePage={currentPage}
                  onClick={(clickEvent) => {
                    // setCurrentPage(clickEvent.selected + 1);
                  }}
                />
              </nav>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getListSuratobyekError: state.suratobyeks.getListSuratobyekError,
  getListSuratobyekLoading: state.suratobyeks.getListSuratobyekLoading,
  getListSuratobyekResult: state.suratobyeks.getListSuratobyekResult,
  deleteSuratobyekResult: state.suratobyeks.deleteSuratobyekResult,
  isOpenSuratobyek: state.suratobyeks.isOpenSuratobyek,
  updateSuratobyekResult: state.suratobyeks.updateSuratobyekResult,
  is_cetak: state.suratobyeks.is_cetak,
});

export default connect(mapStateToProps, {
  listTableSuratobyek,
  deleteSuratobyek,
  updateOrderSuratobyek,
  updateOrderSuratobyekDb,
  stopCetakSurat,
})(CardTableSuratObyek);
