import { motion } from "framer-motion";
import { zoom } from "../Animations";
import Backdrop from "../Backdrop";
import { updateOrderTindakanpemohonDb } from "../../../actions/tindakanpemohons";
import { CardTableTindakanpemohon } from "../../Cards/Pemohons/CardTableTindakanpemohon";
import { connect } from "react-redux";

const UpdateOrderTindakanpemohon = ({
  handleClose,
  tindakanpemohons,
  updateOrderTindakanpemohonDb,
}) => {
  // useEffect(() => {}, []);

  return (
    <Backdrop>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="border-0 rounded-lg shadow-lg absolute flex flex-col w-3/4 md:w-2/5 outline-none focus:outline-none shadow-slate-700"
        variants={zoom}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="flex items-start justify-between p-3 border-b border-solid bg-blueGray-100 rounded-t">
          <h3 className="text-xl font-semibold text-blueGray-400 uppercase">
            Update Order Tindakan Pemohon
          </h3>
          <button
            className="p-1 ml-auto bg-transparent border-0 text-blueGray-400 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            onClick={handleClose}
          >
            <span className="bg-transparent text-blueGray-500 h-6 w-6 text-xl block outline-none focus:outline-none">
              ×
            </span>
          </button>
        </div>
        <div className="relative p-4 flex-auto bg-blueGray-200">
          <CardTableTindakanpemohon
            tindakanpemohons={tindakanpemohons}
            updateOrder={updateOrderTindakanpemohonDb}
          />
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default connect(null, {
  updateOrderTindakanpemohonDb,
})(UpdateOrderTindakanpemohon);
