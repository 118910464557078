import React from "react";

// components

import CardLineChart from "../../components/Cards/CardLineChart";
import CardTableLatestPermohonan from "../../components/Cards/Permohonans/CardTableLatestPermohonan";
import { motion } from "framer-motion";

export default function Dashboard() {
  return (
    <>
      <motion.div
        className="flex flex-wrap"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
          <CardLineChart />
        </div>
      </motion.div>
      <motion.div
        className="flex flex-wrap mt-4"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 1.5 }}
        transition={{ duration: 0.8 }}
      >
        <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
          <CardTableLatestPermohonan />
        </div>
      </motion.div>
    </>
  );
}
