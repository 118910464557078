import {
  GET_LIST_SAKSIOBYEK,
  TAMBAH_SAKSIOBYEK,
  UPDATE_SAKSIOBYEK,
  DELETE_SAKSIOBYEK,
  CLOSE_SAKSIOBYEK,
  OPEN_SAKSIOBYEK,
  GET_DETAIL_SAKSIOBYEK,
  GET_LIST_SURAT_IN_SAKSIOBYEK,
  GET_LIST_SURAT_NOT_IN_SAKSIOBYEK,
} from "../actions/saksiobyeks";

const initialState = {
  getListSaksiobyekLoading: false,
  getListSaksiobyekResult: false,
  getListSaksiobyekError: false,

  tambahSaksiobyekLoading: false,
  tambahSaksiobyekResult: false,
  tambahSaksiobyekError: false,

  getDetailSaksiobyekLoading: false,
  getDetailSaksiobyekResult: false,
  getDetailSaksiobyekError: false,

  updateSaksiobyekLoading: false,
  updateSaksiobyekResult: false,
  updateSaksiobyekError: false,

  deleteSaksiobyekLoading: false,
  deleteSaksiobyekResult: false,
  deleteSaksiobyekError: false,

  getListSuratInSaksiobyekLoading: false,
  getListSuratInSaksiobyekResult: false,
  getListSuratInSaksiobyekError: false,

  getListSuratNotInSaksiobyekLoading: false,
  getListSuratNotInSaksiobyekResult: false,
  getListSuratNotInSaksiobyekError: false,

  isOpenSaksiobyek: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_SAKSIOBYEK:
      return {
        ...state,
        getListSaksiobyekLoading: action.payload.loading,
        getListSaksiobyekResult: action.payload.data,
        getListSaksiobyekError: action.payload.errorMessage,
      };
    case GET_DETAIL_SAKSIOBYEK:
      return {
        ...state,
        getDetailSaksiobyekLoading: action.payload.loading,
        getDetailSaksiobyekResult: action.payload.data,
        getDetailSaksiobyekError: action.payload.errorMessage,
      };

    case TAMBAH_SAKSIOBYEK:
      return {
        ...state,
        tambahSaksiobyekLoading: action.payload.loading,
        tambahSaksiobyekResult: action.payload.data,
        tambahSaksiobyekError: action.payload.errorMessage,
      };

    case UPDATE_SAKSIOBYEK:
      return {
        ...state,
        updateSaksiobyekLoading: action.payload.loading,
        updateSaksiobyekResult: action.payload.data,
        updateSaksiobyekError: action.payload.errorMessage,
      };

    case DELETE_SAKSIOBYEK:
      return {
        ...state,
        deleteSaksiobyekLoading: action.payload.loading,
        deleteSaksiobyekResult: action.payload.data,
        deleteSaksiobyekError: action.payload.errorMessage,
      };
    case OPEN_SAKSIOBYEK:
      return {
        ...state,
        isOpenSaksiobyek: true,
      };
    case CLOSE_SAKSIOBYEK:
      return {
        ...state,
        isOpenSaksiobyek: false,
        updateSaksiobyekError: false,
        tambahSaksiobyekError: false,
      };
    case GET_LIST_SURAT_IN_SAKSIOBYEK:
      return {
        ...state,
        getListSuratInSaksiobyekLoading: action.payload.loading,
        getListSuratInSaksiobyekResult: action.payload.data,
        getListSuratInSaksiobyekError: action.payload.errorMessage,
      };
    case GET_LIST_SURAT_NOT_IN_SAKSIOBYEK:
      return {
        ...state,
        getListSuratNotInSaksiobyekLoading: action.payload.loading,
        getListSuratNotInSaksiobyekResult: action.payload.data,
        getListSuratNotInSaksiobyekError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
