import {
  GET_LIST_SURATOBYEK,
  TAMBAH_SURATOBYEK,
  UPDATE_SURATOBYEK,
  DELETE_SURATOBYEK,
  CLOSE_SURATOBYEK,
  OPEN_SURATOBYEK,
  GET_DETAIL_SURATOBYEK,
  GET_LIST_SURAT_IN_SURATOBYEK,
  GET_LIST_SURAT_NOT_IN_SURATOBYEK,
  DO_CETAK,
  STOP_CETAK,
} from "../actions/suratobyeks";

const initialState = {
  getListSuratobyekLoading: false,
  getListSuratobyekResult: false,
  getListSuratobyekError: false,

  tambahSuratobyekLoading: false,
  tambahSuratobyekResult: false,
  tambahSuratobyekError: false,

  getDetailSuratobyekLoading: false,
  getDetailSuratobyekResult: false,
  getDetailSuratobyekError: false,

  updateSuratobyekLoading: false,
  updateSuratobyekResult: false,
  updateSuratobyekError: false,

  deleteSuratobyekLoading: false,
  deleteSuratobyekResult: false,
  deleteSuratobyekError: false,

  getListSuratInSuratobyekLoading: false,
  getListSuratInSuratobyekResult: false,
  getListSuratInSuratobyekError: false,

  getListSuratNotInSuratobyekLoading: false,
  getListSuratNotInSuratobyekResult: false,
  getListSuratNotInSuratobyekError: false,

  isOpenSuratobyek: false,
  is_cetak: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_SURATOBYEK:
      return {
        ...state,
        getListSuratobyekLoading: action.payload.loading,
        getListSuratobyekResult: action.payload.data,
        getListSuratobyekError: action.payload.errorMessage,
      };
    case GET_DETAIL_SURATOBYEK:
      return {
        ...state,
        getDetailSuratobyekLoading: action.payload.loading,
        getDetailSuratobyekResult: action.payload.data,
        getDetailSuratobyekError: action.payload.errorMessage,
      };

    case TAMBAH_SURATOBYEK:
      return {
        ...state,
        tambahSuratobyekLoading: action.payload.loading,
        tambahSuratobyekResult: action.payload.data,
        tambahSuratobyekError: action.payload.errorMessage,
      };

    case UPDATE_SURATOBYEK:
      return {
        ...state,
        updateSuratobyekLoading: action.payload.loading,
        updateSuratobyekResult: action.payload.data,
        updateSuratobyekError: action.payload.errorMessage,
      };

    case DELETE_SURATOBYEK:
      return {
        ...state,
        deleteSuratobyekLoading: action.payload.loading,
        deleteSuratobyekResult: action.payload.data,
        deleteSuratobyekError: action.payload.errorMessage,
      };
    case OPEN_SURATOBYEK:
      return {
        ...state,
        isOpenSuratobyek: true,
      };
    case CLOSE_SURATOBYEK:
      return {
        ...state,
        isOpenSuratobyek: false,
        updateSuratobyekError: false,
        tambahSuratobyekError: false,
      };
    case GET_LIST_SURAT_IN_SURATOBYEK:
      return {
        ...state,
        getListSuratInSuratobyekLoading: action.payload.loading,
        getListSuratInSuratobyekResult: action.payload.data,
        getListSuratInSuratobyekError: action.payload.errorMessage,
      };
    case GET_LIST_SURAT_NOT_IN_SURATOBYEK:
      return {
        ...state,
        getListSuratNotInSuratobyekLoading: action.payload.loading,
        getListSuratNotInSuratobyekResult: action.payload.data,
        getListSuratNotInSuratobyekError: action.payload.errorMessage,
      };
    case DO_CETAK:
      return {
        ...state,
        is_cetak: true,
      };
    case STOP_CETAK:
      return {
        ...state,
        is_cetak: false,
      };
    default:
      return state;
  }
}
