import {
  GET_LIST_PEJABAT,
  TAMBAH_PEJABAT,
  UPDATE_PEJABAT,
  DELETE_PEJABAT,
  CLOSE_PEJABAT,
  OPEN_PEJABAT,
  GET_DETAIL_PEJABAT,
} from "../actions/pejabats";

const initialState = {
  getListPejabatLoading: false,
  getListPejabatResult: false,
  getListPejabatError: false,

  tambahPejabatLoading: false,
  tambahPejabatResult: false,
  tambahPejabatError: false,

  getDetailPejabatLoading: false,
  getDetailPejabatResult: false,
  getDetailPejabatError: false,

  updatePejabatLoading: false,
  updatePejabatResult: false,
  updatePejabatError: false,

  deletePejabatLoading: false,
  deletePejabatResult: false,
  deletePejabatError: false,

  isOpenPejabat: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_PEJABAT:
      return {
        ...state,
        getListPejabatLoading: action.payload.loading,
        getListPejabatResult: action.payload.data,
        getListPejabatError: action.payload.errorMessage,
      };
    case GET_DETAIL_PEJABAT:
      return {
        ...state,
        getDetailPejabatLoading: action.payload.loading,
        getDetailPejabatResult: action.payload.data,
        getDetailPejabatError: action.payload.errorMessage,
      };

    case TAMBAH_PEJABAT:
      return {
        ...state,
        tambahPejabatLoading: action.payload.loading,
        tambahPejabatResult: action.payload.data,
        tambahPejabatError: action.payload.errorMessage,
      };

    case UPDATE_PEJABAT:
      return {
        ...state,
        updatePejabatLoading: action.payload.loading,
        updatePejabatResult: action.payload.data,
        updatePejabatError: action.payload.errorMessage,
      };

    case DELETE_PEJABAT:
      return {
        ...state,
        deletePejabatLoading: action.payload.loading,
        deletePejabatResult: action.payload.data,
        deletePejabatError: action.payload.errorMessage,
      };
    case OPEN_PEJABAT:
      return {
        ...state,
        isOpenPejabat: true,
      };
    case CLOSE_PEJABAT:
      return {
        ...state,
        isOpenPejabat: false,
        updatePejabatError: false,
        tambahPejabatError: false,
      };
    default:
      return state;
  }
}
