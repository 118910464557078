import { dispatchError, dispatchLoading, dispatchSuccess } from "../utils";
import axios from "axios";

export const GET_LIST_TINDAKANPEMOHON = "GET_LIST_TINDAKANPEMOHON";
export const TAMBAH_TINDAKANPEMOHON = "TAMBAH_TINDAKANPEMOHON";
export const GET_DETAIL_TINDAKANPEMOHON = "GET_DETAIL_TINDAKANPEMOHON";
export const UPDATE_TINDAKANPEMOHON = "UPDATE_TINDAKANPEMOHON";
export const DELETE_TINDAKANPEMOHON = "DELETE_TINDAKANPEMOHON";
export const OPEN_TINDAKANPEMOHON = "OPEN_TINDAKANPEMOHON";
export const CLOSE_TINDAKANPEMOHON = "CLOSE_TINDAKANPEMOHON";
export const OPEN_ORDER_TINDAKANPEMOHON = "OPEN_ORDER_TINDAKANPEMOHON";
export const CLOSE_ORDER_TINDAKANPEMOHON = "CLOSE_ORDER_TINDAKANPEMOHON";

export const getDetailTindakanpemehon =
  ({ id }) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_DETAIL_TINDAKANPEMOHON);
    axios
      .get(`/api/tindakanpemohons/${id}`, tokenConfig())
      .then((res) => {
        dispatchSuccess(dispatch, GET_DETAIL_TINDAKANPEMOHON, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_DETAIL_TINDAKANPEMOHON, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };
export const listTableTindakanpemohon =
  ({ oby_id, page = 1, order = "id" } = {}) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_TINDAKANPEMOHON);
    axios
      .get(
        `/api/tindakanpemohons/pemohonobyeks/${oby_id}?page=${page} ${
          order ? "&order_by=" + order : ""
        }`,
        tokenConfig()
      )
      .then((res) => {
        dispatchSuccess(dispatch, GET_LIST_TINDAKANPEMOHON, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_LIST_TINDAKANPEMOHON, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const listTindakanpemohon =
  ({ oby_id }) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_TINDAKANPEMOHON);
    axios
      .get(`/api/tindakanpemohons/obyeks/${oby_id}/list`, tokenConfig())
      .then((res) => {
        dispatchSuccess(dispatch, GET_LIST_TINDAKANPEMOHON, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_LIST_TINDAKANPEMOHON, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const getDetailTindakanpemohon =
  ({ id }) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_DETAIL_TINDAKANPEMOHON);
    axios
      .get(`/api/tindakanpemohons/${id}`, tokenConfig())
      .then((res) => {
        dispatchSuccess(dispatch, GET_DETAIL_TINDAKANPEMOHON, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_DETAIL_TINDAKANPEMOHON, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const openTindakanpemohon = () => (dispatch) => {
  dispatchLoading(dispatch, OPEN_TINDAKANPEMOHON);
};
export const closeTindakanpemohon = () => (dispatch) => {
  dispatch({ type: CLOSE_TINDAKANPEMOHON });
};

export const openOrderTindakanpemohon = () => (dispatch) => {
  dispatchLoading(dispatch, OPEN_ORDER_TINDAKANPEMOHON);
};
export const closeOrderTindakanpemohon = () => (dispatch) => {
  dispatch({ type: CLOSE_ORDER_TINDAKANPEMOHON });
};

export const addTindakanpemohon =
  ({ pemohon, obyek, jenis_pemohon, formatsurat, tindakanpemohons }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      pemohon,
      obyek,
      jenis_pemohon,
      formatsurat,
      tindakanpemohons,
    });
    dispatchLoading(dispatch, TAMBAH_TINDAKANPEMOHON);
    dispatch({ type: OPEN_TINDAKANPEMOHON });
    axios
      .post("/api/tindakanpemohons/", body, config)
      .then((res) => {
        dispatchSuccess(dispatch, TAMBAH_TINDAKANPEMOHON, res.data);
        dispatch({ type: CLOSE_TINDAKANPEMOHON });
      })
      .catch((err) => {
        dispatchError(dispatch, TAMBAH_TINDAKANPEMOHON, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_TINDAKANPEMOHON });
      });
  };

export const updateTindakanpemohon =
  ({ id, pemohonobyek, tindakan, text_uraian, urutan, formatsurat }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      pemohonobyek,
      tindakan,
      text_uraian,
      urutan,
      formatsurat,
    });
    dispatchLoading(dispatch, UPDATE_TINDAKANPEMOHON);
    dispatch({ type: OPEN_TINDAKANPEMOHON });
    axios
      .patch(`/api/tindakanpemohons/${id}/`, body, config)
      .then((res) => {
        dispatchSuccess(dispatch, UPDATE_TINDAKANPEMOHON, res.data);
        dispatch({ type: CLOSE_TINDAKANPEMOHON });
      })
      .catch((err) => {
        dispatchError(dispatch, UPDATE_TINDAKANPEMOHON, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_TINDAKANPEMOHON });
      });
  };
export const updateOrderTindakanpemohonDb =
  (tindakanpemohons) => (dispatch) => {
    updateOrderId(tindakanpemohons, null, dispatch).then((list) => {
      const config = {
        // baseURL: "http://127.0.0.1:8000",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = list;
      axios
        .put(`/api/tindakanpemohons-bulkupdate/`, body, config)
        .then((res) => {
          // dispatch({ type: CLOSE_ORDER_TINDAKANPEMOHON });
        })
        .catch((err) => {
          dispatchError(dispatch, UPDATE_TINDAKANPEMOHON, {
            msg: err.response.data,
            status: err.response.status,
          });
        });

      // dispatchSuccess(dispatch, GET_LIST_TINDAKANPEMOHON, tindakanpemohons)
    });

    // updateOrderId(tindakanpemohons, null, dispatch).then(
    //   (list) => console.log(list)
    // dispatchSuccess(dispatch, GET_LIST_TINDAKANPEMOHON, tindakanpemohons)
    // );
  };

export const updateOrderTindakanpemohon = (tindakanpemohons) => (dispatch) => {
  dispatchSuccess(dispatch, GET_LIST_TINDAKANPEMOHON, tindakanpemohons);
  // const config = {
  //   // baseURL: "http://127.0.0.1:8000",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // };

  // // Request Body
  // const body = JSON.stringify(tindakanpemohons.results);
  // //  dispatchLoading(dispatch, GET_LIST_TINDAKANPEMOHON);
  // axios
  //   .patch(`/api/tindakanpemohons/updaktif/${id}`, body, config)
  //   .then((res) => {
  //     dispatchSuccess(dispatch, UPDATE_TINDAKANPEMOHON, res.data);
  //   })
  //   .catch((err) => {
  //     dispatchError(dispatch, UPDATE_TINDAKANPEMOHON, {
  //       msg: err.response.data,
  //       status: err.response.status,
  //     });
  //   });
  // const body = JSON.stringify(tindakanpemohons.results);
  // axios
  //   .put(`/api/tindakanpemohons-bulkupdate/`, body, config)
  //   .then((res) => {
  //     dispatchSuccess(dispatch, GET_LIST_TINDAKANPEMOHON, tindakanpemohons);
  //   })
  //   .catch((err) => {
  //     dispatchError(dispatch, GET_LIST_TINDAKANPEMOHON, {
  //       msg: err.response.data,
  //       status: err.response.status,
  //     });
  //   });
};
export const updateOrderId = (list) => {
  return new Promise((resolve, reject) => {
    let pmobjects = [];
    for (let index = 0; index < list.length; index++) {
      let obj = {
        id: list[index].id,
        urutan: index,
        tindakan: list[index].tindakan.id,
        formatsurat: list[index].formatsurat,
        text_uraian: list[index].text_uraian,
      };
      pmobjects.push(obj);
    }
    resolve(pmobjects);
  });
};

export const updateOrder = (list, id, dispatch) => {
  return new Promise((resolve, reject) => {
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    for (let index = 0; index < list.length; index++) {
      if (list[index].children.length > 0) {
        updateOrder(list[index].children, list[index].id, dispatch);
      } else {
        const body = {
          urutan: index,
          parent: id,
        };
        //  dispatchLoading(dispatch, GET_LIST_TINDAKANPEMOHON);
        axios
          .patch(
            `/api/tindakanpemohons/updaktif/${list[index].id}`,
            body,
            config
          )
          .catch((err) => {
            dispatchError(dispatch, UPDATE_TINDAKANPEMOHON, {
              msg: err.response.data,
              status: err.response.status,
            });
          });
      }
    }
    resolve(list);
  });
};
export const updateAktifTindakanpemohon =
  ({ id, aktif }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      aktif: aktif ? 1 : 0,
    });
    dispatchLoading(dispatch, UPDATE_TINDAKANPEMOHON);
    axios
      .patch(`/api/tindakanpemohons/updaktif/${id}`, body, config)
      .then((res) => {
        dispatchSuccess(dispatch, UPDATE_TINDAKANPEMOHON, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, UPDATE_TINDAKANPEMOHON, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const deleteTindakanpemohon = (id) => (dispatch) => {
  dispatchLoading(dispatch, DELETE_TINDAKANPEMOHON);
  axios
    .delete(`/api/tindakanpemohons/${id}/`, tokenConfig())
    .then((res) => {
      dispatchSuccess(
        dispatch,
        DELETE_TINDAKANPEMOHON,
        "DATA BERHASIL DIHAPUS"
      );
    })
    .catch((err) =>
      dispatchError(dispatch, DELETE_TINDAKANPEMOHON, {
        msg: err.response.data,
        status: err.response.status,
      })
    );
};

export const tokenConfig = () => {
  // Get token from state

  // Headers
  const config = {
    // baseURL: "http://127.0.0.1:8000",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  // if (token) {
  //   config.headers["Authorization"] = `Token ${token}`;
  // }

  return config;
};
