import React, { Component } from "react";
import { AnimatePresence, motion } from "framer-motion";
import withRouter from "../../../withRouter";
import { connect } from "react-redux";
import { getDetailSuratobyek } from "../../../actions/suratobyeks";
import { getDetailObyek } from "../../../actions/obyeks";
import CardTableSuratObyek from "../../../components/Cards/Surats/CardTableSuratObyek";
import UpdateSuratobyekModal from "../../../components/Modals/Suratobyek/UpdateSuratobyekModal";
import { openSuratobyek, closeSuratobyek } from "../../../actions/suratobyeks";

export class DetailObyekSurat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSuratobyek: {},
      mode: "add",
      reload: false,
    };
  }
  // componentDidMount() {
  //   let { obyid } = this.props.params;
  //   this.props.getDetailObyek({ id: obyid });
  // }
  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     this.props.isOpenSuratobyek !== prevProps.isOpenSuratobyek &&
  //     this.props.isOpenSuratobyek === false
  //   ) {
  //     let { id } = this.props.params;
  //     this.props.getDetailSuratobyek({ id });
  //   }
  // }
  //   static propTypes = {};
  openModalSuratobyek = ({ mode = "add", selectedSuratobyek = {} } = {}) => {
    this.setState({ selectedSuratobyek, mode });
    this.props.openSuratobyek();
  };
  closeModalSuratobyek = () => {
    this.props.closeSuratobyek();
  };

  render() {
    // const {
    //   getDetailSuratobyekLoading,
    //   getDetailSuratobyekResult,
    //   getDetailSuratobyekError,
    // } = this.props.suratobyeks;
    // let obyekLength = getDetailSuratobyekResult.jenispermohonans
    //   ? getDetailSuratobyekResult.jenispermohonans.length + 1
    //   : 1;
    const obyId = this.props.params.obyid;

    return (
      <motion.div
        className="flex flex-wrap"
        initial={{ opacity: 0, scale: 0.7 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 1.2 }}
        transition={{ duration: 0.3 }}
      >
        {/* <div className="w-full lg:w-1/4 px-4 mt-8 relative">
          <CardDetailObyek obyeks={this.props.obyeks} />
        </div> */}
        <CardTableSuratObyek
          obyId={obyId}
          openModalSuratobyek={this.openModalSuratobyek}
        />
        <AnimatePresence
          initial={false}
          wait={true}
          onExitComplete={() => null}
        >
          {this.props.isOpenSuratobyek && (
            <UpdateSuratobyekModal
              handleClose={this.closeModalSuratobyek}
              mode={this.state.mode}
              suratobyek={this.state.selectedSuratobyek}
            />
          )}
        </AnimatePresence>
      </motion.div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isOpenSuratobyek: state.suratobyeks.isOpenSuratobyek,
    obyeks: state.obyeks,
  };
};

const mapDispatchToProps = {
  getDetailSuratobyek,
  getDetailObyek,
  openSuratobyek,
  closeSuratobyek,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DetailObyekSurat));
