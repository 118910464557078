import React from "react";

// components
export default function HeaderBlank() {
  return (
    <>
      {/* Header */}
      <div className="relative bg-lightBlue-600 md:pt-32 pb-16 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap"></div>
          </div>
        </div>
      </div>
    </>
  );
}
