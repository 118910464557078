import { motion } from "framer-motion";
import { dropIn } from "../Animations";
import { useEffect, useRef, useState } from "react";
import Backdrop from "../Backdrop";
import { connect } from "react-redux";
import { addPermohonan, updatePermohonan } from "../../../actions/permohonans";
import { ErrorAlert } from "../../Alerts/ErrorAlert";
import Select from "react-select";

const AddPermohonanModal = ({
  handleClose,
  addPermohonan,
  errors,
  isOpenPermohonan,
  permohonan,
  mode = "add",
  updatePermohonan,
}) => {
  const firstInput = useRef();
  const [formData, setFormData] = useState({
    nama_pemohon: "",
    identitas: "",
    active:1
  });
  const { nama_pemohon, identitas, active } = formData;
  const ACTIVE_OPTIONS = [
    { value: "1", label: "Active" },
    { value: "0", label: "Not Active" },
  ];

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // const onSelectChange = (e) => {
  //   setFormData({ ...formData, ["jenispermohonans"]: e });
  // };
  const onSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setRefresh(!refresh);
    if (mode === "add") {
      const newPermohonan = {
        // jenispermohonans: jenispermohonans,
        nama_pemohon,
        identitas,
        active: active.value ? active.value : 1,
      };
      addPermohonan(newPermohonan);
    } else {
      const newPermohonan = {
        id: permohonan.id,
        // jenispermohonans: jenispermohonans,
        nama_pemohon,
        identitas,
        active: active.value ? active.value : 1,
      };
      updatePermohonan(newPermohonan);
    }
  };

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setRefresh(true);
    if (!isOpenPermohonan) {
      handleClose();
    }

    return () => setRefresh(false);
  }, [errors, isOpenPermohonan]);

  useEffect(() => {

    if (permohonan && permohonan.jenispermohonans) {
      const _active = {
        value: permohonan.active,
        label: permohonan.active ===1?'Active':'Not Active',
      };
        setFormData({
        jenispermohonans: permohonan.jenispermohonans,
        nama_pemohon: permohonan.nama_pemohon,
        identitas: permohonan.identitas,
        active:_active
      });
    }
    firstInput.current.focus();
    return()=>setFormData({ ...formData, active: {value:'1',label:"Active"}});
  }, []);

  return (
    <Backdrop>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="border-0 rounded-lg shadow-lg absolute flex flex-col w-11/12 md:w-3/4 lg:w-1/2 outline-none focus:outline-none shadow-slate-700"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="flex items-start justify-between p-3 border-b border-solid bg-blueGray-100 rounded-t">
          <h3 className="text-xl font-semibold text-blueGray-400 uppercase">
            Form Permohonan
          </h3>
          <button
            className="p-1 ml-auto bg-transparent border-0 text-blueGray-400 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            onClick={handleClose}
          >
            <span className="bg-transparent text-blueGray-500 h-6 w-6 text-xl block outline-none focus:outline-none">
              ×
            </span>
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className="relative p-2 flex-auto bg-blueGray-200">
            <div className="flex-auto px-5 lg:px-5 py-5 pt-0">
              <h6 className="text-blueGray-400 text-sm mt-3 mb-4 font-bold uppercase">
                Data Permohonan
              </h6>
              {errors &&
              errors.msg &&
              errors.status &&
              errors.status === 400 &&
              refresh ? (
                <ErrorAlert errors={Object.entries(errors.msg)} />
              ) : null}

              <div className="flex flex-wrap">
                {/* <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Jenis Permohonan
                    </label>
                    <MultiSelectDb
                      innerRef={firstInput}
                      url="/api/jenispermohonans/list"
                      valueField="id"
                      labelField="nama_jenis_permohonan"
                      value={jenispermohonans}
                      onChange={onSelectChange}
                    />
                  </div>
                </div> */}
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Nama Pemohon
                    </label>
                    <input
                      type="text"
                      ref={firstInput}
                      className="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-lg focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      name="nama_pemohon"
                      value={nama_pemohon}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Identitas
                    </label>
                    <input
                      type="text"
                      className="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-lg focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      name="identitas"
                      value={identitas}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/2 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Status
                    </label>
                    <Select
                      classNamePrefix="select"
                      defaultValue={ACTIVE_OPTIONS[0]}
                      name="active"
                      options={ACTIVE_OPTIONS}
                      onChange={(e) => onSelectChange("active", e)}
                      value={active}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b bg-blueGray-100">
            <button
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded-2xl shadow-lg shadow-slate-500 hover:shadow-slate-800 outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded-2xl shadow-lg shadow-slate-500 hover:shadow-slate-800 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="submit"
            >
              Save Changes
            </button>
          </div>
        </form>
      </motion.div>
    </Backdrop>
  );
};

const mapStateToProps = (state) => ({
  errors: state.permohonans.tambahPermohonanError
    ? state.permohonans.tambahPermohonanError
    : state.permohonans.updatePermohonanError,
  isOpenPermohonan: state.permohonans.isOpenPermohonan,
});

export default connect(mapStateToProps, { addPermohonan, updatePermohonan })(
  AddPermohonanModal
);
