import React, { Component } from "react";
import { SortableTreeWithoutDndContext as SortableTree, find, getFlatDataFromTree, getNodeAtPath, walk } from "@nosferatu500/react-sortable-tree";
import "@nosferatu500/react-sortable-tree/style.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  deletePemohonobyek,
  listPemohonobyek,
  updateAktifPemohonobyek,
  updateOrderPemohonobyek,
  updateOrderPemohonobyekDb,
  updateFormatSuratPemohonobyek,
} from "../../../actions/pemohonobyeks";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import DropDownItem from "../../Dropdowns/DropDownItem";
import CommonDropdown from "../../Dropdowns/CommonDropdown";
import { displayTextLimit } from "../../../utils";
import UpdateFormatSuratModal from '../../Modals/Pemohon/UpdateFormatSuratModal'

class CardListPemohonobyek extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      openFormatSuratModal:false,
      curChildren:[]
    };
  }

  deleteConfirmDialog = (pemohonobyek) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.deletePemohonobyek(pemohonobyek.id);
        let params = { oby_id: this.props.obyId };
        this.props.listPemohonobyek(params);
      }
    });
  };
  updAktifConfirmDialog = ({ pemohonobyek }) => {
    Swal.fire({
      title: "Kamu yakin?",
      text: "Ubah data aktif",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        updateAktifPemohonobyek({
          id: pemohonobyek.id,
          aktif: !pemohonobyek.aktif,
        });
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          timer: 2000,
          timerProgressBar: true,
        });
        Toast.fire(
          "Deleted!",
          `${pemohonobyek.nama_pemohon} telah diupdate`,
          "success"
        );
      }
    });
  };
  updOrderConfirmDialog = (pemohonobyeks) => {
    Swal.fire({
      title: "Kamu yakin?",
      text: "Ubah susunan dan urutan data",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.updateOrderPemohonobyekDb(pemohonobyeks);
      }
    });
  };
  resetOrder() {
    let params = { oby_id: this.props.obyId };
    this.props.listPemohonobyek(params);
    this.setState({ editMode: false });
  }
  closeUpdateFormatSuratModal(){
    this.setState({openFormatSuratModal:false})
  }

  saveFormatSuratModal(e){
    const curCld = this.state.curChildren
    let pemohonobyeks=[] 
    curCld.forEach((item)=>{
      pemohonobyeks.push({id:item.id,formatsurat:e})
    }
      )
    this.props.updateFormatSuratPemohonobyek(pemohonobyeks)
  }


  getNodes(nodes=[], key){
    let cld = []
    for (let index = 0; index < nodes.length; index++) {
      const el = nodes[index];
        if(el.id===key){
          cld = el.children
          break  
      }else{
          for (let idx = 0; idx < el.children.length; idx++) {
            const element = el.children[idx];
            if(element.id===key){
              cld = element.children
              break
            }else{
              for (let idx = 0; idx < element.children.length; idx++) {
                const element1 = el.children[idx];
                if(element1.id===key){
                  cld = element1.children
                  break
                }
              }
            }
          }
      }
    };
    return cld
  }

  getNodeKey(node) {
    return node.id;
  }
  
   getParent(childNode, treeData) {
    let parent = null;
  
    walk({
      treeData,
      getNodeKey:this.getNodeKey,
      ignoreCollapsed: false,
      callback: ({ node }) => {
        if (find(node.children, { id: this.getNodeKey(childNode) })) {
          parent = node;
        }
      },
    });
  
    return parent;
  }


  componentDidMount() {
    let params = { oby_id: this.props.obyId };
    this.props.listPemohonobyek(params);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.tambahPemohonobyekResult !== this.props.tambahPemohonobyekResult
    ) {
      let params = { oby_id: this.props.obyId };
      this.props.listPemohonobyek(params);
    }
    if (
      prevProps.updatePemohonobyekResult !== this.props.updatePemohonobyekResult
    ) {
      let params = { oby_id: this.props.obyId };
      this.props.listPemohonobyek(params);
      this.setState({ editMode: false });
    }
    if (
      prevProps.updateTindakanpemohonResult !==
      this.props.updateTindakanpemohonResult
    ) {
      let params = { oby_id: this.props.obyId };
      this.props.listPemohonobyek(params);
    }
    if (
      prevProps.deletePemohonobyekResult !== this.props.deletePemohonobyekResult
    ) {
      let params = { oby_id: this.props.obyId };
      this.props.listPemohonobyek(params);
    }
    if (
      prevProps.isOpenOrderTindakanpemohon !==
      this.props.isOpenOrderTindakanpemohon &&
      this.props.isOpenOrderTindakanpemohon === false
    ) {
      let params = { oby_id: this.props.obyId };
      this.props.listPemohonobyek(params);
    }
  }
  render() {
    const {
      openModalPemohonobyek,
      openModalTindakanpemohon,
      openModalOrderTindakanPemohon,
      getListPemohonobyekError,
      getListPemohonobyekLoading,
      getListPemohonobyekResult,
      updateOrderPemohonobyek,
    } = this.props;
    if (getListPemohonobyekError) {
      return (
        <div className="flex flex-wrap mt-4">
          <div className="w-full mb-12 px-4">
            <h1>{getListPemohonobyekError.message}</h1>
          </div>
        </div>
      );
    }

    if (this.props.deletePemohonobyekError) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Data tidak dapat dihapus",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    const getNodeKey = ({ treeIndex }) => treeIndex;
    return (
      <DndProvider backend={HTML5Backend}>
        <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-white">
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="text-center flex justify-between w-full">
                <h3 className="font-semibold text-lg text-blueGray-700">
                  DAFTAR PEMOHON (PARA PIHAK)
                </h3>
                <div className="relative flex flex-wrap items-stretch mb-3">
                  {this.state.editMode ? (
                    <>
                      <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() =>
                          this.props.updateOrderPemohonobyekDb(
                            getListPemohonobyekResult
                          )
                        }
                      >
                        Update Order
                      </button>
                      <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => this.resetOrder()}
                      >
                        Batal
                      </button>
                    </>
                  ) : (
                    <button
                      disabled={this.state.editMode}
                      className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={openModalPemohonobyek}
                    >
                      Pemohon Baru
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="block w-full min-h-[480px] h-full overflow-auto">
            {getListPemohonobyekLoading ? (
              <div className="w-full absolute justify-center flex z-30 m-auto flex-col items-center bg-white h-32 overflow-auto">
                <BeatLoader color="#36d7b7" size={20} />
              </div>
            ) : null}
            {getListPemohonobyekResult ? (
              <SortableTree
                treeData={getListPemohonobyekResult}
                onChange={(treeData) => {
                  updateOrderPemohonobyek(treeData);
                  this.setState({ editMode: true });
                }}
                generateNodeProps={({ node, path }) => {
                  return {
                    title: () => {
                      return (
                        <div className="flex justify-between items-center">
                          <div className="flex flex-col items-start flex-wrap text-xs">
                            <div className="flex items-start text-xs w-full">
                              <div className="text-red-800 mr-2">
                                {node.caption}
                              </div>
                              <div className="text-lightBlue-700 font-bold uppercase">
                                {displayTextLimit(node.nama_pemohon, 20)}
                              </div>

                              <div className="pl-2 text-lightBlue-500">
                                - {node.nik}
                              </div>
                              <div className="pl-2 text-lightBlue-500">
                                ({node.jenis_pemohon})
                              </div>
                            </div>
                            <div className="flex items-center flex-wrap text-xs text-gray-500 font-normal max-w-2xl overflow-hidden">
                              {node.tindakanpemohon_pemohonobyek.length > 0 &&
                                node.tindakanpemohon_pemohonobyek.map(
                                  (d, i) => {
                                    return (
                                      <button
                                        disabled={this.state.editMode}
                                        key={d.id}
                                        className="text-lightBlue-600 background-transparent text-xs outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => {
                                          openModalTindakanpemohon({
                                            selectedTindakanpemohon: d,
                                            mode: "edit",
                                          });
                                        }}
                                      >

                                        <span className="text-red-800 mr-2">{d.caption}</span>
                                        {displayTextLimit(d.tindakan.judul, 20)}
                                      </button>
                                    );
                                  }
                                )}
                              {node.tindakanpemohon_pemohonobyek.length > 0 &&
                                !this.state.editMode ? (
                                <button
                                  className="text-lightBlue-500 bg-transparent border border-solid border-lightBlue-500 hover:bg-lightBlue-500 hover:text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                                  onClick={() => {
                                    openModalOrderTindakanPemohon({
                                      tindakanpemohons:
                                        node.tindakanpemohon_pemohonobyek,
                                    });
                                  }}
                                >
                                  <i className="fa-solid fa-arrow-down-a-z"></i>
                                </button>
                              ) : null}
                            </div>
                          </div>
                          {!this.state.editMode ? (
                            <CommonDropdown>
                              <DropDownItem
                                title="Edit"
                                cmd={(e) =>
                                  openModalPemohonobyek({
                                    selectedPemohonobyek: node,
                                    mode: "edit",
                                  })
                                }
                              />
                              <DropDownItem
                                title="Hapus"
                                cmd={(e) => this.deleteConfirmDialog(node)}
                              />
                              <DropDownItem
                                title="Format Indent List"
                                cmd={(e) => {
                                  if(node.parent){
                                    // const prn = getListPemohonobyekResult.find((data)=>data.id===node.parent)
                                    // const prnPath = xpath.pop()
                                    // const cnode = getNodeAtPath({treeData:getListPemohonobyekResult,path, getNodeKey})
                                    // const cnode = this.getParent(node, getListPemohonobyekResult)
                                    const cnode = this.getNodes(getListPemohonobyekResult,node.parent)
                                    if(cnode.length>0){
                                      // const cld = prn.children
                                      this.setState({curChildren:cnode})                                      
                                      this.setState({openFormatSuratModal:true})
                                    }
                                  }else{
                                    const sibling = getListPemohonobyekResult.filter((data)=>data.parent === null)
                                    this.setState({curChildren:sibling})                                      
                                    this.setState({openFormatSuratModal:true})

                                  }
                                }
                                }
                              />
                            </CommonDropdown>
                          ) : null}
                        </div>
                      );
                    },
                  };
                }}
              />
            ) : null}
          </div>
        </div>
        {this.state.openFormatSuratModal ? 
        <UpdateFormatSuratModal
          handleClose={()=>this.closeUpdateFormatSuratModal()}
          handleSave={(e)=>this.saveFormatSuratModal(e)}
        />
        :null}
      </DndProvider>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getListPemohonobyekError: state.pemohonobyeks.getListPemohonobyekError,
    getListPemohonobyekLoading: state.pemohonobyeks.getListPemohonobyekLoading,
    getListPemohonobyekResult: state.pemohonobyeks.getListPemohonobyekResult,
    deletePemohonobyekResult: state.pemohonobyeks.deletePemohonobyekResult,
    deletePemohonobyekError: state.pemohonobyeks.deletePemohonobyekError,
    isOpenPemohonobyek: state.pemohonobyeks.isOpenPemohonobyek,
    isOpenTindakanpemohon: state.tindakanpemohons.isOpenTindakanpemohon,
    updatePemohonobyekResult: state.pemohonobyeks.updatePemohonobyekResult,
    tambahPemohonobyekResult: state.pemohonobyeks.tambahPemohonobyekResult,
    updateTindakanpemohonResult:
      state.tindakanpemohons.updateTindakanpemohonResult,
    isOpenOrderTindakanpemohon:
      state.tindakanpemohons.isOpenOrderTindakanpemohon,
  };
};

export default connect(mapStateToProps, {
  listPemohonobyek,
  deletePemohonobyek,
  updateAktifPemohonobyek,
  updateOrderPemohonobyek,
  updateOrderPemohonobyekDb,
  updateFormatSuratPemohonobyek,
})(CardListPemohonobyek);
