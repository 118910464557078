import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CardTableAlamat } from "../../components/Cards/Alamats/CartTableAlamat";
import { motion } from "framer-motion";

export const Pagination = () => {
  const [reload, setReload] = useState(false);
  useEffect(() => {
    // let params = { page: 1 };
    // store.dispatch(listAlamat(params));
  }, []);

  return (
    <>
      <div className="flex flex-wrap mt-10">
        <div className="relative w-full mb-10 px-4">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            onClick={(e) => setReload(!reload)}
          >
            Reload
          </motion.button>
        </div>
        <div className="w-full mb-12 px-4">
          <CardTableAlamat reload={reload} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    alamats: state.alamats,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // listAlamat: (page) => dispatch(listAlamat(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
