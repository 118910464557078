import { motion } from "framer-motion";
import { dropIn } from "../Animations";
import { useEffect, useRef, useState } from "react";
import Backdrop from "../Backdrop";
import { connect } from "react-redux";
import {
  addTindakanpemohon,
  updateTindakanpemohon,
} from "../../../actions/tindakanpemohons";
import { ErrorAlert } from "../../Alerts/ErrorAlert";
import SingleSelectDbImage from "../../Selects/SingleSelectDbImage";

const EditTindakanTindakanpemohon = ({
  handleClose,
  addTindakanpemohon,
  errors,
  tindakanpemohon,
  mode = "add",
  updateTindakanpemohon,
}) => {
  const firstInput = useRef();

  const [formData, setFormData] = useState({
    id: null,
    urutan: null,
    judul: null,
    pemohonobyek: null,
    tindakan: null,
    uraian: null,
    text_uraian: "",
    formatsurat: null,
  });

  const { text_uraian, formatsurat } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setRefresh(!refresh);
    if (mode === "add") {
      const newTindakanpemohon = { ...formData };
      newTindakanpemohon.tindakan = newTindakanpemohon.tindakan.id;
      addTindakanpemohon(newTindakanpemohon);
    } else {
      const newTindakanpemohon = { ...formData };
      newTindakanpemohon.tindakan = newTindakanpemohon.tindakan.id;
      newTindakanpemohon.formatsurat = formatsurat.value;
      updateTindakanpemohon(newTindakanpemohon);
    }
  };

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setRefresh(true);
    return () => setRefresh(false);
  }, [errors]);

  useEffect(() => {
    if (tindakanpemohon && tindakanpemohon.tindakan) {
      let newTindakanpemohon = { ...tindakanpemohon };
      if (newTindakanpemohon.text_uraian === "") {
        newTindakanpemohon.text_uraian = tindakanpemohon.tindakan.uraian;
      }
      const nfs = {
        value: tindakanpemohon.formatsurat,
        label: tindakanpemohon.caption,
      };
      console.log(tindakanpemohon);

      newTindakanpemohon.formatsurat = nfs;
      setFormData(newTindakanpemohon);
    } else {
    }
    firstInput.current.focus();
  }, [tindakanpemohon]);

  return (
    <Backdrop>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="border-0 rounded-lg shadow-lg absolute flex flex-col w-1/2 outline-none focus:outline-none shadow-slate-700"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="flex items-start justify-between p-3 border-b border-solid bg-blueGray-100 rounded-t">
          <h3 className="text-xl font-semibold text-blueGray-400 uppercase">
            Form Tindakan Pemohon
          </h3>
          <button
            className="p-1 ml-auto bg-transparent border-0 text-blueGray-400 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            onClick={handleClose}
          >
            <span className="bg-transparent text-blueGray-500 h-6 w-6 text-xl block outline-none focus:outline-none">
              ×
            </span>
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className="relative p-2 flex-auto bg-blueGray-200">
            <div className="flex-auto px-5 lg:px-5 py-5 pt-0">
              {errors &&
                errors.msg &&
                errors.status &&
                errors.status === 400 &&
                refresh ? (
                <ErrorAlert errors={Object.entries(errors.msg)} />
              ) : null}

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Uraian
                  </label>
                  <textarea
                    ref={firstInput}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    rows="4"
                    name="text_uraian"
                    value={text_uraian}
                    onChange={onChange}
                  ></textarea>
                </div>
              </div>
              <div className="w-full lg:w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-formatsurat"
                  >
                    Format Surat
                  </label>
                  <SingleSelectDbImage
                    url={`/api/formatsurats/list`}
                    valueField="id"
                    labelField="caption"
                    imageField="img"
                    onChange={(e) => onSelectChange("formatsurat", e)}
                    value={formatsurat}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b bg-blueGray-100">
            <button
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded-2xl shadow-lg shadow-slate-500 hover:shadow-slate-800 outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded-2xl shadow-lg shadow-slate-500 hover:shadow-slate-800 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="submit"
            >
              Simpan
            </button>
          </div>
        </form>
      </motion.div>
    </Backdrop>
  );
};

const mapStateToProps = (state) => ({
  errors: state.tindakanpemohons.tambahTindakanpemohonError
    ? state.tindakanpemohons.tambahTindakanpemohonError
    : state.tindakanpemohons.updateTindakanpemohonError,
  isOpenTindakanpemohon: state.tindakanpemohons.isOpenTindakanpemohon,
});

export default connect(mapStateToProps, {
  addTindakanpemohon,
  updateTindakanpemohon,
})(EditTindakanTindakanpemohon);
