import React, { Component, useRef } from "react";
import Select from "react-select";
import axios from "axios";
import propTypes from "prop-types";

export class MultiSelectDbMultiLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
      value: [],
    };
    this.getValue = this.getValue.bind(this);
  }
  async getOptions() {
    const res = await axios.get(this.props.url);
    const data = res.data;
    const options = data.map((d) => {
      let xlabel = "";
      for (let index = 0; index < this.props.labelField.length; index++) {
        const element = d[this.props.labelField[index]];
        if (index === 0) {
          xlabel = element;
        } else {
          xlabel = xlabel.concat(" - ", element);
        }
      }
      return {
        value: d[this.props.valueField],
        label: xlabel,
      };
    });
    this.setState({ selectOptions: options });
    // this.getValue(options);
  }

  async getValue(options) {
    const flt = options.filter(
      (a) => a === this.props.value.filter((b) => b.id === a.id)
    );
    if (flt.length > 0) this.setState([...this.state.value, { value: flt[0] }]);
  }
  handleChange(e) {
    this.setState({ value: e });
  }

  async componentDidMount() {
    await this.getOptions();
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.value !== prevProps.value) {
  // this.getValue(this.state.selectOptions);
  //   }
  // }

  render() {
    return (
      <>
        <Select
          ref={this.props.innerRef}
          value={this.props.value}
          options={this.state.selectOptions}
          onChange={this.props.onChange}
          isMulti
        />
      </>
    );
  }
}
MultiSelectDbMultiLabel.defaultProps = {
  value: [],
  innerRef: null,
};
MultiSelectDbMultiLabel.propTypes = {
  url: propTypes.string.isRequired,
  value: propTypes.array,
  labelField: propTypes.array.isRequired,
  valueField: propTypes.string.isRequired,
  innerRef: propTypes.object,
};

export default MultiSelectDbMultiLabel;
