import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { getDetailObyek } from "../../../actions/obyeks";
import { openObyek, closeObyek } from "../../../actions/obyeks";
import AddObyekModal from "../../Modals/Obyek/AddObyekModal";

const CardDetailObyek = ({
  obyeks,
  getDetailObyek,
  openObyek,
  closeObyek,
  isOpenObyek,
  updateObyekResult,
}) => {
  const color = "light";
  const { getDetailObyekLoading, getDetailObyekResult, getDetailObyekError } =
    obyeks;
  const params = useParams();
  const [mode, setMode] = useState("add");
  const [selectedObyek, setSelectedObyek] = useState({});

  useEffect(() => {
    if (params.obyid) {
      getDetailObyek({ id: params.obyid });
    }
  }, [getDetailObyek, params.obyid]);

  useEffect(() => {
    if (params.obyid && updateObyekResult && isOpenObyek == false) {
      getDetailObyek({ id: params.obyid });
    }
  }, [params.obyid, updateObyekResult, isOpenObyek]);

  useEffect(() => {
    if (getDetailObyekResult) {
      setSelectedObyek(getDetailObyekResult);
    }
  }, [getDetailObyekResult]);

  const closeModalObyek = () => {
    closeObyek();
  };

  const openModalObyek = ({ mode = "add", selectedObyek = {} } = {}) => {
    if (selectedObyek && selectedObyek.jenishak) {
    } else {
      const permohonan_id = this.props.permohonans.getDetailPermohonanResult
        ? this.props.permohonans.getDetailPermohonanResult.id
        : {};
      selectedObyek.permohonan = permohonan_id;
    }
    setMode(mode);
    setSelectedObyek(selectedObyek);
    openObyek();
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full shadow-lg rounded h-full " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        {getDetailObyekLoading && (
          <div className="w-full absolute justify-center flex z-30 m-auto flex-col items-center bg-white h-32">
            <BeatLoader color="#36d7b7" size={20} />
          </div>
        )}
        {getDetailObyekResult ? (
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center mb-5">
              <div className="text-center flex justify-between w-full">
                <h3
                  className={
                    "font-semibold text-lg uppercase " +
                    (color === "light" ? "text-blueGray-700" : "text-white")
                  }
                >
                  Detail Obyek
                </h3>
                <button
                  className="text-lightBlue-500 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={(e) =>
                    openModalObyek({
                      selectedObyek: selectedObyek,
                      mode: "edit",
                    })
                  }
                >
                  <i className="fas fa-edit"></i>
                </button>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12">
                <div className="relative w-full mb-4">
                  <label
                    className="block uppercase text-blueGray-800 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nama Transaksi
                  </label>
                  <label
                    className="block uppercase text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    {getDetailObyekResult.jenispermohonan.nama_jenis_permohonan}
                  </label>
                  <label
                    className="block uppercase text-blueGray-800 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Alas Hak
                  </label>
                  <label
                    className="block uppercase text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    {getDetailObyekResult.jenishak.jenis_hak} No.{" "}
                    {getDetailObyekResult.nomor_hak}
                    {getDetailObyekResult.persil
                      ? `, Ps.${getDetailObyekResult.persil}`
                      : null}
                    {getDetailObyekResult.kelas
                      ? `, Kelas ${getDetailObyekResult.kelas}`
                      : null}
                    , L.{getDetailObyekResult.luas} M2
                  </label>
                  <label
                    className="block uppercase text-blueGray-800 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Penerima
                  </label>
                  <label
                    className="block uppercase text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    {getDetailObyekResult.penerima
                      ? getDetailObyekResult.penerima.nama_pemohon
                      : ""}
                  </label>

                  <label
                    className="block uppercase text-blueGray-800 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Lokasi
                  </label>
                  <label
                    className="block uppercase text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    {getDetailObyekResult.alamat.nama_desa} -{" "}
                    {getDetailObyekResult.alamat.nama_kecamatan}
                  </label>
                  <label
                    className="block uppercase text-blueGray-800 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Jenis Tanah
                  </label>
                  <label
                    className="block uppercase text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    {getDetailObyekResult.jenis_tanah}
                  </label>
                </div>
                <div className="text-center my-4 flex flex-col">
                  <Link
                    to={`/admin/permohonans/${params.id}/obyeks/${getDetailObyekResult.id}/pemohons`}
                    className="bg-blueGray-800 w-full text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:scale-105 hover:shadow-slate-700 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  >
                    PEMOHON
                  </Link>
                  <Link
                    to={`/admin/permohonans/${params.id}/obyeks/${getDetailObyekResult.id}/pilihsurats`}
                    className="bg-blueGray-800 w-full text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:scale-105 hover:shadow-slate-700 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  >
                    PILIH SURAT
                  </Link>
                  <Link
                    to={`/admin/permohonans/${params.id}/obyeks/${getDetailObyekResult.id}/surats`}
                    className="bg-blueGray-800 w-full text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:scale-105 hover:shadow-slate-700 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  >
                    PROSES SURAT
                  </Link>
                  <Link
                    to={`/admin/permohonans/${params.id}`}
                    className="bg-blueGray-800 w-full text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:scale-105 hover:shadow-slate-700 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  >
                    KEMBALI
                  </Link>
                </div>
              </div>
            </div>
            {isOpenObyek && (
              <AddObyekModal
                handleClose={closeModalObyek}
                mode={mode}
                obyek={selectedObyek}
              />
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    obyeks: state.obyeks,
    isOpenObyek: state.obyeks.isOpenObyek,
    updateObyekResult: state.obyeks.updateObyekResult,
  };
};

export default connect(mapStateToProps, {
  getDetailObyek,
  openObyek,
  closeObyek,
})(CardDetailObyek);
