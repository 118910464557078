import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import AlamatModal from "../../components/Modals/Alamat/AlamatModal";
const Animasi = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  return (
    <>
      <div className="flex flex-wrap mt-32">
        <div className="relative w-full mb-10 px-4">
          <div>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              onClick={() => (modalOpen ? close() : open())}
            >
              Launch modal
            </motion.button>
          </div>
          <AnimatePresence
            // Disable any initial animations on children that
            // are present when the component is first rendered
            initial={false}
            // Only render one component at a time.
            // The exiting component will finish its exit
            // animation before entering component is rendered
            exitBeforeEnter={true}
            // Fires when all exiting nodes have completed animating out
            onExitComplete={() => null}
          >
            {modalOpen && <AlamatModal handleClose={close} />}
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};

export default Animasi;
