import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import { id } from "date-fns/locale";

export default class DateField extends Component {
  constructor(props) {
    super(props);
    // this.state = { value: "" };
  }

  render() {
    registerLocale("id", id);
    return (
      <DatePicker
        selected={this.props.selected}
        value={this.props.value}
        onChange={this.props.onChange}
        type="text"
        className={this.props.className}
        locale={id}
        dateFormat="dd/MM/yyyy"
        customInput={
          <MaskedTextInput
            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
          />
        }
      />
    );
  }
}
