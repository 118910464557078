import React from "react";
import { Navigate, Outlet } from "react-router-dom";

// components

import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import HeaderStats from "../components/Headers/HeaderStats.js";
import FooterAdmin from "../components/Footers/FooterAdmin.js";
import { connect } from "react-redux";
import propTypes from "prop-types";
import HeaderBlank from "../components/Headers/HeaderBlank.js";
// views

function Admin({ auth }) {
  if (auth && !auth.isAuthenticated && !auth.isLoading) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <>
      <Sidebar auth={auth} />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderBlank />
        <div className="px-4 md:px-10 mx-auto w-full -m-28 md:min-h-screen-75 flex flex-col justify-between">
          <Outlet />
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
Admin.propTypes = {
  auth: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Admin);
