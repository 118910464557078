import {
  GET_LIST_CHART,
  TAMBAH_CHART,
  UPDATE_CHART,
  DELETE_CHART,
} from "../actions/charts";

const initialState = {
  getListChartLoading: false,
  getListChartResult: false,
  getListChartError: false,

  tambahChartLoading: false,
  tambahChartResult: false,
  tambahChartError: false,

  getDetailChartLoading: false,
  getDetailChartResult: false,
  getDetailChartError: false,

  updateChartLoading: false,
  updateChartResult: false,
  updateChartError: false,

  deleteChartLoading: false,
  deleteChartResult: false,
  deleteChartError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_CHART:
      return {
        ...state,
        getListChartLoading: action.payload.loading,
        getListChartResult: action.payload.data,
        getListChartError: action.payload.errorMessage,
      };

    case TAMBAH_CHART:
      return {
        ...state,
        tambahChartLoading: action.payload.loading,
        tambahChartResult: action.payload.data,
        tambahChartError: action.payload.errorMessage,
      };

    case UPDATE_CHART:
      return {
        ...state,
        updateChartLoading: action.payload.loading,
        updateChartResult: action.payload.data,
        updateChartError: action.payload.errorMessage,
      };

    case DELETE_CHART:
      return {
        ...state,
        deleteChartLoading: action.payload.loading,
        deleteChartResult: action.payload.data,
        deleteChartError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
