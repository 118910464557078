import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Admin from "./layouts/Admin";
import Auth from "./layouts/Auth.js";
import Dashboard from "./views/admin/Dashboard";
import Settings from "./views/admin/Settings";
// import Tables from "./views/admin/Tables";
import Login from "./views/auth/Login.js";
import Register from "./views/auth/Register.js";
import StafRoute from "./components/routes/StafRoute";
import { Daftar, Pagination } from "./views/staf";
import Animasi from "./views/staf/Animasi";
import Permohonan from "./views/admin/Permohonan/Permohonan";
import DetailPermohonan from "./views/admin/Permohonan/DetailPermohonan";
import DetailObyek from "./views/admin/Obyek/DetailObyek";
import DetailObyekSurat from "./views/admin/Obyek/DetailObyekSurat";
import DetailObyekPilihSurat from "./views/admin/Obyek/DetailObyekPilihSurat";
import ObyekLayout from "./layouts/ObyekLayout";
import Pejabat from "./views/admin/Pengaturan/Pejabat";
import Help from "./views/admin/help";
// import PengaturanLayout from "./layouts/PengaturanLayout";

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes key={location.pathname} location={location}>
        <Route path="admin" element={<Admin />}>
          <Route path="settings" element={<Settings />} />
          <Route path="pejabat" element={<Pejabat />} />
          <Route path="permohonans" element={<Permohonan />} />
          <Route path="permohonans/:id" element={<DetailPermohonan />} />
          <Route index element={<Dashboard />} />
          <Route path="help" element={<Help />} />
        </Route>
        {/* <Route path="pengaturan" element={<PengaturanLayout />}>
          <Route path="pejabat" element={<Pejabat />} />
          <Route index element={<Pejabat />} />
        </Route> */}
        <Route path="admin/permohonans" element={<ObyekLayout />}>
          <Route path=":id/obyeks/:obyid/pemohons" element={<DetailObyek />} />
          <Route
            path=":id/obyeks/:obyid/surats"
            element={<DetailObyekSurat />}
          />
          <Route
            path=":id/obyeks/:obyid/pilihsurats"
            element={<DetailObyekPilihSurat />}
          />
        </Route>
        <Route path="auth" element={<Auth />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route index element={<Login />} />
        </Route>
        <Route path="staf" element={<StafRoute />}>
          <Route path="daftar" element={<Daftar />} />
          <Route path="animasi" element={<Animasi />} />
          <Route index element={<Daftar />} />
        </Route>
        <Route path="*" element={<Navigate to="/auth" replace />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
