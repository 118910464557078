import { combineReducers } from "redux";
import leads from "./leads";
import errors from "./errors";
import messages from "./messages";
import auth from "./auth";
import alamats from "./alamats";
import permohonans from "./permohonans";
import obyeks from "./obyeks";
import pemohons from "./pemohons";
import pemohonobyeks from "./pemohonobyeks";
import suratobyeks from "./suratobyeks";
import tindakanpemohons from "./tindakanpemohons";
import saksiobyeks from "./saksiobyeks";
import pejabats from "./pejabats";
import charts from "./charts";
import artikels from "./artikels";
export default combineReducers({
  leads,
  errors,
  messages,
  auth,
  alamats,
  permohonans,
  obyeks,
  pemohons,
  pemohonobyeks,
  suratobyeks,
  tindakanpemohons,
  saksiobyeks,
  pejabats,
  charts,
  artikels,
});
