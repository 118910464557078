import { dispatchError, dispatchLoading, dispatchSuccess } from "../utils";
import axios from "axios";
import { arrayMoveImmutable } from "array-move";

export const GET_LIST_PEMOHONOBYEK = "GET_LIST_PEMOHONOBYEK";
export const TAMBAH_PEMOHONOBYEK = "TAMBAH_PEMOHONOBYEK";
export const GET_DETAIL_PEMOHONOBYEK = "GET_DETAIL_PEMOHONOBYEK";
export const UPDATE_PEMOHONOBYEK = "UPDATE_PEMOHONOBYEK";
export const DELETE_PEMOHONOBYEK = "DELETE_PEMOHONOBYEK";
export const OPEN_PEMOHONOBYEK = "OPEN_PEMOHONOBYEK";
export const CLOSE_PEMOHONOBYEK = "CLOSE_PEMOHONOBYEK";
export const listTablePemohonobyek =
  ({ oby_id, page = 1, order = "id", search = "" } = {}) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_PEMOHONOBYEK);
    axios
      .get(
        `/api/pemohonobyeks/obyeks/${oby_id}?page=${page} ${
          order ? "&order_by=" + order : ""
        }${search ? "&nama_pemohon=" + search : ""}`,
        tokenConfig()
      )
      .then((res) => {
        dispatchSuccess(dispatch, GET_LIST_PEMOHONOBYEK, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_LIST_PEMOHONOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const listPemohonobyek =
  ({ oby_id }) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_PEMOHONOBYEK);
    axios
      .get(`/api/pemohonobyeks/obyeks/${oby_id}/list`, tokenConfig())
      .then((res) => {
        dispatchSuccess(dispatch, GET_LIST_PEMOHONOBYEK, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_LIST_PEMOHONOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const getDetailPemohonobyek =
  ({ id }) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_DETAIL_PEMOHONOBYEK);
    axios
      .get(`/api/pemohonobyeks/${id}`, tokenConfig())
      .then((res) => {
        dispatchSuccess(dispatch, GET_DETAIL_PEMOHONOBYEK, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_DETAIL_PEMOHONOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const openPemohonobyek = () => (dispatch) => {
  dispatchLoading(dispatch, OPEN_PEMOHONOBYEK);
};
export const closePemohonobyek = () => (dispatch) => {
  dispatch({ type: CLOSE_PEMOHONOBYEK });
};

export const addPemohonobyek =
  ({
    pemohon,
    obyek,
    jenis_pemohon,
    formatsurat,
    tindakanpemohons,
    kuasa,
    sendiri,
    aktif,
  }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      pemohon,
      obyek,
      jenis_pemohon,
      formatsurat,
      tindakanpemohons,
      kuasa: kuasa ? 1 : 0,
      sendiri: sendiri ? 1 : 0,
      aktif: aktif ? 1 : 0,
    });
    dispatchLoading(dispatch, TAMBAH_PEMOHONOBYEK);
    dispatch({ type: OPEN_PEMOHONOBYEK });
    axios
      .post("/api/pemohonobyeks/", body, config)
      .then((res) => {
        dispatchSuccess(dispatch, TAMBAH_PEMOHONOBYEK, res.data);
        dispatch({ type: CLOSE_PEMOHONOBYEK });
      })
      .catch((err) => {
        dispatchError(dispatch, TAMBAH_PEMOHONOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_PEMOHONOBYEK });
      });
  };

export const updatePemohonobyek =
  ({
    id,
    pemohon,
    obyek,
    jenis_pemohon,
    formatsurat,
    tindakanpemohons,
    kuasa,
    sendiri,
    aktif,
  }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      pemohon,
      obyek,
      jenis_pemohon,
      formatsurat,
      tindakanpemohons,
      kuasa: kuasa ? 1 : 0,
      sendiri: sendiri ? 1 : 0,
      aktif: aktif ? 1 : 0,
    });
    dispatchLoading(dispatch, UPDATE_PEMOHONOBYEK);
    dispatch({ type: OPEN_PEMOHONOBYEK });
    axios
      .patch(`/api/pemohonobyeks/${id}/`, body, config)
      .then((res) => {
        dispatchSuccess(dispatch, UPDATE_PEMOHONOBYEK, res.data);
        dispatch({ type: CLOSE_PEMOHONOBYEK });
      })
      .catch((err) => {
        dispatchError(dispatch, UPDATE_PEMOHONOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_PEMOHONOBYEK });
      });
  };
export const updateOrderPemohonobyekDb = (pemohonobyeks) => (dispatch) => {
  updateOrderId(pemohonobyeks, null, dispatch).then((list) => {
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = list;
    axios
      .put(`/api/pemohonobyeks-bulkupdate/`, body, config)
      .then((res) => {
        dispatchSuccess(dispatch, UPDATE_PEMOHONOBYEK, res.data);
        // dispatchSuccess(dispatch, GET_LIST_PEMOHONOBYEK, pemohonobyeks);
      })
      .catch((err) => {
        dispatchError(dispatch, UPDATE_PEMOHONOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });

    // dispatchSuccess(dispatch, GET_LIST_PEMOHONOBYEK, pemohonobyeks)
  });

  // updateOrderId(pemohonobyeks, null, dispatch).then(
  //   (list) => console.log(list)
  // dispatchSuccess(dispatch, GET_LIST_PEMOHONOBYEK, pemohonobyeks)
  // );
};

export const updateOrderPemohonobyek = (pemohonobyeks) => (dispatch) => {
  dispatchSuccess(dispatch, GET_LIST_PEMOHONOBYEK, pemohonobyeks);
  // const config = {
  //   // baseURL: "http://127.0.0.1:8000",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // };

  // // Request Body
  // const body = JSON.stringify(pemohonobyeks.results);
  // //  dispatchLoading(dispatch, GET_LIST_PEMOHONOBYEK);
  // axios
  //   .patch(`/api/pemohonobyeks/updaktif/${id}`, body, config)
  //   .then((res) => {
  //     dispatchSuccess(dispatch, UPDATE_PEMOHONOBYEK, res.data);
  //   })
  //   .catch((err) => {
  //     dispatchError(dispatch, UPDATE_PEMOHONOBYEK, {
  //       msg: err.response.data,
  //       status: err.response.status,
  //     });
  //   });
  // const body = JSON.stringify(pemohonobyeks.results);
  // axios
  //   .put(`/api/pemohonobyeks-bulkupdate/`, body, config)
  //   .then((res) => {
  //     dispatchSuccess(dispatch, GET_LIST_PEMOHONOBYEK, pemohonobyeks);
  //   })
  //   .catch((err) => {
  //     dispatchError(dispatch, GET_LIST_PEMOHONOBYEK, {
  //       msg: err.response.data,
  //       status: err.response.status,
  //     });
  //   });
};
export const updateOrderId = (list, id, dispatch) => {
  return new Promise((resolve, reject) => {
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };
    let pmobjects = [];
    for (let index = 0; index < list.length; index++) {
      if (list[index].children.length > 0) {
        let obj = {
          id: list[index].id,
          urutan: index,
          parent: id,
          pemohon: list[index].pemohon,
          formatsurat: list[index].formatsurat,
          jenis_pemohon: list[index].jenis_pemohon,
        };
        pmobjects.push(obj);
        updateOrderId(list[index].children, list[index].id, dispatch).then(
          (list) => {
            for (let index = 0; index < list.length; index++) {
              const element = list[index];
              pmobjects.push(element);
            }
          }
        );
      } else {
        let obj = {
          id: list[index].id,
          urutan: index,
          parent: id,
          pemohon: list[index].pemohon,
          formatsurat: list[index].formatsurat,
          jenis_pemohon: list[index].jenis_pemohon,
        };
        pmobjects.push(obj);
      }
    }
    // const body = pmobjects;
    // axios
    //   .put(`/api/pemohonobyeks-bulkupdate/`, body, config)
    //   .then((res) => {
    //     // dispatchSuccess(dispatch, GET_LIST_PEMOHONOBYEK, pemohonobyeks);
    //     resolve(list);
    //   })
    //   .catch((err) => {
    //     dispatchError(dispatch, UPDATE_PEMOHONOBYEK, {
    //       msg: err.response.data,
    //       status: err.response.status,
    //     });
    //   });
    resolve(pmobjects);
  });
};

export const updateOrder = (list, id, dispatch) => {
  return new Promise((resolve, reject) => {
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    for (let index = 0; index < list.length; index++) {
      if (list[index].children.length > 0) {
        updateOrder(list[index].children, list[index].id, dispatch);
      } else {
        const body = {
          urutan: index,
          parent: id,
        };
        //  dispatchLoading(dispatch, GET_LIST_PEMOHONOBYEK);
        axios
          .patch(`/api/pemohonobyeks/updaktif/${list[index].id}`, body, config)
          .catch((err) => {
            dispatchError(dispatch, UPDATE_PEMOHONOBYEK, {
              msg: err.response.data,
              status: err.response.status,
            });
          });
      }
    }
    resolve(list);
  });
};

export const updateAktifPemohonobyek =
  ({ id, aktif }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      aktif: aktif ? 1 : 0,
    });
    dispatchLoading(dispatch, UPDATE_PEMOHONOBYEK);
    axios
      .patch(`/api/pemohonobyeks/updaktif/${id}`, body, config)
      .then((res) => {
        dispatchSuccess(dispatch, UPDATE_PEMOHONOBYEK, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, UPDATE_PEMOHONOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

  export const updateFormatSuratPemohonobyek =
  (pemohonObyeks) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify(
      pemohonObyeks,
    );
    axios
    .put(`/api/pemohonobyeks-bulkupdate/`, body, config)
    .then((res) => {
      dispatchSuccess(dispatch, UPDATE_PEMOHONOBYEK, res.data);
      // dispatchSuccess(dispatch, GET_LIST_PEMOHONOBYEK, pemohonobyeks);
    })
    .catch((err) => {
      dispatchError(dispatch, UPDATE_PEMOHONOBYEK, {
        msg: err.response.data,
        status: err.response.status,
      });
    });

  };


export const deletePemohonobyek = (id) => (dispatch) => {
  dispatchLoading(dispatch, DELETE_PEMOHONOBYEK);
  axios
    .delete(`/api/pemohonobyeks/${id}/`, tokenConfig())
    .then((res) => {
      dispatchSuccess(dispatch, DELETE_PEMOHONOBYEK, "DATA BERHASIL DIHAPUS");
    })
    .catch((err) =>
      dispatchError(dispatch, DELETE_PEMOHONOBYEK, {
        msg: err.response.data,
        status: err.response.status,
      })
    );
};

export const tokenConfig = () => {
  // Get token from state

  // Headers
  const config = {
    // baseURL: "http://127.0.0.1:8000",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  // if (token) {
  //   config.headers["Authorization"] = `Token ${token}`;
  // }

  return config;
};
