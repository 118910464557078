import * as React from "react";
import { useMotionValue, Reorder, useDragControls } from "framer-motion";
import { useRaisedShadow } from "./use-raised-shadow";
import { ReorderIcon } from "./Icon";

export const ItemSuratobyek = ({
  suratobyek,
  openModalSuratobyek,
  setError,
}) => {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const dragControls = useDragControls();

  return (
    <Reorder.Item
      value={suratobyek}
      id={suratobyek.id}
      style={{ boxShadow, y }}
      dragListener={false}
      dragControls={dragControls}
      as="tr"
    >
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap py-1 w-11">
        <ReorderIcon dragControls={dragControls} />
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap py-1">
        {suratobyek.jenis_surat === "Surat" ? (
          <i className="fas fa-file-text text-orange-500 mr-2"></i>
        ) : (
          <i className="fas fa-book text-orange-500 mr-2"></i>
        )}
        {suratobyek.nama_surat}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap py-1">
        {suratobyek.jenis_surat}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap py-1 text-left">
        <div>
          <button
            className="text-lightBlue-500 background-transparent font-bold uppercase px-4 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={(e) =>
              openModalSuratobyek({
                selectedSuratobyek: suratobyek,
                mode: "edit",
              })
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
              />
            </svg>
          </button>
        </div>
      </td>
    </Reorder.Item>
  );
};
