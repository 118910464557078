import { motion } from "framer-motion";
import { dropIn, flip, newspaper, zoom } from "../Animations";
import React, { useEffect, useRef, useState } from "react";
import Backdrop from "../Backdrop";
import SingleSelectDb from "../../Selects/SingleSelectDb";
import { connect } from "react-redux";
import { addObyek, updateObyek, prevPageObyek } from "../../../actions/obyeks";
import { ErrorAlert } from "../../Alerts/ErrorAlert";
import AlamatByKedudukanSelect from "../../Selects/AlamatByKedudukanSelect";
import Select from "react-select";
import { NumericFormat, PatternFormat } from "react-number-format";
import { format } from "date-fns";
import DateField from "../../Inputs/DateField";

const AddObyekModal = ({
  handleClose,
  addObyek,
  errors,
  isOpenObyek,
  obyek,
  mode = "add",
  updateObyek,
  pageObyek,
  prevPageObyek,
}) => {
  const firstInput = useRef();
  const secondInput = useRef();
  const thirdInput = useRef();

  const JENISTANAH_OPTIONS = [
    { value: "Pekarangan", label: "Pekarangan" },
    { value: "Perumahan", label: "Perumahan" },
    { value: "Non Pertanian", label: "Non Pertanian" },
    { value: "Pertanian", label: "Pertanian" },
    { value: "Permukiman", label: "Permukiman" },
  ];

  const JENIS_SU_OPTIONS = [
    { value: "Surat Ukur", label: "Surat Ukur" },
    { value: "Gambar Situasi", label: "Gambar Situasi" },
    { value: "Peta Bidang Tanah", label: "Peta Bidang Tanah" },
  ];

  const [formData, setFormData] = useState({
    alamat: {},
    jenishak: {},
    nomor_hak: "",
    persil: "",
    kelas: "",
    luas: 0,
    luas_bangunan: 0,
    atas_nama: "",
    nib: "",
    nop: "",
    jenis_tanah: JENISTANAH_OPTIONS[0],
    batas_utara: "",
    batas_timur: "",
    batas_selatan: "",
    batas_barat: "",
    tanggal_surat: new Date(),
    tanggal_su: new Date(),
    jenis_su: JENIS_SU_OPTIONS[0],
    nomor_su: "",
    jenispermohonan: {},
    permohonan: {},
    harga: 0,
    fharga: null,
  });
  const {
    alamat,
    jenishak,
    nomor_hak,
    persil,
    kelas,
    luas,
    luas_bangunan,
    atas_nama,
    nib,
    nop,
    jenis_tanah,
    batas_utara,
    batas_timur,
    batas_selatan,
    batas_barat,
    tanggal_surat,
    tanggal_su,
    jenis_su,
    nomor_su,
    jenispermohonan,
    permohonan,
    harga,
    fharga,
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setRefresh(!refresh);
    if (mode === "add") {
      const newObyek = {
        alamat: alamat.id ? alamat.id : null,
        jenishak: jenishak.value ? jenishak.value : null,
        nomor_hak,
        persil,
        kelas,
        luas,
        luas_bangunan,
        atas_nama,
        nib,
        nop,
        jenis_tanah: jenis_tanah.value ? jenis_tanah.value : null,
        batas_utara,
        batas_timur,
        batas_selatan,
        batas_barat,
        tanggal_surat: format(tanggal_surat, "yyyy-MM-dd"),
        tanggal_su: format(tanggal_su, "yyyy-MM-dd"),
        jenis_su: jenis_su.value ? jenis_su.value : null,
        nomor_su,
        jenispermohonan: jenispermohonan.value ? jenispermohonan.value : null,
        permohonan: obyek.permohonan,
        page: pageObyek,
        harga,
      };
      addObyek(newObyek);
    } else {
      const newObyek = {
        id: obyek.id,
        alamat: alamat.id ? alamat.id : null,
        jenishak: jenishak.value ? jenishak.value : null,
        nomor_hak,
        persil,
        kelas,
        luas,
        luas_bangunan,
        atas_nama,
        nib,
        nop,
        jenis_tanah: jenis_tanah.value ? jenis_tanah.value : null,
        batas_utara,
        batas_timur,
        batas_selatan,
        batas_barat,
        tanggal_surat: format(tanggal_surat, "yyyy-MM-dd"),
        tanggal_su: format(tanggal_su, "yyyy-MM-dd"),
        jenis_su: jenis_su.value ? jenis_su.value : null,
        nomor_su,
        jenispermohonan: jenispermohonan.value ? jenispermohonan.value : null,
        permohonan: obyek.permohonan,
        page: pageObyek,
        harga,
      };
      updateObyek(newObyek);
    }
  };
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setRefresh(true);
    // if (!isOpenObyek && page === 3) {
    //   handleClose();
    // }
    return () => setRefresh(false);
  }, [errors]);

  useEffect(() => {
    if (obyek && obyek.jenishak) {
      let newOby = { ...obyek };
      const jh = newOby.jenishak;
      const njh = {
        value: jh.id,
        label: jh.jenis_hak,
      };
      newOby.fharga = newOby.harga;
      newOby.jenishak = njh;
      const jsu = newOby.jenis_su;
      const njsu = {
        value: jsu,
        label: jsu,
      };
      newOby.jenis_su = njsu;
      const jt = newOby.jenis_tanah;
      const njt = {
        value: jt,
        label: jt,
      };
      newOby.jenis_tanah = njt;
      newOby.tanggal_su = new Date(newOby.tanggal_su);
      newOby.tanggal_surat = new Date(newOby.tanggal_surat);
      newOby.jenispermohonan = {
        value: newOby.jenispermohonan.id,
        label: newOby.jenispermohonan.nama_jenis_permohonan,
      };

      setFormData(newOby);
    }
  }, [obyek]);
  useEffect(() => {
    if (pageObyek === 1) {
      firstInput.current.focus();
    } else if (pageObyek === 2) {
      secondInput.current.focus();
    } else if (pageObyek === 3) {
      thirdInput.current.focus();
    }
  }, [pageObyek]);

  return (
    <Backdrop>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="border-0 rounded-lg shadow-lg absolute flex flex-col w-11/12 md:w-3/4 lg:w-1/2  outline-none focus:outline-none shadow-slate-700"
        variants={zoom}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="flex items-start justify-between p-3 border-b border-solid bg-blueGray-100 rounded-t">
          <h3 className="text-xl font-semibold text-blueGray-400 uppercase">
            Form Obyek
          </h3>
          <button
            className="p-1 ml-auto bg-transparent border-0 text-blueGray-400 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            onClick={handleClose}
          >
            <span className="bg-transparent text-blueGray-500 h-6 w-6 text-xl block outline-none focus:outline-none">
              ×
            </span>
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className="relative p-2 flex-auto bg-blueGray-200">
            <div className="flex-auto px-5 lg:px-5 py-5 pt-0">
              {errors &&
              errors.msg &&
              errors.status &&
              errors.status === 400 &&
              refresh ? (
                <ErrorAlert errors={Object.entries(errors.msg)} />
              ) : null}
              {pageObyek === 1 ? (
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Jenis Permohonan
                      </label>
                      <SingleSelectDb
                        url="/api/jenispermohonans/list"
                        valueField="id"
                        labelField="nama_jenis_permohonan"
                        innerRef={firstInput}
                        onChange={(e) => onSelectChange("jenispermohonan", e)}
                        value={jenispermohonan}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Jenis Hak
                      </label>
                      <SingleSelectDb
                        url="/api/jenishaks/list"
                        valueField="id"
                        labelField="jenis_hak"
                        onChange={(e) => onSelectChange("jenishak", e)}
                        value={jenishak}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Nomor Hak
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={nomor_hak}
                        onChange={onChange}
                        name="nomor_hak"
                      />
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Persil
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={persil}
                        onChange={onChange}
                        name="persil"
                      />
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Kelas
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={kelas}
                        onChange={onChange}
                        name="kelas"
                      />
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Atas Nama
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={atas_nama}
                        onChange={onChange}
                        name="atas_nama"
                      />
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Luas Tanah
                      </label>
                      <NumericFormat
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={luas}
                        thousandSeparator="."
                        decimalSeparator=","
                        type="number"
                        onValueChange={(values, sourceInfo) => {
                          onSelectChange("luas", values.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Luas Bangunan
                      </label>
                      <NumericFormat
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={luas_bangunan}
                        thousandSeparator="."
                        decimalSeparator=","
                        type="number"
                        onValueChange={(values, sourceInfo) => {
                          onSelectChange("luas_bangunan", values.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {pageObyek === 2 ? (
                <div className="flex flex-wrap">
                  <div className="w-1/2 lg:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        NIB
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={nib}
                        onChange={onChange}
                        name="nib"
                        ref={secondInput}
                      />
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Jenis Tanah
                      </label>
                      {/* <input
                    type="text"
                    className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={jenis_tanah}
                    onChange={onChange}
                    name="jenis_tanah"
                  /> */}
                      <Select
                        classNamePrefix="select"
                        name="jenis_tanah"
                        options={JENISTANAH_OPTIONS}
                        onChange={(e) => onSelectChange("jenis_tanah", e)}
                        value={jenis_tanah}
                      />
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-1/3 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Surat Ukur/GS
                      </label>
                      <Select
                        classNamePrefix="select"
                        defaultValue={JENIS_SU_OPTIONS[0]}
                        name="jenis_su"
                        options={JENIS_SU_OPTIONS}
                        onChange={(e) => onSelectChange("jenis_su", e)}
                        value={jenis_su}
                      />
                    </div>
                  </div>

                  <div className="w-1/2 lg:w-1/3 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Nomor SU/GS
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={nomor_su}
                        onChange={onChange}
                        name="nomor_su"
                      />
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-1/3 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Tanggal SU/GS
                      </label>
                      <DateField
                        selected={tanggal_su}
                        value={tanggal_su}
                        onChange={(date) => onSelectChange("tanggal_su", date)}
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        NOP
                      </label>
                      <PatternFormat
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={nop}
                        format="##.##.###.###.###-####.#"
                        allowEmptyFormatting
                        mask="_"
                        name="nop"
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  {/* <div className="w-1/2 lg:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Tanggal Surat
                      </label>
                      <DatePicker
                        selected={tanggal_surat}
                        value={tanggal_surat}
                        onChange={(date) =>
                          onSelectChange("tanggal_surat", date)
                        }
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        locale={id}
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div> */}
                  <div className="w-full lg:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Lokasi Obyek
                      </label>
                      <AlamatByKedudukanSelect
                        namaDesa={""}
                        value={alamat}
                        onChange={(e) => onSelectChange("alamat", e)}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {pageObyek === 3 ? (
                <>
                  <h6 className="text-blueGray-400 text-sm mt-2 mb-2 font-bold uppercase">
                    Batas-Batas
                  </h6>
                  <div className="flex flex-wrap">
                    <div className="w-1/2 lg:w-1/2 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Utara
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={batas_utara}
                          onChange={onChange}
                          name="batas_utara"
                          ref={thirdInput}
                        />
                      </div>
                    </div>
                    <div className="w-1/2 lg:w-1/2 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Timur
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={batas_timur}
                          onChange={onChange}
                          name="batas_timur"
                        />
                      </div>
                    </div>
                    <div className="w-1/2 lg:w-1/2 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Selatan
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={batas_selatan}
                          onChange={onChange}
                          name="batas_selatan"
                        />
                      </div>
                    </div>
                    <div className="w-1/2 lg:w-1/2 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Barat
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={batas_barat}
                          onChange={onChange}
                          name="batas_barat"
                        />
                      </div>
                    </div>
                    <div className="w-1/2 lg:w-1/2 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Harga (Rp)
                        </label>
                        <NumericFormat
                          className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={fharga}
                          thousandSeparator="."
                          decimalSeparator=","
                          type="text"
                          onValueChange={(values, sourceInfo) => {
                            onSelectChange("fharga", values.formattedValue);
                            onSelectChange("harga", values.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-between p-4 border-t border-solid border-blueGray-200 rounded-b bg-blueGray-100">
            <button
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded shadow-lg shadow-slate-500 hover:shadow-slate-800 outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleClose}
            >
              Close
            </button>
            {pageObyek > 1 ? (
              <button
                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded shadow-lg shadow-slate-500 hover:shadow-slate-800 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  prevPageObyek();
                }}
              >
                Sebelumnya
              </button>
            ) : null}
            <button
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded shadow-lg shadow-slate-500 hover:shadow-slate-800 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="submit"
            >
              {pageObyek === 3 ? "Simpan" : "Selanjutnya"}
            </button>
          </div>
        </form>
      </motion.div>
    </Backdrop>
  );
};

const mapStateToProps = (state) => ({
  errors: state.obyeks.tambahObyekError
    ? state.obyeks.tambahObyekError
    : state.obyeks.updateObyekError,
  isOpenObyek: state.obyeks.isOpenObyek,
  pageObyek: state.obyeks.pageObyek,
});

export default connect(mapStateToProps, {
  addObyek,
  updateObyek,
  prevPageObyek,
})(AddObyekModal);
