import React, { Component } from "react";
import Select from "react-select";
import axios from "axios";
import propTypes from "prop-types";

export class SingleSelectDb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
      value: null,
    };
    this.getValue = this.getValue.bind(this);
  }
  async getOptions() {
    const res = await axios.get(this.props.url);
    const data = res.data;

    const options = data.map((d) => ({
      value: d[this.props.valueField],
      // label: d[this.props.labelField],
      label: (
        <div className="flex justify-between items-center content-center px-2 py-1">
          <span>{d[this.props.labelField]}</span>
          {this.props.imageField ? (
            <img
              width={24}
              height={24}
              className="shadow-sm border-lightBlue-500"
              src={d[this.props.imageField]}
            />
          ) : null}
        </div>
      ),
    }));
    this.setState({ selectOptions: options });
    if (!this.props.value) {
      this.props.onChange(options[0]);
    }
  }

  async getValue(options) {
    const flt = options.filter((a) => a === this.props.value);
    if (flt.length > 0) this.setState({ value: flt[0] });
  }
  handleChange(e) {
    this.setState({ value: e });
  }

  async componentDidMount() {
    await this.getOptions();
    // if (!this.props.value) {
    //   this.setState({ value: this.state.selectOptions[0] });
    // }
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.value !== prevProps.value) {
  //   }
  // }

  render() {
    return (
      <>
        <Select
          ref={this.props.innerRef}
          value={this.props.value}
          options={this.state.selectOptions}
          onChange={this.props.onChange}
        />
      </>
    );
  }
}
SingleSelectDb.defaultProps = {
  value: {},
  innerRef: null,
};
SingleSelectDb.propTypes = {
  url: propTypes.string.isRequired,
  value: propTypes.object,
  labelField: propTypes.string.isRequired,
  valueField: propTypes.string.isRequired,
  innerRef: propTypes.object,
};

export default SingleSelectDb;
