import {
  GET_LIST_ARTIKEL,
  TAMBAH_ARTIKEL,
  UPDATE_ARTIKEL,
  DELETE_ARTIKEL,
  GET_DETAIL_ARTIKEL,
} from "../actions/artikels";

const initialState = {
  getListArtikelLoading: false,
  getListArtikelResult: false,
  getListArtikelError: false,

  getDetailArtikelLoading: false,
  getDetailArtikelResult: false,
  getDetailArtikelError: false,

  tambahArtikelLoading: false,
  tambahArtikelResult: false,
  tambahArtikelError: false,

  getDetailArtikelLoading: false,
  getDetailArtikelResult: false,
  getDetailArtikelError: false,

  updateArtikelLoading: false,
  updateArtikelResult: false,
  updateArtikelError: false,

  deleteArtikelLoading: false,
  deleteArtikelResult: false,
  deleteArtikelError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_ARTIKEL:
      return {
        ...state,
        getListArtikelLoading: action.payload.loading,
        getListArtikelResult: action.payload.data,
        getListArtikelError: action.payload.errorMessage,
      };
    case GET_DETAIL_ARTIKEL:
      return {
        ...state,
        getDetailArtikelLoading: action.payload.loading,
        getDetailArtikelResult: action.payload.data,
        getDetailArtikelError: action.payload.errorMessage,
      };

    case TAMBAH_ARTIKEL:
      return {
        ...state,
        tambahArtikelLoading: action.payload.loading,
        tambahArtikelResult: action.payload.data,
        tambahArtikelError: action.payload.errorMessage,
      };

    case UPDATE_ARTIKEL:
      return {
        ...state,
        updateArtikelLoading: action.payload.loading,
        updateArtikelResult: action.payload.data,
        updateArtikelError: action.payload.errorMessage,
      };

    case DELETE_ARTIKEL:
      return {
        ...state,
        deleteArtikelLoading: action.payload.loading,
        deleteArtikelResult: action.payload.data,
        deleteArtikelError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
