import { dispatchError, dispatchLoading, dispatchSuccess } from "../utils";
import axios from "axios";
import { returnErrors } from "./messages";

export const GET_LIST_CHART = "GET_LIST_CHART";
export const TAMBAH_CHART = "TAMBAH_CHART";
export const GET_DETAIL_CHART = "GET_DETAIL_CHART";
export const UPDATE_CHART = "UPDATE_CHART";
export const DELETE_CHART = "DELETE_CHART";

export const listChartPermohonan = () => (dispatch) => {
  dispatchLoading(dispatch, GET_LIST_CHART);

  axios
    .get(`/api/charts/permohonans`, tokenConfig())
    .then((res) => {
      dispatchSuccess(dispatch, GET_LIST_CHART, res.data);
    })
    .catch((err) => {
      dispatchError(
        dispatch,
        GET_LIST_CHART,
        returnErrors(err.response.data, err.response.status)
      );
    });
};

export const tokenConfig = () => {
  // Get token from state

  // Headers
  const config = {
    // baseURL: "http://127.0.0.1:8000",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  // if (token) {
  //   config.headers["Authorization"] = `Token ${token}`;
  // }

  return config;
};
