import { dispatchError, dispatchLoading, dispatchSuccess } from "../utils";
import axios from "axios";

export const GET_LIST_SAKSIOBYEK = "GET_LIST_SAKSIOBYEK";
export const GET_LIST_SURAT_IN_SAKSIOBYEK = "GET_LIST_SURAT_IN_SAKSIOBYEK";
export const GET_LIST_SURAT_NOT_IN_SAKSIOBYEK =
  "GET_LIST_SURAT_NOT_IN_SAKSIOBYEK";

export const TAMBAH_SAKSIOBYEK = "TAMBAH_SAKSIOBYEK";
export const GET_DETAIL_SAKSIOBYEK = "GET_DETAIL_SAKSIOBYEK";
export const UPDATE_SAKSIOBYEK = "UPDATE_SAKSIOBYEK";
export const DELETE_SAKSIOBYEK = "DELETE_SAKSIOBYEK";
export const OPEN_SAKSIOBYEK = "OPEN_SAKSIOBYEK";
export const CLOSE_SAKSIOBYEK = "CLOSE_SAKSIOBYEK";
export const listTableSaksiobyek =
  ({ oby_id, page = 1, order = "id", search = "" } = {}) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_SAKSIOBYEK);
    axios
      .get(
        `/api/saksiobyeks/obyeks/${oby_id}?page=${page} ${
          order ? "&order_by=" + order : ""
        }${search ? "&nama_surat=" + search : ""}`,
        tokenConfig()
      )
      .then((res) => {
        dispatchSuccess(dispatch, GET_LIST_SAKSIOBYEK, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_LIST_SAKSIOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const listSaksiobyek =
  ({ oby_id }) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_SAKSIOBYEK);
    axios
      .get(`/api/saksiobyeks/obyeks/${oby_id}/list`, tokenConfig())
      .then((res) => {
        dispatchSuccess(dispatch, GET_LIST_SAKSIOBYEK, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_LIST_SAKSIOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };
export const listSuratInSaksiobyek = (oby_id) => (dispatch) => {
  dispatchLoading(dispatch, GET_LIST_SURAT_IN_SAKSIOBYEK);
  axios
    .get(`/api/suratinsuratobyek/obyeks/${oby_id}/`, tokenConfig())
    .then((res) => {
      dispatchSuccess(dispatch, GET_LIST_SURAT_IN_SAKSIOBYEK, res.data);
    })
    .catch((err) => {
      dispatchError(dispatch, GET_LIST_SURAT_IN_SAKSIOBYEK, {
        msg: err.response.data,
        status: err.response.status,
      });
    });
};
export const listSuratNotInSaksiobyek = (oby_id) => (dispatch) => {
  dispatchLoading(dispatch, GET_LIST_SURAT_NOT_IN_SAKSIOBYEK);
  axios
    .get(`/api/suratnotinsuratobyek/obyeks/${oby_id}/`, tokenConfig())
    .then((res) => {
      dispatchSuccess(dispatch, GET_LIST_SURAT_NOT_IN_SAKSIOBYEK, res.data);
    })
    .catch((err) => {
      dispatchError(dispatch, GET_LIST_SURAT_NOT_IN_SAKSIOBYEK, {
        msg: err.response.data,
        status: err.response.status,
      });
    });
};

export const getDetailSaksiobyek =
  ({ id }) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_DETAIL_SAKSIOBYEK);
    axios
      .get(`/api/saksiobyeks/${id}`, tokenConfig())
      .then((res) => {
        dispatchSuccess(dispatch, GET_DETAIL_SAKSIOBYEK, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_DETAIL_SAKSIOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const openSaksiobyek = () => (dispatch) => {
  dispatchLoading(dispatch, OPEN_SAKSIOBYEK);
};
export const closeSaksiobyek = () => (dispatch) => {
  dispatch({ type: CLOSE_SAKSIOBYEK });
};

export const addSaksiobyek =
  ({ saksi, obyek }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      saksi,
      obyek,
    });
    dispatchLoading(dispatch, TAMBAH_SAKSIOBYEK);
    dispatch({ type: OPEN_SAKSIOBYEK });
    axios
      .post("/api/saksiobyeks/", body, config)
      .then((res) => {
        dispatchSuccess(dispatch, TAMBAH_SAKSIOBYEK, res.data);
        dispatch({ type: CLOSE_SAKSIOBYEK });
      })
      .catch((err) => {
        dispatchError(dispatch, TAMBAH_SAKSIOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_SAKSIOBYEK });
      });
  };

export const updateSaksiobyek =
  ({ id, saksi, obyek }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      saksi,
      obyek,
    });
    dispatchLoading(dispatch, UPDATE_SAKSIOBYEK);
    dispatch({ type: OPEN_SAKSIOBYEK });
    axios
      .patch(`/api/saksiobyeks/${id}/`, body, config)
      .then((res) => {
        dispatchSuccess(dispatch, UPDATE_SAKSIOBYEK, res.data);
        dispatch({ type: CLOSE_SAKSIOBYEK });
      })
      .catch((err) => {
        dispatchError(dispatch, UPDATE_SAKSIOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_SAKSIOBYEK });
      });
  };
export const updateOrderSaksiobyekDb = (saksiobyeks) => (dispatch) => {
  updateOrderId(saksiobyeks.results).then((list) => {
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = list;
    console.log(body);
    axios
      .put(`/api/saksiobyeks-bulkupdate/`, body, config)
      .then((res) => {
        dispatchSuccess(dispatch, GET_LIST_SAKSIOBYEK, saksiobyeks);
      })
      .catch((err) => {
        dispatchError(dispatch, UPDATE_SAKSIOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });

    // dispatchSuccess(dispatch, GET_LIST_SAKSIOBYEK, saksiobyeks)
  });
  // updateOrderId(saksiobyeks, null, dispatch).then(
  //   (list) => console.log(list)
  // dispatchSuccess(dispatch, GET_LIST_SAKSIOBYEK, saksiobyeks)
  // );
};

export const updateListSaksiobyek = (obyid, surats) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  //dispatchLoading(dispatch, GET_LIST_SURAT_IN_SAKSIOBYEK);
  const body = { surats };
  axios
    .put(`/api/obyeks/updlistsuratobyek/${obyid}`, body, config)
    .then((res) => {
      //  dispatchSuccess(dispatch, GET_LIST_SURAT_IN_SAKSIOBYEK, surats);
    })
    .catch((err) => {
      dispatchError(dispatch, GET_LIST_SURAT_IN_SAKSIOBYEK, {
        msg: err.response.data,
        status: err.response.status,
      });
    });
};

export const updateOrderSaksiobyek = (saksiobyeks) => (dispatch) => {
  dispatchSuccess(dispatch, GET_LIST_SAKSIOBYEK, saksiobyeks);
  // const config = {
  //   // baseURL: "http://127.0.0.1:8000",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // };

  // // Request Body
  // const body = JSON.stringify(saksiobyeks.results);
  // //  dispatchLoading(dispatch, GET_LIST_SAKSIOBYEK);
  // axios
  //   .patch(`/api/saksiobyeks/updaktif/${id}`, body, config)
  //   .then((res) => {
  //     dispatchSuccess(dispatch, UPDATE_SAKSIOBYEK, res.data);
  //   })
  //   .catch((err) => {
  //     dispatchError(dispatch, UPDATE_SAKSIOBYEK, {
  //       msg: err.response.data,
  //       status: err.response.status,
  //     });
  //   });
  // const body = JSON.stringify(saksiobyeks.results);
  // axios
  //   .put(`/api/saksiobyeks-bulkupdate/`, body, config)
  //   .then((res) => {
  //     dispatchSuccess(dispatch, GET_LIST_SAKSIOBYEK, saksiobyeks);
  //   })
  //   .catch((err) => {
  //     dispatchError(dispatch, GET_LIST_SAKSIOBYEK, {
  //       msg: err.response.data,
  //       status: err.response.status,
  //     });
  //   });
};
export const updateOrderId = (list) => {
  return new Promise((resolve, reject) => {
    let pmobjects = [];
    for (let index = 0; index < list.length; index++) {
      let obj = {
        id: list[index].id,
        urutan: index,
        surat: list[index].surat,
      };
      pmobjects.push(obj);
    }
    resolve(pmobjects);
  });
};

export const updateOrder = (list, id, dispatch) => {
  return new Promise((resolve, reject) => {
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    for (let index = 0; index < list.length; index++) {
      if (list[index].children.length > 0) {
        updateOrder(list[index].children, list[index].id, dispatch);
      } else {
        const body = {
          urutan: index,
          parent: id,
        };
        //  dispatchLoading(dispatch, GET_LIST_SAKSIOBYEK);
        axios
          .patch(`/api/saksiobyeks/updaktif/${list[index].id}`, body, config)
          .catch((err) => {
            dispatchError(dispatch, UPDATE_SAKSIOBYEK, {
              msg: err.response.data,
              status: err.response.status,
            });
          });
      }
    }
    resolve(list);
  });
};
export const updateAktifSaksiobyek =
  ({ id, aktif }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      aktif: aktif ? 1 : 0,
    });
    dispatchLoading(dispatch, UPDATE_SAKSIOBYEK);
    axios
      .patch(`/api/saksiobyeks/updaktif/${id}`, body, config)
      .then((res) => {
        dispatchSuccess(dispatch, UPDATE_SAKSIOBYEK, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, UPDATE_SAKSIOBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const deleteSaksiobyek = (id) => (dispatch) => {
  dispatchLoading(dispatch, DELETE_SAKSIOBYEK);
  axios
    .delete(`/api/saksiobyeks/${id}/`, tokenConfig())
    .then((res) => {
      dispatchSuccess(dispatch, DELETE_SAKSIOBYEK, "DATA BERHASIL DIHAPUS");
    })
    .catch((err) =>
      dispatchError(dispatch, DELETE_SAKSIOBYEK, {
        msg: err.response.data,
        status: err.response.status,
      })
    );
};

export const tokenConfig = () => {
  // Get token from state

  // Headers
  const config = {
    // baseURL: "http://127.0.0.1:8000",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  // if (token) {
  //   config.headers["Authorization"] = `Token ${token}`;
  // }

  return config;
};
