import React from "react";
import { motion } from "framer-motion";

export const ALERT_ERROR = "ALERT_ERROR";
export const ALERT_SUCCESS = "ALERT_SUCCESS";
export const ALERT_INFO = "ALERT_INFO";
export const FormAlert = ({
  alertType = ALERT_INFO,
  title = "",
  text = "",
  variants = null,
  onRemove = () => {},
}) => {
  if (alertType === ALERT_ERROR)
    return (
      <motion.div
        className="bg-red-100 border border-red-400 text-red-700 px-2 py-2 rounded relative mb-2"
        role="alert"
        variants={variants}
        onTap={onRemove}
        layout
        exit={{ opacity: 0, y: 50, transition: { delay: 0.2 } }}
      >
        <strong className="font-bold mr-2 uppercase">{title}</strong>
        <span className="block sm:inline">{text}</span>
        <span className="absolute top-0 bottom-0 right-0 px-2 py-3">
          <svg
            className="fill-current h-4 w-4 text-red-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      </motion.div>
    );
  if (alertType === ALERT_INFO)
    return (
      <motion.div
        className="bg-blue-100 border border-blue-500 text-red-700 px-2 py-2 rounded relative mb-2"
        role="alert"
        variants={variants}
        onTap={onRemove}
        layout
        exit={{ opacity: 0, y: 50, transition: { delay: 0.2 } }}
      >
        <strong className="font-bold mr-2 uppercase">{title}</strong>
        <span className="block sm:inline">{text}</span>
        <span className="absolute top-0 bottom-0 right-0 px-2 py-3">
          <svg
            className="fill-current h-4 w-4 text-blue-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      </motion.div>
    );
  if (alertType === ALERT_SUCCESS)
    return (
      <motion.div
        className="bg-red-100 border border-teal-500 text-teal-900 px-2 py-2 rounded relative mb-2"
        role="alert"
        variants={variants}
        onTap={onRemove}
        layout
        exit={{ opacity: 0, y: 50, transition: { delay: 0.2 } }}
      >
        <strong className="font-bold mr-2 uppercase">{title}</strong>
        <span className="block sm:inline">{text}</span>
        <span className="absolute top-0 bottom-0 right-0 px-2 py-3">
          <svg
            className="fill-current h-4 w-4 text-teal-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      </motion.div>
    );
};
export default FormAlert;
