import React, { useState } from "react";
import { Reorder } from "framer-motion";
import { ItemTindakanpemohon } from "./ItemTindakanpemohon";

export const CardTableTindakanpemohon = ({ tindakanpemohons, updateOrder }) => {
  const color = "dark";
  // const controls = useDragControls();
  const [ctindakanpemohons, setCtindakanpemohons] = useState(tindakanpemohons);
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-4 align-middle border border-solid py-4 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  <span>#</span>
                </th>
                <th
                  className={
                    "px-4 align-middle border border-solid py-4 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  <span>Uraian</span>
                </th>
              </tr>
            </thead>
            {ctindakanpemohons && (
              <Reorder.Group
                axis="y"
                values={ctindakanpemohons}
                as="tbody"
                onReorder={(e) => {
                  const newitem = [...e];
                  setCtindakanpemohons(newitem);
                  updateOrder(newitem);
                }}
              >
                {ctindakanpemohons &&
                  ctindakanpemohons.map((tindakanpemohon, index) => {
                    return (
                      <ItemTindakanpemohon
                        key={tindakanpemohon.id}
                        tindakanpemohon={tindakanpemohon}
                      />
                    );
                  })}
              </Reorder.Group>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default CardTableTindakanpemohon;
