import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";

// components

import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import HeaderStats from "../components/Headers/HeaderStats.js";
import FooterAdmin from "../components/Footers/FooterAdmin.js";
import { connect } from "react-redux";
import propTypes from "prop-types";
import auth from "../reducers/auth.js";
import HeaderBlank from "../components/Headers/HeaderBlank.js";
import CardDetailObyek from "../components/Cards/Permohonans/CardDetailObyek.js";
// views

const ObyekLayout = ({ auth }) => {
  if (auth && !auth.isAuthenticated && !auth.isLoading) {
    return <Navigate to="/auth/login" />;
  }
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderBlank />
        <div className="px-4 md:px-10 mx-auto w-full -m-36 flex flex-col min-h-screen-75">
          <div className="w-full lg:w-12/12 mt-8 relative flex flex-row flex-wrap">
            <div className="w-full lg:w-1/4 px-4 mt-8 relative">
              <CardDetailObyek />
            </div>
            <div className="w-full lg:w-3/4 px-4 mt-8 relative">
              <Outlet />
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
};
ObyekLayout.propTypes = {
  auth: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ObyekLayout);
