import React, { useEffect, useState } from "react";
import { deletePermohonan, listPermohonan } from "../../../actions/permohonans";
import ReactPaginate from "react-paginate";
import store from "../../../store";
import CommonDropdown from "../../Dropdowns/CommonDropdown";
import DropDownItem from "../../Dropdowns/DropDownItem";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import DatePicker, { registerLocale } from "react-datepicker";
import { id } from "date-fns/locale";
import subMonths from "date-fns/subMonths";

export const CardTablePermohonan = ({
  openModalPermohonan,
  reload,
  getListPermohonanError,
  getListPermohonanLoading,
  getListPermohonanResult,
  deletePermohonanResult,
  listPermohonan,
  deletePermohonan,
}) => {
  const date = new Date();
  const [currentPage, setCurrentPage] = useState(0);
  const color = "light";
  const [order, setOrder] = useState("-id");
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(
    subMonths(new Date(date.getFullYear(), date.getMonth(), 1), 6)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [active, setActive] = useState(true);
  registerLocale("id", id);

  useEffect(() => {
    let params = {
      page: currentPage + 1,
      order,
      search,
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(endDate, "yyyy-MM-dd"),
      active: active ? 1 : 0,
    };
    listPermohonan(params);
  }, [
    currentPage,
    order,
    search,
    reload,
    deletePermohonanResult,
    startDate,
    endDate,
    active,
  ]);

  const deleteConfirmDialog = ({ permohonan }) => {
    Swal.fire({
      title: "Yakin akan menghapus permohonan ?",
      text: "Kamu tidak bisa mengembalikannya!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, hapus saja!",
    }).then((result) => {
      if (result.isConfirmed) {
        deletePermohonan(permohonan.id);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          timer: 2000,
          timerProgressBar: true,
        });
        Toast.fire(
          "Deleted!",
          `${permohonan.nama_pemohon} telah dihapus`,
          "success"
        );
      }
    });
  };
  if (getListPermohonanError) {
    return (
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-3">
          <h1>{getListPermohonanError.message}</h1>
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-3 py-3 border-0">
          <div className="flex flex-wrap items-center justify-between">
            <div className="text-center flex justify-between w-full md:w-1/4">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                Daftar Permohonan
              </h3>
            </div>
            <div className="text-center flex flex-col md:flex-row justify-end w-full md:w-3/4 items-center py-2 px-2 gap-3">
              <div className="flex flex-col md:flex-row justify-between w-full md:w-1/3 items-center gap-3">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  locale={id}
                  dateFormat="MMM yyyy"
                  showMonthYearPicker
                  className="text-sm px-2 py-1 rounded w-full"
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  locale={id}
                  dateFormat="MMM yyyy"
                  showMonthYearPicker
                  className="text-sm px-2 py-1 rounded w-full"
                />
              </div>
              <div className="relative flex flex-wrap flex-col items-stretch w-full md:w-1/4 lg:w-1/6">
                <div className="inline-flex items-center">
                  <label
                    className="relative flex items-center p-3 rounded-full cursor-pointer"
                    htmlFor="check"
                  >
                    <input
                      type="checkbox"
                      className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                      id="check"
                      checked={active}
                      onChange={() => setActive(!active)}
                    />
                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        stroke-width="1"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </label>
                  <label
                    className="mt-px font-light text-gray-700 cursor-pointer select-none"
                    htmlFor="check"
                  >
                    Active
                  </label>
                </div>
              </div>

              <div className="relative flex flex-wrap flex-col items-stretch w-full md:w-1/5">
                <span className="z-10 h-full leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-2 py-1">
                  <i className="fas fa-search"></i>
                </span>
                <input
                  type="text"
                  placeholder="Cari Permohonan"
                  className="px-2 py-1 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline pl-10 w-full"
                  onChange={(e) => {
                    setTimeout(() => {
                      setSearch(e.target.value);
                    }, 500);
                  }}
                />
              </div>
              <button
                className="text-lightBlue-500 bg-transparent border border-solid border-lightBlue-500 hover:bg-lightBlue-500 hover:text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-2 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                onClick={openModalPermohonan}
              >
                <i className="fas fa-circle-plus"></i> New
              </button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {getListPermohonanLoading && (
            <div className="w-full absolute justify-center flex z-30 m-auto flex-col items-center bg-white h-32">
              <BeatLoader color="#36d7b7" size={20} />
            </div>
          )}

          {getListPermohonanResult ? (
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    className={
                      "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    <span>No</span>
                  </th>
                  <th
                    className={
                      "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    <a
                      href="/#"
                      className="flex justify-between"
                      onClick={(e) => {
                        // const pattern = /^\-{0,}?(id)$/g;
                        e.preventDefault();
                        setOrder(
                          `${order === "created" ? "-created" : "created"}`
                        );
                      }}
                    >
                      <span>Dibuat</span>
                      <i
                        className={`fa-solid fa-sort${
                          /^-{0,}?(nama_pemohon)$/g.test(order)
                            ? order === "nama_pemohon"
                              ? "-up"
                              : "-down"
                            : ""
                        }`}
                      ></i>
                    </a>
                  </th>
                  <th
                    className={
                      "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    <a
                      href="/#"
                      className="flex justify-between"
                      onClick={(e) => {
                        // const pattern = /^\-{0,}?(id)$/g;
                        e.preventDefault();
                        setOrder(
                          `${
                            order === "nama_pemohon"
                              ? "-nama_pemohon"
                              : "nama_pemohon"
                          }`
                        );
                      }}
                    >
                      <span>Nama Pemohon</span>
                      <i
                        className={`fa-solid fa-sort${
                          /^-{0,}?(nama_pemohon)$/g.test(order)
                            ? order === "nama_pemohon"
                              ? "-up"
                              : "-down"
                            : ""
                        }`}
                      ></i>
                    </a>
                  </th>
                  <th
                    className={
                      "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    <a
                      href="/#"
                      className="flex justify-between"
                      onClick={(e) => {
                        // const pattern = /^\-{0,}?(id)$/g;
                        e.preventDefault();
                        setOrder(
                          `${
                            order === "identitas" ? "-identitas" : "identitas"
                          }`
                        );
                      }}
                    >
                      <span>Identitas Tanah</span>
                      <i
                        className={`fa-solid fa-sort${
                          /^-{0,}?(identitas)$/g.test(order)
                            ? order === "identitas"
                              ? "-up"
                              : "-down"
                            : ""
                        }`}
                      ></i>
                    </a>
                  </th>
                  <th
                    className={
                      "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    <a
                      href="/#"
                      className="flex justify-between"
                      onClick={(e) => {
                        // const pattern = /^\-{0,}?(id)$/g;
                        e.preventDefault();
                        setOrder(
                          `${
                            order === "jenispermohonans__nama_jenis_permohonan"
                              ? "-jenispermohonans__nama_jenis_permohonan"
                              : "jenispermohonans__nama_jenis_permohonan"
                          }`
                        );
                      }}
                    >
                      <span>Jenis Permohonan</span>
                      <i
                        className={`fa-solid fa-sort${
                          /^-{0,}?(jenispermohonans__nama_jenis_permohonan)$/g.test(
                            order
                          )
                            ? order ===
                              "jenispermohonans__nama_jenis_permohonan"
                              ? "-up"
                              : "-down"
                            : ""
                        }`}
                      ></i>
                    </a>
                  </th>
                  <th
                    className={
                      "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    Menu
                  </th>
                </tr>
              </thead>
              <tbody>
                {getListPermohonanResult.results &&
                  getListPermohonanResult.results.map((permohonan, index) => {
                    return (
                      <tr key={permohonan.id}>
                        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 w-8">
                          {currentPage * 10 + index + 1}.
                        </td>
                        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3">
                          {format(new Date(permohonan.created), "dd MMM yy")}
                        </td>
                        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3">
                          <i className="fas fa-file-archive text-orange-500 mr-2"></i>{" "}
                          {permohonan.nama_pemohon}
                        </td>
                        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3">
                          {permohonan.identitas}
                        </td>
                        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs p-3 flex-row flex-wrap justify-between items-start md:w-80">
                          {permohonan.jenispermohonans &&
                            permohonan.jenispermohonans.map((d, k) => (
                              <a
                                key={k}
                                href={`/admin/permohonans/${permohonan.id}/obyeks/${permohonan.obyek_permohonan[k]}/pemohons`}
                                className="text-lightBlue-500 bg-transparent border border-solid border-lightBlue-500 hover:bg-lightBlue-500 hover:text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                              >
                                {d.nama_jenis_permohonan}
                              </a>
                            ))}
                        </td>
                        <td className="visible md:hidden lg:hidden border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-right">
                          <CommonDropdown>
                            <DropDownItem
                              title="Edit"
                              cmd={(e) =>
                                openModalPermohonan({
                                  selectedPermohonan: permohonan,
                                  mode: "edit",
                                })
                              }
                            />
                            <DropDownItem
                              title="Detail"
                              url={`/admin/permohonans/${permohonan.id}`}
                            />
                            <DropDownItem
                              title="Hapus"
                              cmd={(e) => deleteConfirmDialog({ permohonan })}
                            />
                          </CommonDropdown>
                        </td>
                        <td className="invisible md:visible border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap py-3 text-right flex flex-row justify-around content-center items-center w-20">
                          <Link
                            className="text-lightBlue-500 background-transparent font-bold uppercase py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            to={`/admin/permohonans/${permohonan.id}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-5 h-5"
                            >
                              <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                              <path
                                fillRule="evenodd"
                                d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Link>
                          <button
                            className="text-lightBlue-500 background-transparent font-bold uppercase py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={(e) =>
                              openModalPermohonan({
                                selectedPermohonan: permohonan,
                                mode: "edit",
                              })
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-5 h-5"
                            >
                              <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                              <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                            </svg>
                          </button>
                          <button
                            className="text-lightBlue-500 background-transparent font-bold uppercase py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={(e) => deleteConfirmDialog({ permohonan })}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : null}
        </div>
        {getListPermohonanResult ? (
          <>
            <div className="py-4 px-2 justify-end flex bg-white">
              <nav className="block">
                <ReactPaginate
                  previousLabel={<i className="fas fa-chevron-left -ml-px"></i>}
                  nextLabel={<i className="fas fa-chevron-right -mr-px"></i>}
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={Math.ceil(getListPermohonanResult.count / 10)}
                  pageRangeDisplayed={4}
                  marginPagesDisplayed={2}
                  onPageChange={(e) => {
                    setCurrentPage(e.selected);
                  }}
                  containerClassName="flex pl-0 rounded list-none flex-wrap"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  activeClassName="page-link-active"
                  forcePage={currentPage}
                  onClick={(clickEvent) => {
                    // setCurrentPage(clickEvent.selected + 1);
                  }}
                />
              </nav>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getListPermohonanError: state.permohonans.getListPermohonanError,
  getListPermohonanLoading: state.permohonans.getListPermohonanLoading,
  getListPermohonanResult: state.permohonans.getListPermohonanResult,
  deletePermohonanResult: state.permohonans.deletePermohonanResult,
});

export default connect(mapStateToProps, { listPermohonan, deletePermohonan })(
  CardTablePermohonan
);
