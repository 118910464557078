import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import AlamatSelect from "../../components/Selects/AlamatSelect";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { id, es } from "date-fns/esm/locale";
import { format } from "date-fns";
import { CurrencyInput } from "../../components/Inputs";
import { NumericFormat } from "react-number-format";
import SingleSelectDb from "../../components/Selects/SingleSelectDb";
import MultiSelectDb from "../../components/Selects/MultiSelectDb";
function Daftar() {
  // const [options, setOptions] = useState([""]);
  registerLocale("id", id);
  const [selected, setSelected] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [number, setNumber] = useState(0);
  const [valuesObj, setValuesObj] = useState({});
  const [value, setValue] = useState([
    { value: 173, label: "Asempapan" },
    { value: 48, label: "Bageng" },
  ]);
  // const [value, setValue] = useState([]);

  const alamatRef = React.createRef();
  // const [inputKab, setInputKab] = useState("");
  // useEffect(() => {
  //   const getData = async () => {
  //     const arr = [];
  //     await axios.get(`/api/alamats/kabupatens/?q=${inputKab}`).then((res) => {
  //       let result = res.data;
  //       result.map((kb) => {
  //         return arr.push({ value: kb.id, label: kb.nama_kabupaten });
  //       });
  //       setOptions(arr);
  //     });
  //   };
  //   return () => {
  //     setTimeout(() => {
  //       getData();
  //     }, 1000);
  //   };
  // }, [inputKab]);
  /* isClearable
                /* Select
                  options={options}
                  onInputChange={(e) => {
                    setInputKab(e);
                  }}
                  onChange={(v) => setSelected(v)} */

  return (
    <div className="flex flex-wrap mt-32">
      <div className="relative w-full mb-10 px-4">
        <div className="w-full lg:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-2 shadow-lg rounded-lg bg-blueGray-200 border-0 p-5">
            {/* <div className="flex-auto px-4 lg:px-5 pt-2">
              <AlamatSelect
                namaDesa={selected.value || ""}
                value={selected}
                onChange={(alamat) => setSelected(alamat)}
              />
              <div>{JSON.stringify(selected)}</div>
            </div> */}
            <div className="flex-auto px-4 lg:px-5 pt-2">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                locale={id}
                dateFormat="dd/MM/yyyy"
              />
              <div>{format(startDate, "dd MMMM yyyy", { locale: id })}</div>
            </div>
            <div className="flex-auto px-4 lg:px-5 pt-2">
              <CurrencyInput
                className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                value={number}
                onValueChange={(values, sourceInfo) => {
                  setValuesObj(values);
                }}
              />
              <div>{valuesObj ? valuesObj.value : ""}</div>
            </div>
            <div className="flex-auto px-4 lg:px-5 pt-2">
              {/* <div>{value}</div> */}
              <MultiSelectDb
                url="/api/alamats/list"
                value={value}
                valueField="id"
                labelField="nama_desa"
                innerRef={alamatRef}
                onChange={setValue}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Daftar;
