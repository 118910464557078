import { dispatchError, dispatchLoading, dispatchSuccess } from "../utils";
import axios from "axios";

export const GET_LIST_PEMOHON = "GET_LIST_PEMOHON";
export const TAMBAH_PEMOHON = "TAMBAH_PEMOHON";
export const GET_DETAIL_PEMOHON = "GET_DETAIL_PEMOHON";
export const UPDATE_PEMOHON = "UPDATE_PEMOHON";
export const DELETE_PEMOHON = "DELETE_PEMOHON";
export const OPEN_PEMOHON = "OPEN_PEMOHON";
export const CLOSE_PEMOHON = "CLOSE_PEMOHON";
export const GET_PEMOHON_NIK = "GET_PEMOHON_NIK";
export const CLOSE_PEMOHON_NIK = "CLOSE_PEMOHON_NIK";

export const listPemohon =
  ({ perm_id, page = 1, order = "id", search = "" } = {}) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_PEMOHON);
    axios
      .get(
        `/api/pemohons/permohonans/${perm_id}?page=${page} ${
          order ? "&order_by=" + order : ""
        }${search ? "&nama_pemohon=" + search : ""}`,
        tokenConfig()
      )
      .then((res) => {
        dispatchSuccess(dispatch, GET_LIST_PEMOHON, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_LIST_PEMOHON, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };
export const getDetailPemohon =
  ({ id }) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_DETAIL_PEMOHON);
    axios
      .get(`/api/pemohons/detail/${id}`, tokenConfig())
      .then((res) => {
        dispatchSuccess(dispatch, GET_DETAIL_PEMOHON, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_DETAIL_PEMOHON, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

  export const getPemohonNik =
  ({ nik }) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_PEMOHON_NIK);
    axios
      .get(`/api/pemohons/detail?nik=${nik}`, tokenConfig())
      .then((res) => {
        dispatchSuccess(dispatch, GET_PEMOHON_NIK, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_PEMOHON_NIK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const openPemohon = () => (dispatch) => {
  dispatchLoading(dispatch, OPEN_PEMOHON);
};
export const closePemohon = () => (dispatch) => {
  dispatch({ type: CLOSE_PEMOHON });
};
export const closePemohonNik = () => (dispatch) => {
  dispatch({ type: CLOSE_PEMOHON_NIK });
};

export const addPemohon =
   ({
    nama_pemohon,
    gender,
    status_pernikahan,
    tanggal_lahir,
    nik,
    rt,
    rw,
    npwp,
    alamat,
    pekerjaan,
    permohonan,
    tempatlahir,
  }) =>
   async (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      nama_pemohon,
      gender,
      status_pernikahan,
      tanggal_lahir,
      nik,
      rt,
      rw,
      npwp,
      alamat,
      pekerjaan,
      permohonan,
      tempatlahir,
    });
    dispatchLoading(dispatch, TAMBAH_PEMOHON);
    dispatch({ type: OPEN_PEMOHON });
    await new Promise((resolve)=>setTimeout(() => {
      resolve()
    }, 1000))
    await axios
      .post("/api/pemohons/", body, config)
      .then((res) => {
        dispatchSuccess(dispatch, TAMBAH_PEMOHON, res.data);
        dispatch({ type: CLOSE_PEMOHON });
      })
      .catch((err) => {
        dispatchError(dispatch, TAMBAH_PEMOHON, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_PEMOHON });
      });
  };

export const updatePemohon =
  ({
    id,
    nama_pemohon,
    gender,
    status_pernikahan,
    tanggal_lahir,
    nik,
    rt,
    rw,
    npwp,
    alamat,
    pekerjaan,
    permohonan,
    tempatlahir,
  }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      nama_pemohon,
      gender,
      status_pernikahan,
      tanggal_lahir,
      nik,
      rt,
      rw,
      npwp,
      alamat,
      pekerjaan,
      permohonan,
      tempatlahir,
    });
    dispatchLoading(dispatch, UPDATE_PEMOHON);
    dispatch({ type: OPEN_PEMOHON });
    axios
      .patch(`/api/pemohons/${id}/`, body, config)
      .then((res) => {
        dispatchSuccess(dispatch, UPDATE_PEMOHON, res.data);
        dispatch({ type: CLOSE_PEMOHON });
      })
      .catch((err) => {
        dispatchError(dispatch, UPDATE_PEMOHON, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_PEMOHON });
      });
  };

export const deletePemohon = (id) => (dispatch) => {
  dispatchLoading(dispatch, DELETE_PEMOHON);
  axios
    .delete(`/api/pemohons/${id}/`, tokenConfig())
    .then((res) => {
      dispatchSuccess(dispatch, DELETE_PEMOHON, "DATA BERHASIL DIHAPUS");
    })
    .catch((err) =>
      dispatchError(dispatch, DELETE_PEMOHON, {
        msg: err.response.data,
        status: err.response.status,
      })
    );
};

export const tokenConfig = () => {
  // Get token from state

  // Headers
  const config = {
    // baseURL: "http://127.0.0.1:8000",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  // if (token) {
  //   config.headers["Authorization"] = `Token ${token}`;
  // }

  return config;
};
