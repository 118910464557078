import { dispatchError, dispatchLoading, dispatchSuccess } from "../utils";
import axios from "axios";

export const GET_LIST_PEJABAT = "GET_LIST_PEJABAT";
export const TAMBAH_PEJABAT = "TAMBAH_PEJABAT";
export const GET_DETAIL_PEJABAT = "GET_DETAIL_PEJABAT";
export const UPDATE_PEJABAT = "UPDATE_PEJABAT";
export const DELETE_PEJABAT = "DELETE_PEJABAT";
export const OPEN_PEJABAT = "OPEN_PEJABAT";
export const CLOSE_PEJABAT = "CLOSE_PEJABAT";

export const getDetailPejabat = () => (dispatch) => {
  dispatchLoading(dispatch, GET_DETAIL_PEJABAT);
  axios
    .get(`/api/pejabats/detail`, tokenConfig())
    .then((res) => {
      dispatchSuccess(dispatch, GET_DETAIL_PEJABAT, res.data);
    })
    .catch((err) => {
      dispatchError(dispatch, GET_DETAIL_PEJABAT, {
        msg: err.response.data,
        status: err.response.status,
      });
    });
};

export const openPejabat = () => (dispatch) => {
  dispatchLoading(dispatch, OPEN_PEJABAT);
};
export const closePejabat = () => (dispatch) => {
  dispatch({ type: CLOSE_PEJABAT });
};

export const addPejabat =
  ({
    nama_notaris,
    gelar_depan,
    gelar_belakang,
    gelar_depan_pendek,
    gelar_belakang_pendek,
    gender,
    status_pernikahan,
    tempatlahir,
    tanggal_lahir,
    nik,
    npwp,
    alamat,
    alamat_kantor,
    telp,
    email,
    user,
    nomor_sk_notaris,
    nomor_sk_ppat,
    tanggal_sk_notaris,
    tanggal_sk_ppat,
    handphone,
    kode_pos,
    kedudukan,
  }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      nama_notaris,
      gelar_depan,
      gelar_belakang,
      gelar_depan_pendek,
      gelar_belakang_pendek,
      gender,
      status_pernikahan,
      tempatlahir,
      tanggal_lahir,
      nik,
      npwp,
      alamat,
      alamat_kantor,
      telp,
      email,
      user,
      nomor_sk_notaris,
      nomor_sk_ppat,
      tanggal_sk_notaris,
      tanggal_sk_ppat,
      handphone,
      kode_pos,
      kedudukan,
    });
    dispatchLoading(dispatch, TAMBAH_PEJABAT);
    dispatch({ type: OPEN_PEJABAT });
    axios
      .post("/api/pejabats/", body, config)
      .then((res) => {
        dispatchSuccess(dispatch, TAMBAH_PEJABAT, res.data);
        dispatch({ type: CLOSE_PEJABAT });
      })
      .catch((err) => {
        dispatchError(dispatch, TAMBAH_PEJABAT, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_PEJABAT });
      });
  };

export const updatePejabat =
  ({
    id,
    nama_notaris,
    gelar_depan,
    gelar_belakang,
    gelar_depan_pendek,
    gelar_belakang_pendek,
    gender,
    status_pernikahan,
    tempatlahir,
    tanggal_lahir,
    nik,
    npwp,
    alamat,
    alamat_kantor,
    telp,
    email,
    user,
    nomor_sk_notaris,
    nomor_sk_ppat,
    tanggal_sk_notaris,
    tanggal_sk_ppat,
    handphone,
    kode_pos,
    kedudukan,
  }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      nama_notaris,
      gelar_depan,
      gelar_belakang,
      gelar_depan_pendek,
      gelar_belakang_pendek,
      gender,
      status_pernikahan,
      tempatlahir,
      tanggal_lahir,
      nik,
      npwp,
      alamat,
      alamat_kantor,
      telp,
      email,
      user,
      nomor_sk_notaris,
      nomor_sk_ppat,
      tanggal_sk_notaris,
      tanggal_sk_ppat,
      handphone,
      kode_pos,
      kedudukan,
    });
    dispatchLoading(dispatch, UPDATE_PEJABAT);
    dispatch({ type: OPEN_PEJABAT });
    axios
      .patch(`/api/pejabats/${id}/`, body, config)
      .then((res) => {
        dispatchSuccess(dispatch, UPDATE_PEJABAT, res.data);
        dispatch({ type: CLOSE_PEJABAT });
      })
      .catch((err) => {
        dispatchError(dispatch, UPDATE_PEJABAT, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_PEJABAT });
      });
  };

export const deletePejabat = (id) => (dispatch) => {
  dispatchLoading(dispatch, DELETE_PEJABAT);
  axios
    .delete(`/api/pejabats/${id}/`, tokenConfig())
    .then((res) => {
      dispatchSuccess(dispatch, DELETE_PEJABAT, "DATA BERHASIL DIHAPUS");
    })
    .catch((err) =>
      dispatchError(dispatch, DELETE_PEJABAT, {
        msg: err.response.data,
        status: err.response.status,
      })
    );
};

export const tokenConfig = () => {
  // Get token from state

  // Headers
  const config = {
    // baseURL: "http://127.0.0.1:8000",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  // if (token) {
  //   config.headers["Authorization"] = `Token ${token}`;
  // }

  return config;
};
