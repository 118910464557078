import { motion } from "framer-motion";
import { dropIn } from "../Animations";
import { useEffect, useRef, useState } from "react";
import Backdrop from "../Backdrop";
import { connect } from "react-redux";
import { addSuratobyek, updateSuratobyek } from "../../../actions/suratobyeks";
import { ErrorAlert } from "../../Alerts/ErrorAlert";
import DatePicker, { registerLocale } from "react-datepicker";
import { id as indo } from "date-fns/locale";
import { format } from "date-fns";
import MultiSelectDbMultiLabel from "../../Selects/MultiSelectDbMultiLabel";

const UpdateSuratobyekModal = ({
  handleClose,
  addSuratobyek,
  errors,
  suratobyek,
  mode = "add",
  updateSuratobyek,
}) => {
  const firstInput = useRef();
  registerLocale("id", indo);

  const [formData, setFormData] = useState({
    id: null,
    urutan: null,
    obyek: null,
    surat: null,
    nomor_surat: "",
    tanggal_surat: new Date(),
    saksis: [],
    stafs:[]
  });

  const [customFields, setCustomFields] = useState([]);
  const { nomor_surat, tanggal_surat, saksis, stafs } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };
  const onCustomFieldChange = (e, id) => {
    setCustomFields(
      customFields.map((cfield) => {
        if (cfield.id === id) {
          // Create a *new* object with changes
          return { ...cfield, value_field: e.target.value };
        } else {
          // No changes
          return cfield;
        }
      })
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setRefresh(!refresh);
    const newSuratobyek = { ...formData };
    newSuratobyek.tanggal_surat = format(tanggal_surat, "yyyy-MM-dd");
    // updateSuratobyek(newSuratobyek);
    // const customfields = customFields.map((cf, i) => ({
    //   id: cf.id,
    //   value_field: cf.value_field,
    // }));
    newSuratobyek.customfields = customFields;
    updateSuratobyek(newSuratobyek);
  };

  const [refresh, setRefresh] = useState(false);
  const [isSaksi, setIsSaksi] = useState(false);
  const [isStaf, setIsStaf] = useState(false);
  useEffect(() => {
    setRefresh(true);
    return () => setRefresh(false);
  }, [errors]);

  useEffect(() => {
    if (suratobyek && suratobyek.surat) {
      if (suratobyek.surat.saksi === 1) {
        setIsSaksi(true);
      } else {
        setIsSaksi(false);
      }
      if (suratobyek.surat.staf === 1) {
        setIsStaf(true);
      } else {
        setIsStaf(false);
      }
      if (
        suratobyek.surat.customfields &&
        suratobyek.surat.customfields.length > 0
      ) {
        const newCustomField = [...suratobyek.surat.customfields];
        if (suratobyek.suratobyekfield_suratobyek.length > 0) {
          for (
            let index = 0;
            index < suratobyek.suratobyekfield_suratobyek.length;
            index++
          ) {
            const element = suratobyek.suratobyekfield_suratobyek[index];
            if (index < newCustomField.length) {
              newCustomField[index].value_field = element.value_field;
            }
          }
        }
        setCustomFields(newCustomField);
      }
      const newSuratobyek = { ...suratobyek };
      newSuratobyek.nomor_surat =
        newSuratobyek.nomor_surat != null ? newSuratobyek.nomor_surat : "";
      newSuratobyek.tanggal_surat = new Date(newSuratobyek.tanggal_surat);
      if (suratobyek.surat.saksi === 1) {
        const newSaksis = [...suratobyek.saksis];
        const asaksis = newSaksis.map((v, i) => {
          return {
            value: v.id,
            label: `${v.nama_saksi} - ${v.jabatan} - ${v.grupsaksi}`,
          };
        });
        newSuratobyek.saksis = asaksis;
      }
      if (suratobyek.surat.staf === 1) {
        const newStafs = [...suratobyek.stafs];
        const astafs = newStafs.map((v, i) => {
          return {
            value: v.id,
            label: `${v.nama_staf}`,
          };
        });
        newSuratobyek.stafs = astafs;
      }
      setFormData(newSuratobyek);
    }
    firstInput.current.focus();
  }, [suratobyek]);

  return (
    <Backdrop>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="border-0 rounded-lg shadow-lg absolute flex flex-col w-11/12 md:w-3/4 lg:w-1/2  outline-none focus:outline-none shadow-slate-700"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="flex items-start justify-between p-3 border-b border-solid bg-blueGray-100 rounded-t">
          <h3 className="text-xl font-semibold text-blueGray-400 uppercase">
            Form {formData.surat ? formData.surat.nama_surat : null}
          </h3>
          <button
            className="p-1 ml-auto bg-transparent border-0 text-blueGray-400 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            onClick={handleClose}
          >
            <span className="bg-transparent text-blueGray-500 h-6 w-6 text-xl block outline-none focus:outline-none">
              ×
            </span>
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className="relative p-2 flex-auto bg-blueGray-200">
            <div className="flex-auto px-2 lg:px-5 py-5 pt-0">
              {errors &&
              errors.msg &&
              errors.status &&
              errors.status === 400 &&
              refresh ? (
                <ErrorAlert errors={Object.entries(errors.msg)} />
              ) : null}

              <div className="w-full lg:w-12/12 px-2">
                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Nomor Surat
                    </label>
                    <input
                      type="text"
                      ref={firstInput}
                      className="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-lg focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      name="nomor_surat"
                      value={nomor_surat}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Tanggal Surat
                    </label>
                    <DatePicker
                      selected={tanggal_surat}
                      value={tanggal_surat}
                      onChange={(date) => onSelectChange("tanggal_surat", date)}
                      className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      locale={indo}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>
                {isSaksi ? (
                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Saksi
                      </label>
                      <MultiSelectDbMultiLabel
                        url="/api/saksis/list"
                        valueField="id"
                        labelField={["nama_saksi", "jabatan", "grupsaksi"]}
                        value={saksis}
                        onChange={(e) => onSelectChange("saksis", e)}
                      />
                    </div>
                  </div>
                ) : null}
                {isStaf ? (
                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Staf
                      </label>
                      <MultiSelectDbMultiLabel
                        url="/api/stafs/list"
                        valueField="id"
                        labelField={["nama_staf"]}
                        value={stafs}
                        onChange={(e) => onSelectChange("stafs", e)}
                      />
                    </div>
                  </div>
                ) : null}

              </div>
              {customFields ? (
                <div className="flex flex-wrap px-4">
                  {customFields.map((f, i) => (
                    <div className="w-full md:w-1/2 px-2" key={i}>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {f.judul_field}
                        </label>
                        {f.tipe_field === "Textarea" ? (
                          <textarea
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            rows="4"
                            name="value_field"
                            value={f.value_field}
                            onChange={(e) => onCustomFieldChange(e, f.id)}
                          ></textarea>
                        ) : (
                          <input
                            type="text"
                            className="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-lg focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            name="value_field"
                            value={f.value_field}
                            onChange={(e) => onCustomFieldChange(e, f.id)}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b bg-blueGray-100">
            <button
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded-2xl shadow-lg shadow-slate-500 hover:shadow-slate-800 outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded-2xl shadow-lg shadow-slate-500 hover:shadow-slate-800 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="submit"
            >
              Cetak
            </button>
          </div>
        </form>
      </motion.div>
    </Backdrop>
  );
};

const mapStateToProps = (state) => ({
  errors: state.suratobyeks.tambahSuratobyekError
    ? state.suratobyeks.tambahSuratobyekError
    : state.suratobyeks.updateSuratobyekError,
  isOpenSuratobyek: state.suratobyeks.isOpenSuratobyek,
});

export default connect(mapStateToProps, {
  addSuratobyek,
  updateSuratobyek,
})(UpdateSuratobyekModal);
