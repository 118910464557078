import React, { useEffect, useRef } from "react";

// imports related to DND
import { Droppable, Draggable } from "@hello-pangea/dnd";

export function ListComponents({ Marvel, DC, filterList }) {
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "drakgray" : "transparent",
    // margin: "auto",
  });
  const inputSearch = useRef();
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    background: isDragging ? "darkgrey" : "white",
    color: isDragging ? "white" : "black",
    // padding: isDragging ? "0%" : "2%",
    // paddingLeft: "2%",
    // margin: "0%",
    // fontSize: "12px",
    // borderBottom: "0.5px solid gray",
    ...draggableStyle,
  });
  useEffect(() => {
    inputSearch.current.focus();
  }, []);

  return (
    <div className="w-full p-4 flex flex-row justify-between items-start h-full overflow-y-auto">
      <Droppable droppableId="Marvel_drop_area">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            className="w-1/2"
          >
            <div className="py-2 px-2 text-lg items-start flex justify-between bg-lightBlue-500 text-lightBlue-50 rounded-t-lg shadow-lg shadow-slate-600">
              <h4>Daftar Surat</h4>
              <div className="relative flex w-full md:w-7/12 flex-wrap items-stretch justify-between bg-slate-500">
                <span className="z-10 h-full leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1">
                  <i className="fas fa-search"></i>
                </span>
                <div className="flex justify-end w-full">
                  <input
                    type="text"
                    placeholder="Cari Surat"
                    ref={inputSearch}
                    className="py-1 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full pl-8"
                    onChange={(e) =>
                      setTimeout(filterList(e.target.value), 5000)
                    }
                  />
                  {inputSearch &&
                  inputSearch.current &&
                  inputSearch.current.value.length > 0 ? (
                    <button
                      className="text-blueGray-500 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mb-1 ease-linear transition-all duration-150 absolute"
                      type="button"
                      onClick={(e) => {
                        inputSearch.current.value = "";
                        setTimeout(filterList(""), 5000);
                      }}
                    >
                      <i className="fas fa-close"></i>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>

            <ul
              style={{
                listStyleType: "none",
              }}
              className="bg-white rounded-lg border border-gray-200 text-gray-900 shadow-lg shadow-slate-600"
            >
              {provided.placeholder}
              {Marvel.map((data, index) => (
                <Draggable
                  key={data.id}
                  draggableId={`${data.id}${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <li
                      key={data.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      className="px-6 py-2 border-b border-gray-200 w-full"
                    >
                      {data.nama_surat}
                    </li>
                  )}
                </Draggable>
              ))}
            </ul>
          </div>
        )}
      </Droppable>
      <div className="flex flex-col justify-center items-center p-4 mt-2 mx-4">
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M17 8l4 4m0 0l-4 4m4-4H3"
          />
        </svg>
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
      </div>
      <Droppable droppableId="DC_drop_area">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            className="w-1/2"
          >
            <h6 className="py-2 items-center flex justify-center text-xl bg-lightBlue-500 text-lightBlue-50 rounded-t-lg shadow-lg shadow-slate-600">
              Daftar Surat Permohonan
            </h6>
            <ul
              style={{
                listStyleType: "none",
              }}
              className="bg-white rounded-lg border border-gray-200 text-gray-900 shadow-lg shadow-slate-600"
            >
              {provided.placeholder}
              {DC.map((data, index) => (
                <Draggable
                  key={data.id}
                  draggableId={`${data.id}${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <li
                      key={data.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      className="px-6 py-2 border-b border-gray-200 w-full"
                    >
                      {data.nama_surat}
                    </li>
                  )}
                </Draggable>
              ))}
            </ul>
          </div>
        )}
      </Droppable>
    </div>
  );
}
