export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const displayTextLimit = (str, length) => {
  const trimmed =
    str.length > length
      ? str.substring(0, length) + "..."
      : str.substring(0, length);
  return trimmed;
};
