import {
  GET_LIST_PERMOHONAN,
  TAMBAH_PERMOHONAN,
  UPDATE_PERMOHONAN,
  DELETE_PERMOHONAN,
  CLOSE_PERMOHONAN,
  OPEN_PERMOHONAN,
  GET_DETAIL_PERMOHONAN,
} from "../actions/permohonans";

const initialState = {
  getListPermohonanLoading: false,
  getListPermohonanResult: false,
  getListPermohonanError: false,

  tambahPermohonanLoading: false,
  tambahPermohonanResult: false,
  tambahPermohonanError: false,

  getDetailPermohonanLoading: false,
  getDetailPermohonanResult: false,
  getDetailPermohonanError: false,

  updatePermohonanLoading: false,
  updatePermohonanResult: false,
  updatePermohonanError: false,

  deletePermohonanLoading: false,
  deletePermohonanResult: false,
  deletePermohonanError: false,

  isOpenPermohonan: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_PERMOHONAN:
      return {
        ...state,
        getListPermohonanLoading: action.payload.loading,
        getListPermohonanResult: action.payload.data,
        getListPermohonanError: action.payload.errorMessage,
      };
    case GET_DETAIL_PERMOHONAN:
      return {
        ...state,
        getDetailPermohonanLoading: action.payload.loading,
        getDetailPermohonanResult: action.payload.data,
        getDetailPermohonanError: action.payload.errorMessage,
      };

    case TAMBAH_PERMOHONAN:
      return {
        ...state,
        tambahPermohonanLoading: action.payload.loading,
        tambahPermohonanResult: action.payload.data,
        tambahPermohonanError: action.payload.errorMessage,
      };

    case UPDATE_PERMOHONAN:
      return {
        ...state,
        updatePermohonanLoading: action.payload.loading,
        updatePermohonanResult: action.payload.data,
        updatePermohonanError: action.payload.errorMessage,
      };

    case DELETE_PERMOHONAN:
      return {
        ...state,
        deletePermohonanLoading: action.payload.loading,
        deletePermohonanResult: action.payload.data,
        deletePermohonanError: action.payload.errorMessage,
      };
    case OPEN_PERMOHONAN:
      return {
        ...state,
        isOpenPermohonan: true,
      };
    case CLOSE_PERMOHONAN:
      return {
        ...state,
        isOpenPermohonan: false,
        updatePermohonanError: false,
        tambahPermohonanError: false,
      };
    default:
      return state;
  }
}
