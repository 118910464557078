import { AnimatePresence, motion } from "framer-motion";
import React, { Component } from "react";
import { connect } from "react-redux";
import CardTablePermohonan from "../../../components/Cards/Permohonans/CardTablePermohonan";
import { openPermohonan, closePermohonan } from "../../../actions/permohonans";
import AddPermohonanModal from "../../../components/Modals/Permohonan/AddPermohonanModal";

export class Permohonan extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedPermohonan: {}, mode: "add", reload: false };
    // this.state = { mode: "add", reload: false };
    this.closeModalPermohonan = this.closeModalPermohonan.bind(this);
    this.openModalPermohonan = this.openModalPermohonan.bind(this);
  }
  closeModalPermohonan = () => {
    this.props.closePermohonan();
    this.setState({ reload: !this.state.reload });
  };

  openModalPermohonan = ({ mode = "add", selectedPermohonan = {} } = {}) => {
    // if (selectedPermohonan && selectedPermohonan.jenispermohonans) {
    //   let njp = [];
    //   for (
    //     let index = 0;
    //     index < selectedPermohonan.jenispermohonans.length;
    //     index++
    //   ) {
    //   const jp = selectedPermohonan.jenispermohonans[index];
    //   njp.push({
    //     value: jp.jenispermohonan_id,
    //     label: jp.nama_jenis_permohonan,
    //   });
    // }
    // selectedPermohonan.jenispermohonans = njp;
    // }
    this.setState({ selectedPermohonan, mode });
    this.props.openPermohonan();
  };

  render() {
    const { mode, selectedPermohonan } = this.state;
    return (
      <motion.div
        className="flex flex-wrap"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 1.5 }}
        transition={{ duration: 0.3 }}
      >
        <div className="w-full lg:w-full px-4">
          <CardTablePermohonan
            openModalPermohonan={this.openModalPermohonan}
            reload={this.state.reload}
          />
        </div>
        <AnimatePresence
          initial={false}
          mode="wait"
          onExitComplete={() => null}
        >
          {this.props.isOpenPermohonan && (
            <AddPermohonanModal
              handleClose={this.closeModalPermohonan}
              mode={mode}
              permohonan={selectedPermohonan}
            />
          )}
        </AnimatePresence>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permohonans: state.permohonans,
    isOpenPermohonan: state.permohonans.isOpenPermohonan,
  };
};

const mapDispatchToProps = { openPermohonan, closePermohonan };

export default connect(mapStateToProps, mapDispatchToProps)(Permohonan);
