import React from "react";
import { Outlet } from "react-router-dom";
import Staf from "../../layouts/Staf";

function StafRoute() {
  return (
    <Staf>
      <Outlet />
    </Staf>
  );
}

// StafRoute.propTypes = {
//   auth: PropTypes.object.isRequired,
//   y,
// };

export default StafRoute;
