import React, { useEffect, useState } from "react";
import { listAlamat } from "../../../actions/alamats";
import ReactPaginate from "react-paginate";
import store from "../../../store";
import CommonDropdown from "../../Dropdowns/CommonDropdown";
import DropDownItem from "../../Dropdowns/DropDownItem";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const CardTableAlamat = ({ reload = false }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const color = "light";
  const [order, setOrder] = useState("id");
  const [search, setSearch] = useState("");
  useEffect(() => {
    let params = { page: currentPage + 1, order, search };
    store.dispatch(listAlamat(params));
  }, [currentPage, order, search, reload]);

  const { getListAlamatError, getListAlamatLoading, getListAlamatResult } =
    store.getState().alamats;

  const deleteConfirmDialog = ({ alamat }) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", `${alamat.nama_desa} telah dihapus`, "success");
      }
    });
  };
  if (getListAlamatError) {
    return (
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <h1>{getListAlamatError.message}</h1>
        </div>
      </div>
    );
  }
  return (
    <div
      className={
        "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
        (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
      }
    >
      <div className="rounded-t mb-0 px-4 py-3 border-0">
        <div className="flex flex-wrap items-center ">
          <div className="text-center flex justify-between w-full">
            <h3
              className={
                "font-semibold text-lg " +
                (color === "light" ? "text-blueGray-700" : "text-white")
              }
            >
              Daftar Wilayah
            </h3>
            <div className="relative flex flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-2 py-1">
                <i className="fas fa-search"></i>
              </span>
              <input
                type="text"
                placeholder="Cari Alamat"
                className="px-2 py-1 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full pl-10"
                onChange={(e) => {
                  setTimeout(() => {
                    setSearch(e.target.value);
                  }, 500);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="block w-full overflow-x-auto">
        {getListAlamatResult ? (
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  <a
                    href="/#"
                    className="flex justify-between"
                    onClick={(e) => {
                      // const pattern = /^\-{0,}?(id)$/g;
                      e.preventDefault();
                      setOrder(`${order === "id" ? "-id" : "id"}`);
                    }}
                  >
                    <span>Id</span>
                    <i
                      className={`fa-solid fa-sort${
                        /^-{0,}?(id)$/g.test(order)
                          ? order === "id"
                            ? "-up"
                            : "-down"
                          : ""
                      }`}
                    ></i>
                  </a>
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  <a
                    href="/#"
                    className="flex justify-between"
                    onClick={(e) => {
                      // const pattern = /^\-{0,}?(id)$/g;
                      e.preventDefault();
                      setOrder(
                        `${order === "nama_desa" ? "-nama_desa" : "nama_desa"}`
                      );
                    }}
                  >
                    <span>Nama Desa</span>
                    <i
                      className={`fa-solid fa-sort${
                        /^-{0,}?(nama_desa)$/g.test(order)
                          ? order === "nama_desa"
                            ? "-up"
                            : "-down"
                          : ""
                      }`}
                    ></i>
                  </a>
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  <a
                    href="/#"
                    className="flex justify-between"
                    onClick={(e) => {
                      // const pattern = /^\-{0,}?(id)$/g;
                      e.preventDefault();
                      setOrder(
                        `${
                          order === "kecamatan__nama_kecamatan"
                            ? "-kecamatan__nama_kecamatan"
                            : "kecamatan__nama_kecamatan"
                        }`
                      );
                    }}
                  >
                    <span>Kecamatan</span>
                    <i
                      className={`fa-solid fa-sort${
                        /^-{0,}?(kecamatan)$/g.test(order)
                          ? order === "kecamatan"
                            ? "-up"
                            : "-down"
                          : ""
                      }`}
                    ></i>
                  </a>
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Kabupaten
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Provinsi
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Menu
                </th>
              </tr>
            </thead>
            <tbody>
              {getListAlamatLoading && (
                <div className="w-full bg-red-400">
                  <h1>Loading....</h1>;
                </div>
              )}
              {getListAlamatResult.results &&
                getListAlamatResult.results.map((alamat, index) => {
                  return (
                    <tr key={alamat.id}>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {alamat.id}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <i className="fas fa-circle text-orange-500 mr-2"></i>{" "}
                        {alamat.nama_desa}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {alamat.nama_kecamatan}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {alamat.nama_kabupaten}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {alamat.nama_propinsi}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                        <CommonDropdown>
                          <DropDownItem title="Menu 1" url="/auth/login" />
                          <DropDownItem
                            title="Hapus"
                            cmd={(e) => deleteConfirmDialog({ alamat })}
                          />
                        </CommonDropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : null}
      </div>
      {getListAlamatResult ? (
        <>
          <div className="py-4 px-2 justify-end flex bg-slate-100">
            <nav className="block">
              <ReactPaginate
                previousLabel={<i className="fas fa-chevron-left -ml-px"></i>}
                nextLabel={<i className="fas fa-chevron-right -mr-px"></i>}
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={Math.ceil(getListAlamatResult.count / 10)}
                pageRangeDisplayed={4}
                marginPagesDisplayed={2}
                onPageChange={(e) => {
                  setCurrentPage(e.selected);
                }}
                containerClassName="flex pl-0 rounded list-none flex-wrap"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                activeClassName="page-link-active"
                forcePage={currentPage}
                onClick={(clickEvent) => {
                  // setCurrentPage(clickEvent.selected + 1);
                }}
              />
            </nav>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default CardTableAlamat;
