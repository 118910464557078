import { dispatchError, dispatchLoading, dispatchSuccess } from "../utils";
import axios from "axios";
import { returnErrors } from "./messages";

export const GET_LIST_ALAMAT = "GET_LIST_ALAMAT";
export const TAMBAH_ALAMAT = "TAMBAH_ALAMAT";
export const GET_DETAIL_ALAMAT = "GET_DETAIL_ALAMAT";
export const UPDATE_ALAMAT = "UPDATE_ALAMAT";
export const DELETE_ALAMAT = "DELETE_ALAMAT";

export const listAlamat =
  ({ page = 1, order = "id", search = "" } = {}) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_ALAMAT);

    axios
      .get(
        `/api/alamats/?page=${page} ${order ? "&order_by=" + order : ""}${
          search ? "&nama_desa=" + search : ""
        }`,
        tokenConfig()
      )
      .then((res) => {
        dispatchSuccess(dispatch, GET_LIST_ALAMAT, res.data);
      })
      .catch((err) => {
        dispatchError(
          dispatch,
          GET_LIST_ALAMAT,
          returnErrors(err.response.data, err.response.status)
        );
      });
  };

export const tokenConfig = () => {
  // Get token from state

  // Headers
  const config = {
    // baseURL: "http://127.0.0.1:8000",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  // if (token) {
  //   config.headers["Authorization"] = `Token ${token}`;
  // }

  return config;
};
