import {
  GET_LIST_TINDAKANPEMOHON,
  TAMBAH_TINDAKANPEMOHON,
  UPDATE_TINDAKANPEMOHON,
  DELETE_TINDAKANPEMOHON,
  CLOSE_TINDAKANPEMOHON,
  OPEN_TINDAKANPEMOHON,
  GET_DETAIL_TINDAKANPEMOHON,
  CLOSE_ORDER_TINDAKANPEMOHON,
  OPEN_ORDER_TINDAKANPEMOHON,
} from "../actions/tindakanpemohons";

const initialState = {
  getListTindakanpemohonLoading: false,
  getListTindakanpemohonResult: false,
  getListTindakanpemohonError: false,

  tambahTindakanpemohonLoading: false,
  tambahTindakanpemohonResult: false,
  tambahTindakanpemohonError: false,

  getDetailTindakanpemohonLoading: false,
  getDetailTindakanpemohonResult: false,
  getDetailTindakanpemohonError: false,

  updateTindakanpemohonLoading: false,
  updateTindakanpemohonResult: false,
  updateTindakanpemohonError: false,

  deleteTindakanpemohonLoading: false,
  deleteTindakanpemohonResult: false,
  deleteTindakanpemohonError: false,

  isOpenTindakanpemohon: false,
  isOpenOrderTindakanpemohon: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_TINDAKANPEMOHON:
      return {
        ...state,
        getListTindakanpemohonLoading: action.payload.loading,
        getListTindakanpemohonResult: action.payload.data,
        getListTindakanpemohonError: action.payload.errorMessage,
      };
    case GET_DETAIL_TINDAKANPEMOHON:
      return {
        ...state,
        getDetailTindakanpemohonLoading: action.payload.loading,
        getDetailTindakanpemohonResult: action.payload.data,
        getDetailTindakanpemohonError: action.payload.errorMessage,
      };

    case TAMBAH_TINDAKANPEMOHON:
      return {
        ...state,
        tambahTindakanpemohonLoading: action.payload.loading,
        tambahTindakanpemohonResult: action.payload.data,
        tambahTindakanpemohonError: action.payload.errorMessage,
      };

    case UPDATE_TINDAKANPEMOHON:
      return {
        ...state,
        updateTindakanpemohonLoading: action.payload.loading,
        updateTindakanpemohonResult: action.payload.data,
        updateTindakanpemohonError: action.payload.errorMessage,
      };

    case DELETE_TINDAKANPEMOHON:
      return {
        ...state,
        deleteTindakanpemohonLoading: action.payload.loading,
        deleteTindakanpemohonResult: action.payload.data,
        deleteTindakanpemohonError: action.payload.errorMessage,
      };
    case OPEN_TINDAKANPEMOHON:
      return {
        ...state,
        isOpenTindakanpemohon: true,
      };
    case CLOSE_TINDAKANPEMOHON:
      return {
        ...state,
        isOpenTindakanpemohon: false,
        updateTindakanpemohonError: false,
        tambahTindakanpemohonError: false,
      };
    case OPEN_ORDER_TINDAKANPEMOHON:
      return {
        ...state,
        isOpenOrderTindakanpemohon: true,
      };
    case CLOSE_ORDER_TINDAKANPEMOHON:
      return {
        ...state,
        isOpenOrderTindakanpemohon: false,
      };
    default:
      return state;
  }
}
