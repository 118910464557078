import { motion } from "framer-motion";
import { dropIn } from "../Animations";
import { useEffect, useRef, useState } from "react";
import Backdrop from "../Backdrop";
import { connect } from "react-redux";
import {
  addPemohonobyek,
  updatePemohonobyek,
} from "../../../actions/pemohonobyeks";
import { ErrorAlert } from "../../Alerts/ErrorAlert";
import SingleSelectDb from "../../Selects/SingleSelectDb";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import MultiSelectDb from "../../Selects/MultiSelectDb";
import Switch from "react-switch";
import BeatLoader from "react-spinners/BeatLoader";

const AddPemohonobyekModal = ({
  handleClose,
  addPemohonobyek,
  errors,
  pemohonobyek,
  mode = "add",
  updatePemohonobyek,
  permohonan,
  loading
}) => {
  const firstInput = useRef();
  registerLocale("id", id);

  const JENIS_PEMOHON_OPTIONS = [
    { value: "Pelepas", label: "Pelepas" },
    { value: "Penerima", label: "Penerima" },
    { value: "Persetujuan", label: "Persetujuan" },
  ];
  const [formData, setFormData] = useState({
    pemohon: {},
    jenis_pemohon: JENIS_PEMOHON_OPTIONS[0],
    obyek: {},
    tindakanpemohons: [],
    formatsurat: {},
    kuasa: false,
    sendiri: true,
    aktif: true,
  });

  const {
    pemohon,
    jenis_pemohon,
    obyek,
    formatsurat,
    tindakanpemohons,
    aktif,
    kuasa,
    sendiri,
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setRefresh(!refresh);
    if (mode === "add") {
      const newPemohonobyek = {
        jenis_pemohon: jenis_pemohon.value ? jenis_pemohon.value : null,
        pemohon: pemohon.value ? pemohon.value : null,
        obyek: pemohonobyek.obyek,
        formatsurat: formatsurat.value ? formatsurat.value : null,
        tindakanpemohons,
        kuasa,
        sendiri,
        aktif,
      };
      addPemohonobyek(newPemohonobyek);
    } else {
      const newPemohonobyek = {
        id: pemohonobyek.id,
        jenis_pemohon: jenis_pemohon.value ? jenis_pemohon.value : null,
        pemohon: pemohon.value ? pemohon.value : null,
        obyek: pemohonobyek.obyek,
        formatsurat: formatsurat.value ? formatsurat.value : null,
        tindakanpemohons,
        kuasa,
        sendiri,
        aktif,
      };
      updatePemohonobyek(newPemohonobyek);
    }
  };

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setRefresh(true);
    return () => setRefresh(false);
  }, [errors]);

  useEffect(() => {
    // console.log(pemohonobyek);
    if (pemohonobyek && pemohonobyek.pemohon) {
      let newPemohon = { ...pemohonobyek };

      const npmh = {
        value: newPemohon.pemohon,
        label: `${newPemohon.nama_pemohon} - ${newPemohon.nik}`,
      };
      newPemohon.pemohon = npmh;
      const gd = newPemohon.jenis_pemohon;
      const ngd = {
        value: gd,
        label: gd,
      };
      newPemohon.jenis_pemohon = ngd;
      const nfs = {
        value: newPemohon.formatsurat,
        label: newPemohon.caption,
      };
      newPemohon.formatsurat = nfs;

      newPemohon.kuasa = newPemohon.kuasa === 1 ? true : false;
      newPemohon.sendiri = newPemohon.sendiri === 1 ? true : false;
      newPemohon.aktif = newPemohon.aktif === 1 ? true : false;
      const atindakanpemohons = newPemohon.tindakanpemohon_pemohonobyek.map(
        (v, i) => {
          return { value: v.tindakan.id, label: v.tindakan.judul };
        }
      );
      newPemohon.tindakanpemohons = atindakanpemohons;
      setFormData(newPemohon);
    } else {
    }
    firstInput.current.focus();
  }, [pemohonobyek]);

  return (
    <Backdrop>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="border-0 rounded-lg shadow-lg absolute flex flex-col w-1/2 outline-none focus:outline-none shadow-slate-700"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="flex items-start justify-between p-3 border-b border-solid bg-blueGray-100 rounded-t">
          <h3 className="text-xl font-semibold text-blueGray-400 uppercase">
            Form Pemohon
          </h3>
          <button
            className="p-1 ml-auto bg-transparent border-0 text-blueGray-400 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            onClick={handleClose}
          >
            <span className="bg-transparent text-blueGray-500 h-6 w-6 text-xl block outline-none focus:outline-none">
              ×
            </span>
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className="relative p-2 flex-auto bg-blueGray-200">
            <div className="flex-auto px-5 lg:px-5 py-5 pt-0">
              {errors &&
                errors.msg &&
                errors.status &&
                errors.status === 400 &&
                refresh ? (
                <ErrorAlert errors={Object.entries(errors.msg)} />
              ) : null}

              <div className="w-full lg:w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Pemohon
                  </label>
                  <SingleSelectDb
                    innerRef={firstInput}
                    url={`/api/pemohons/list/${permohonan}`}
                    valueField="id"
                    labelField="nama_nik"
                    onChange={(e) => onSelectChange("pemohon", e)}
                    value={pemohon}
                  />
                </div>
              </div>
              <div className="w-full lg:w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Jenis Pemohon
                  </label>
                  <Select
                    classNamePrefix="select"
                    defaultValue={JENIS_PEMOHON_OPTIONS[0]}
                    name="jenis_pemohon"
                    options={JENIS_PEMOHON_OPTIONS}
                    onChange={(e) => onSelectChange("jenis_pemohon", e)}
                    value={jenis_pemohon}
                  />
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-1/3 lg:w-1/3 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Kuasa
                    </label>
                    <Switch
                      checked={kuasa}
                      onChange={(e) => onSelectChange("kuasa", e)}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={15}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                      height={10}
                      width={24}
                      className="react-switch"
                      id="material-switch"
                    />
                  </div>
                </div>
                <div className="w-1/3 lg:w-1/3 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Sendiri
                    </label>
                    <Switch
                      checked={sendiri}
                      onChange={(e) => onSelectChange("sendiri", e)}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={15}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                      height={10}
                      width={24}
                      className="react-switch"
                      id="material-switch"
                    />
                  </div>
                </div>
                <div className="w-1/3 lg:w-1/3 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Aktif
                    </label>
                    <Switch
                      checked={aktif}
                      onChange={(e) => onSelectChange("aktif", e)}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={15}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                      height={10}
                      width={24}
                      className="react-switch"
                      id="material-switch"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-formatsurat"
                  >
                    Format Surat
                  </label>
                  <SingleSelectDb
                    url={`/api/formatsurats/list`}
                    valueField="id"
                    labelField="caption"
                    onChange={(e) => onSelectChange("formatsurat", e)}
                    value={formatsurat}
                  />
                </div>
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Tindakan
                  </label>
                  <MultiSelectDb
                    url="/api/tindakans/list"
                    valueField="id"
                    labelField="judul"
                    value={tindakanpemohons}
                    onChange={(e) => onSelectChange("tindakanpemohons", e)}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b bg-blueGray-100">
            <button
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded-2xl shadow-lg shadow-slate-500 hover:shadow-slate-800 outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleClose}
            >
              Close
            </button>
            {loading ?
              <button
                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded-2xl shadow-lg shadow-slate-500 outline-none  mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                disabled={true}
              >
                <BeatLoader color="#36d7b7" size={12} />
              </button>
              : <>
                <button
                  className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded-2xl shadow-lg shadow-slate-500 hover:shadow-slate-800 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  Simpan
                </button>
              </>
            }
          </div>
        </form>
      </motion.div>
    </Backdrop>
  );
};

const mapStateToProps = (state) => ({
  errors: state.pemohonobyeks.tambahPemohonobyekError
    ? state.pemohonobyeks.tambahPemohonobyekError
    : state.pemohonobyeks.updatePemohonobyekError,
  isOpenPemohonobyek: state.pemohonobyeks.isOpenPemohonobyek,
  loading: state.pemohonobyeks.tambahPemohonobyekLoading
    ? state.pemohonobyeks.tambahPemohonobyekLoading
    : state.pemohonobyeks.updatePemohonobyekLoading,
});

export default connect(mapStateToProps, {
  addPemohonobyek,
  updatePemohonobyek,
})(AddPemohonobyekModal);
