import React, { Component } from "react";
import CardPilihSurat from "../../../components/Cards/Surats/CardPilihSurat";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import {
  listSuratInSuratobyek,
  listSuratNotInSuratobyek,
  updateListSuratobyek,
} from "../../../actions/suratobyeks";
import withRouter from "../../../withRouter";
export class DetailObyekPilihSurat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPemohonobyek: {},
      mode: "add",
      reload: false,
    };
  }
  componentDidMount() {
    let { obyid } = this.props.params;
    this.props.listSuratInSuratobyek(obyid);
    this.props.listSuratNotInSuratobyek(obyid);
  }
  render() {
    const {
      getListSuratInSuratobyekResult,
      getListSuratNotInSuratobyekResult,
    } = this.props.suratobyeks;

    return (
      <motion.div
        className="flex flex-wrap"
        initial={{ opacity: 0, scale: 0.7 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 1.2 }}
        transition={{ duration: 0.3 }}
      >
        {getListSuratInSuratobyekResult && getListSuratNotInSuratobyekResult ? (
          <CardPilihSurat
            obyid={this.props.params.obyid}
            suratinsuratobyek={getListSuratInSuratobyekResult}
            suratnotinsuratobyek={getListSuratNotInSuratobyekResult}
            updateListSuratobyek={this.props.updateListSuratobyek}
            listSuratNotInSuratobyek={this.props.listSuratNotInSuratobyek}
          />
        ) : null}
      </motion.div>
    );
  }
}
const mapStateToProps = (state) => ({
  suratobyeks: state.suratobyeks,
});

export default connect(mapStateToProps, {
  listSuratInSuratobyek,
  listSuratNotInSuratobyek,
  updateListSuratobyek,
})(withRouter(DetailObyekPilihSurat));
