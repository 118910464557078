import React, { Component } from "react";
import { AnimatePresence, motion } from "framer-motion";
import withRouter from "../../../withRouter";
import { connect } from "react-redux";
import { getDetailPermohonan } from "../../../actions/permohonans";
import { Navigate } from "react-router-dom";
import CardTableObyek from "../../../components/Cards/Permohonans/CardTableObyek";
import CardDetailPermohonan from "../../../components/Cards/Permohonans/CardDetailPermohonan";
import AddObyekModal from "../../../components/Modals/Obyek/AddObyekModal";
import { openObyek, closeObyek, deleteObyek } from "../../../actions/obyeks";
import { openPemohon, closePemohon } from "../../../actions/pemohons";
import CardTablePemohons from "../../../components/Cards/Pemohons/CardTablePemohons";
import AddPemohonModal from "../../../components/Modals/Pemohon/AddPemohonModal";
import Swal from "sweetalert2";

export class DetailPermohonan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openTab: 1,
      selectedObyek: {},
      selectedPemohon: {},
      mode: "add",
      reload: false,
    };
    this.closeModalObyek = this.closeModalObyek.bind(this);
    this.openModalObyek = this.openModalObyek.bind(this);
    this.closeModalPemohon = this.closeModalPemohon.bind(this);
    this.openModalPemohon = this.openModalPemohon.bind(this);
  }
  closeModalObyek = () => {
    this.props.closeObyek();
  };

  openModalObyek = ({ mode = "add", selectedObyek = {} } = {}) => {
    if (selectedObyek && selectedObyek.jenishak) {
    } else {
      const permohonan_id = this.props.permohonans.getDetailPermohonanResult
        ? this.props.permohonans.getDetailPermohonanResult.id
        : {};
      selectedObyek.permohonan = permohonan_id;
    }
    this.setState({ selectedObyek, mode });
    this.props.openObyek();
  };
  closeModalPemohon = () => {
    this.props.closePemohon();
  };

  openModalPemohon = ({ mode = "add", selectedPemohon = {} } = {}) => {
    if (selectedPemohon && selectedPemohon.nama_pemohon) {
    } else {
      const permohonan_id = this.props.permohonans.getDetailPermohonanResult
        ? this.props.permohonans.getDetailPermohonanResult.id
        : {};
      selectedPemohon.permohonan = permohonan_id;
    }
    this.setState({ selectedPemohon, mode });
    this.props.openPemohon();
  };

  componentDidMount() {
    let { id } = this.props.params;
    this.props.getDetailPermohonan({ id });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.tambahObyekResult !== prevProps.tambahObyekResult &&
      this.props.isOpenObyek === false
    ) {
      // console.log(Date.now());
      let { id } = this.props.params;
      this.props.getDetailPermohonan({ id });
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        timer: 1500,
        timerProgressBar: true,
      });
      Toast.fire("Ditambahkan!", "Obyek baru ditambahkan", "success");
    }

    if (
      this.props.updateObyekResult !== prevProps.updateObyekResult &&
      this.props.isOpenObyek === false
    ) {
      // console.log(Date.now());
      let { id } = this.props.params;
      this.props.getDetailPermohonan({ id });

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        timer: 1500,
        timerProgressBar: true,
      });
      Toast.fire("Diperbarui!", "Data obyek telah diperbarui", "success");
    }

    if (prevProps.deleteObyekResult !== this.props.deleteObyekResult) {
      let { id } = this.props.params;
      this.props.getDetailPermohonan({ id });
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        timer: 1500,
        timerProgressBar: true,
      });
      Toast.fire("Deleted!", `${this.props.deleteObyekResult}`, "success");
    }
  }
  //   static propTypes = {};
  render() {
    const {
      getDetailPermohonanLoading,
      getDetailPermohonanResult,
      getDetailPermohonanError,
    } = this.props.permohonans;
    // let obyekLength = getDetailPermohonanResult.jenispermohonans
    //   ? getDetailPermohonanResult.jenispermohonans.length + 1
    //   : 1;

    if (
      !getDetailPermohonanLoading &&
      getDetailPermohonanError &&
      getDetailPermohonanError.status === 404
    ) {
      return <Navigate to={"/admin/permohonans"} replace={true} />;
    } else {
    }
    return (
      <motion.div
        className="flex flex-wrap"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 1.5 }}
        transition={{ duration: 0.3 }}
      >
        <div className="w-full lg:w-1/4 px-4 mt-8 relative">
          {getDetailPermohonanResult ? (
            <CardDetailPermohonan permohonan={getDetailPermohonanResult} />
          ) : null}
        </div>
        <div className="w-full lg:w-3/4 px-4 mt-8 relative">
          {getDetailPermohonanResult.obyek_permohonan ? (
            <CardTablePemohons
              permId={getDetailPermohonanResult.id}
              openModalPemohon={this.openModalPemohon}
            />
          ) : null}
        </div>
        <div className="w-full lg:w-12/12 px-4 mt-8 relative">
          {getDetailPermohonanResult.obyek_permohonan ? (
            <CardTableObyek
              obyeks={getDetailPermohonanResult.obyek_permohonan}
              openModalObyek={this.openModalObyek}
              deleteObyek={this.props.deleteObyek}
            />
          ) : null}
        </div>

        <AnimatePresence
          initial={false}
          wait={true}
          onExitComplete={() => null}
        >
          {this.props.isOpenObyek && (
            <AddObyekModal
              handleClose={this.closeModalObyek}
              mode={this.state.mode}
              obyek={this.state.selectedObyek}
            />
          )}
          {this.props.isOpenPemohon && (
            <AddPemohonModal
              handleClose={this.closeModalPemohon}
              mode={this.state.mode}
              pemohon={this.state.selectedPemohon}
            />
          )}
        </AnimatePresence>
      </motion.div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    permohonans: state.permohonans,
    pemohons: state.pemohons,
    isOpenObyek: state.obyeks.isOpenObyek,
    isOpenPemohon: state.pemohons.isOpenPemohon,
    deleteObyekResult: state.obyeks.deleteObyekResult,
    tambahObyekResult: state.obyeks.tambahObyekResult,
    updateObyekResult: state.obyeks.updateObyekResult,
  };
};

const mapDispatchToProps = {
  getDetailPermohonan,
  openObyek,
  closeObyek,
  openPemohon,
  closePemohon,
  deleteObyek,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DetailPermohonan));
