import { dispatchError, dispatchLoading, dispatchSuccess } from "../utils";
import axios from "axios";

export const GET_LIST_OBYEK = "GET_LIST_OBYEK";
export const TAMBAH_OBYEK = "TAMBAH_OBYEK";
export const GET_DETAIL_OBYEK = "GET_DETAIL_OBYEK";
export const UPDATE_OBYEK = "UPDATE_OBYEK";
export const DELETE_OBYEK = "DELETE_OBYEK";
export const OPEN_OBYEK = "OPEN_OBYEK";
export const CLOSE_OBYEK = "CLOSE_OBYEK";
export const NEXT_PAGE_OBYEK = "NEXT_PAGE_OBYEK";
export const PREV_PAGE_OBYEK = "FREV_PAGE_OBYEK";
export const FIRST_PAGE_OBYEK = "FIRST_PAGE_OBYEK";

export const listObyek =
  ({ page = 1, order = "id", search = "" } = {}) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_OBYEK);
    axios
      .get(
        `/api/obyeks/?page=${page} ${order ? "&order_by=" + order : ""}${
          search ? "&nama_pemohon=" + search : ""
        }`,
        tokenConfig()
      )
      .then((res) => {
        dispatchSuccess(dispatch, GET_LIST_OBYEK, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_LIST_OBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };
export const getDetailObyek =
  ({ id }) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_DETAIL_OBYEK);
    axios
      .get(`/api/obyeks/${id}`, tokenConfig())
      .then((res) => {
        dispatchSuccess(dispatch, GET_DETAIL_OBYEK, res.data);
      })
      .catch((err) => {
        dispatchError(dispatch, GET_DETAIL_OBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
      });
  };

export const openObyek = () => (dispatch) => {
  dispatch({ type: OPEN_OBYEK });
};
export const closeObyek = () => (dispatch) => {
  dispatch({ type: CLOSE_OBYEK });
};
export const prevPageObyek = () => (dispatch) => {
  dispatch({ type: PREV_PAGE_OBYEK });
};

export const addObyek =
  ({
    alamat,
    jenishak,
    nomor_hak,
    persil,
    kelas,
    luas,
    luas_bangunan,
    atas_nama,
    nib,
    nop,
    jenis_tanah,
    batas_utara,
    batas_timur,
    batas_selatan,
    batas_barat,
    tanggal_surat,
    tanggal_su,
    jenis_su,
    nomor_su,
    jenispermohonan,
    permohonan,
    page,
    harga,
  }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      alamat,
      jenishak,
      nomor_hak,
      persil,
      kelas,
      luas,
      luas_bangunan,
      atas_nama,
      nib,
      nop,
      jenis_tanah,
      batas_utara,
      batas_timur,
      batas_selatan,
      batas_barat,
      tanggal_surat,
      tanggal_su,
      jenis_su,
      nomor_su,
      jenispermohonan,
      permohonan,
      page,
      harga,
    });
    dispatchLoading(dispatch, TAMBAH_OBYEK);
    dispatch({ type: OPEN_OBYEK });
    axios
      .post("/api/obyeks/", body, config)
      .then((res) => {
        dispatchSuccess(dispatch, TAMBAH_OBYEK, res.data);
        if (page === 3) {
          dispatch({ type: CLOSE_OBYEK });
        } else {
          dispatch({ type: NEXT_PAGE_OBYEK });
        }
      })
      .catch((err) => {
        dispatchError(dispatch, TAMBAH_OBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_OBYEK });
      });
  };

export const updateObyek =
  ({
    id,
    alamat,
    jenishak,
    nomor_hak,
    persil,
    kelas,
    luas,
    luas_bangunan,
    atas_nama,
    nib,
    nop,
    jenis_tanah,
    batas_utara,
    batas_timur,
    batas_selatan,
    batas_barat,
    tanggal_surat,
    tanggal_su,
    jenis_su,
    nomor_su,
    page,
    jenispermohonan,
    permohonan,
    harga,
  }) =>
  (dispatch) => {
    // Headers
    const config = {
      // baseURL: "http://127.0.0.1:8000",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      id,
      alamat,
      jenishak,
      nomor_hak,
      persil,
      kelas,
      luas,
      luas_bangunan,
      atas_nama,
      nib,
      nop,
      jenis_tanah,
      batas_utara,
      batas_timur,
      batas_selatan,
      batas_barat,
      tanggal_surat,
      tanggal_su,
      jenis_su,
      nomor_su,
      page,
      permohonan,
      jenispermohonan,
      harga,
    });
    dispatchLoading(dispatch, TAMBAH_OBYEK);
    dispatch({ type: OPEN_OBYEK });
    axios
      .patch(`/api/obyeks/${id}/`, body, config)
      .then((res) => {
        dispatchSuccess(dispatch, UPDATE_OBYEK, res.data);

        if (page === 3) {
          dispatch({ type: CLOSE_OBYEK });
        } else {
          dispatch({ type: NEXT_PAGE_OBYEK });
        }
      })
      .catch((err) => {
        dispatchError(dispatch, UPDATE_OBYEK, {
          msg: err.response.data,
          status: err.response.status,
        });
        dispatch({ type: OPEN_OBYEK });
      });
  };

export const deleteObyek = (id) => (dispatch) => {
  dispatchLoading(dispatch, DELETE_OBYEK);
  axios
    .delete(`/api/obyeks/${id}/`, tokenConfig())
    .then((res) => {
      dispatchSuccess(dispatch, DELETE_OBYEK, "DATA BERHASIL DIHAPUS");
    })
    .catch((err) =>
      dispatchError(dispatch, DELETE_OBYEK, {
        msg: err.response.data,
        status: err.response.status,
      })
    );
};

export const tokenConfig = () => {
  // Get token from state

  // Headers
  const config = {
    // baseURL: "http://127.0.0.1:8000",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  // if (token) {
  //   config.headers["Authorization"] = `Token ${token}`;
  // }

  return config;
};
