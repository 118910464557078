import { motion } from "framer-motion";
import { dropIn } from "../Animations";
import { useEffect, useRef, useState } from "react";
import Backdrop from "../Backdrop";
import { connect } from "react-redux";
import { addPemohon, updatePemohon, getPemohonNik, closePemohonNik } from "../../../actions/pemohons";
import { ErrorAlert } from "../../Alerts/ErrorAlert";
import AlamatSelect from "../../Selects/AlamatSelect";
import SingleSelectDb from "../../Selects/SingleSelectDb";
import { format } from "date-fns";
import Select from "react-select";
import { NumericFormat, PatternFormat } from "react-number-format";
import DateField from "../../Inputs/DateField";
import BeatLoader from "react-spinners/BeatLoader";

const AddPemohonModal = ({
  handleClose,
  addPemohon,
  errors,
  loading,
  pemohon,
  mode = "add",
  updatePemohon,
  getPemohonNik,
  getPemohonNikResult,
  getPemohonNikLoading,
  getPemohonNikError,
  closePemohonNik
}) => {
  const firstInput = useRef();

  const GENDER_OPTIONS = [
    { value: "Laki-Laki", label: "Laki-Laki" },
    { value: "Perempuan", label: "Perempuan" },
  ];
  const STATUS_PERNIKAHAN_OPTIONS = [
    { value: "Belum Menikah", label: "Belum Menikah" },
    { value: "Menikah", label: "Menikah" },
    { value: "Cerai Mati", label: "Cerai Mati" },
    { value: "Cerai Tercatat", label: "Cerai Tercatat" },
  ];
  const [formData, setFormData] = useState({
    nama_pemohon: "",
    gender: GENDER_OPTIONS[0],
    status_pernikahan: STATUS_PERNIKAHAN_OPTIONS[0],
    tanggal_lahir: new Date(),
    nik: "",
    rt: "0",
    rw: "0",
    npwp: "",
    alamat: {},
    pekerjaan: {},
    tempatlahir: {},
    permohonan: pemohon.permohonan ? pemohon.permohonan : null,
  });

  const {
    nama_pemohon,
    gender,
    status_pernikahan,
    tanggal_lahir,
    nik,
    rt,
    rw,
    npwp,
    alamat,
    pekerjaan,
    tempatlahir,
    permohonan,
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onNikBlur = (v) => {
    if(v.length===16 && mode === 'add'){
      getPemohonNik({nik:v})
    }
  };

  useEffect(() => {
    if (getPemohonNikResult && mode === "add") {
      let newPemohon = { ...getPemohonNikResult };
      newPemohon.tanggal_lahir = new Date(newPemohon.tanggal_lahir);
      const pk = newPemohon.pekerjaan;
      const npk = {
        value: pk.id,
        label: pk.nama_pekerjaan,
      };
      newPemohon.pekerjaan = npk;

      const tlh = newPemohon.tempatlahir;
      const tmplahir = {
        value: tlh.id,
        label: tlh.nama_tempatlahir,
      };
      newPemohon.tempatlahir = tmplahir;

      const gd = newPemohon.gender;
      const ngd = {
        value: gd,
        label: gd,
      };
      newPemohon.gender = ngd;
      const sp = newPemohon.status_pernikahan;
      const nsp = {
        value: sp,
        label: sp,
      };
      newPemohon.status_pernikahan = nsp;
      newPemohon.permohonan = pemohon.permohonan ? pemohon.permohonan : null;
      setFormData(newPemohon);
    } 
    return ()=>closePemohonNik()
  }, [getPemohonNikResult, mode, getPemohonNikLoading, closePemohonNik]);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const onSubmit = async (e) => {
    e.preventDefault();
    setRefresh(!refresh);
    if (mode === "add") {
      const newPemohon = {
        nama_pemohon,
        gender: gender.value ? gender.value : null,
        status_pernikahan: status_pernikahan.value
          ? status_pernikahan.value
          : null,
        tanggal_lahir: format(tanggal_lahir, "yyyy-MM-dd"),
        nik,
        rt,
        rw,
        npwp,
        alamat: alamat.id ? alamat.id : null,
        pekerjaan: pekerjaan.value ? pekerjaan.value : null,
        permohonan,
        tempatlahir: tempatlahir.value ? tempatlahir.value : null,
      };
      await addPemohon(newPemohon)
    } else {
      const newPemohon = {
        id: pemohon.id,
        nama_pemohon,
        gender: gender.value ? gender.value : null,
        status_pernikahan: status_pernikahan.value
          ? status_pernikahan.value
          : null,
        tanggal_lahir: format(tanggal_lahir, "yyyy-MM-dd"),
        nik,
        rt,
        rw,
        npwp,
        alamat: alamat.id ? alamat.id : null,
        pekerjaan: pekerjaan.value ? pekerjaan.value : null,
        permohonan,
        tempatlahir: tempatlahir.value ? tempatlahir.value : null,
      };
      await updatePemohon(newPemohon);
    }
  };
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setRefresh(true);
    return () => setRefresh(false);
  }, [errors]);

  useEffect(() => {
    if (pemohon && pemohon.nama_pemohon) {
      let newPemohon = { ...pemohon };
      const pk = newPemohon.pekerjaan;
      const npk = {
        value: pk.id,
        label: pk.nama_pekerjaan,
      };
      newPemohon.pekerjaan = npk;

      const gd = newPemohon.gender;
      const ngd = {
        value: gd,
        label: gd,
      };
      newPemohon.gender = ngd;
      const sp = newPemohon.status_pernikahan;
      const nsp = {
        value: sp,
        label: sp,
      };
      newPemohon.status_pernikahan = nsp;

      newPemohon.tanggal_lahir = new Date(newPemohon.tanggal_lahir);

      setFormData(newPemohon);
    } else {
    }
    firstInput.current.focus();
  }, [pemohon]);
  
  // useEffect(() => {
  //   closePemohonNik()
  // }, [])

  return (
    <Backdrop>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="border-0 rounded-lg shadow-lg absolute flex flex-col w-11/12 md:w-3/4 lg:w-1/2 outline-none focus:outline-none shadow-slate-700"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="flex items-start justify-between p-3 border-b border-solid bg-blueGray-100 rounded-t">
          <h3 className="text-xl font-semibold text-blueGray-400 uppercase">
            Form Pemohon
          </h3>
          <button
            className="p-1 ml-auto bg-transparent border-0 text-blueGray-400 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            onClick={handleClose}
          >
            <span className="bg-transparent text-blueGray-500 h-6 w-6 text-xl block outline-none focus:outline-none">
              ×
            </span>
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className="relative p-2 flex-auto bg-blueGray-200">
            <div className="flex-auto px-5 lg:px-5 py-5 pt-0">
              {errors &&
                errors.msg &&
                errors.status &&
                errors.status === 400 &&
                refresh ? (
                <ErrorAlert errors={Object.entries(errors.msg)} />
              ) : null}

              <div className="flex flex-wrap">
                <div className="w-1/2 lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      NIK
                    </label>
                    <PatternFormat                    
                      className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      format="################"
                      // allowEmptyFormatting
                      value={nik}
                      onValueChange={(values, sourceInfo) => {
                        onSelectChange("nik", values.value);
                      }}
                      onBlur={ (e)=>{
                        // await delay(500)
                        onNikBlur(e.target.value)
                      }
                      }
                      getInputRef={firstInput}
                    />
                  </div>
                </div>
                <div className="w-1/2 lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Nama Pemohon
                    </label>
                    <input
                      type="text"
                      className="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-lg focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      name="nama_pemohon"
                      value={nama_pemohon}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="w-1/2 lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      NPWP
                    </label>
                    <input
                      type="text"
                      className="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-lg focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      name="npwp"
                      value={npwp}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/2 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Tempat Lahir
                    </label>
                    <SingleSelectDb
                      url="/api/tempatlahirs/list"
                      valueField="id"
                      labelField="nama_tempatlahir"
                      onChange={(e) => onSelectChange("tempatlahir", e)}
                      value={tempatlahir}
                    />
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/2 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Tanggal Lahir
                    </label>
                    <DateField
                      selected={tanggal_lahir}
                      value={tanggal_lahir}
                      onChange={(date) => onSelectChange("tanggal_lahir", date)}
                      className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/2 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Kelamin
                    </label>
                    <Select
                      classNamePrefix="select"
                      defaultValue={GENDER_OPTIONS[0]}
                      name="gender"
                      options={GENDER_OPTIONS}
                      onChange={(e) => onSelectChange("gender", e)}
                      value={gender}
                    />
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/2 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Status Pernikahan
                    </label>
                    <Select
                      classNamePrefix="select"
                      defaultValue={STATUS_PERNIKAHAN_OPTIONS[0]}
                      name="status_pernikahan"
                      options={STATUS_PERNIKAHAN_OPTIONS}
                      onChange={(e) => onSelectChange("status_pernikahan", e)}
                      value={status_pernikahan}
                    />
                  </div>
                </div>

                <div className="w-1/2 lg:w-1/2 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Pekerjaan
                    </label>
                    <SingleSelectDb
                      url="/api/pekerjaans/list"
                      valueField="id"
                      labelField="nama_pekerjaan"
                      onChange={(e) => onSelectChange("pekerjaan", e)}
                      value={pekerjaan}
                    />
                  </div>
                </div>
                <div className="w-1/4 lg:w-1/4 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      RT
                    </label>
                    <PatternFormat
                      className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      format="###"
                      // allowEmptyFormatting
                      value={rt}
                      onValueChange={(values, sourceInfo) => {
                        onSelectChange("rt", values.value);
                      }}
                    />
                  </div>
                </div>
                <div className="w-1/4 lg:w-1/4 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      RW
                    </label>
                    <PatternFormat
                      className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      format="###"
                      // allowEmptyFormatting
                      value={rw}
                      onValueChange={(values, sourceInfo) => {
                        onSelectChange("rw", values.value);
                      }}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-1/2 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Alamat
                    </label>
                    <AlamatSelect
                      namaDesa={""}
                      value={alamat}
                      onChange={(e) => onSelectChange("alamat", e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b bg-blueGray-100">
            {loading ?
              <button
                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded-2xl shadow-lg shadow-slate-500 outline-none  mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                disabled={true}
              >
                <BeatLoader color="#36d7b7" size={12} />
              </button>
              : <>
                <button
                  className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded-2xl shadow-lg shadow-slate-500 hover:shadow-slate-800 outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded-2xl shadow-lg shadow-slate-500 hover:shadow-slate-800 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"

                >
                  Simpan
                </button>
              </>
            }
          </div>
        </form>
      </motion.div>
    </Backdrop>
  );
};

const mapStateToProps = (state) => 
{
 return ({
  errors: state.pemohons.tambahPemohonError
    ? state.pemohons.tambahPemohonError
    : state.pemohons.updatePemohonError,
  loading: state.pemohons.tambahPemohonLoading
    ? state.pemohons.tambahPemohonLoading
    : state.pemohons.updatePemohonLoading,
  isOpenPemohon: state.pemohons.isOpenPemohon,
  getPemohonNikResult: state.pemohons.getPemohonNikResult,
  getPemohonNikLoading: state.pemohons.getPemohonNikLoading,
  getPemohonNikError: state.pemohons.getPemohonNikError,

})
};

export default connect(mapStateToProps, { addPemohon, updatePemohon, getPemohonNik, closePemohonNik })(
  AddPemohonModal
);
