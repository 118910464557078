import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import { listArtikel, detailArtikel } from "../../../actions/artikels";
import parse from "html-react-parser";
import BeatLoader from "react-spinners/BeatLoader";

const Help = ({
  listArtikel,
  detailArtikel,
  getListArtikelLoading,
  getListArtikelResult,
  getListArtikelError,
  getDetailArtikelLoading,
  getDetailArtikelResult,
  getDetailArtikelError,
}) => {
  const color = "light";
  useEffect(() => {
    listArtikel();
  }, []);
  const [selectedHelp, setSelectedHelp] = useState(null);

  useEffect(() => {
    if (
      getListArtikelResult &&
      getListArtikelResult.results &&
      getListArtikelResult.results.length > 0
    ) {
      detailArtikel(getListArtikelResult.results[0].slug);
      setSelectedHelp(getListArtikelResult.results[0].slug);
    }
  }, [getListArtikelResult]);

  return (
    <motion.div
      className="flex flex-wrap"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1.5 }}
      transition={{ duration: 0.3 }}
    >
      <div className="w-full flex flex-col sm:flex-row p-4">
        <div
          className={
            "relative flex flex-col sm:flex-row min-w-0 break-words w-full shadow-lg rounded h-full p-2 " +
            (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
          }
        >
          <div className="p-2 w-full md:w-1/4">
            {getDetailArtikelLoading && (
              <div className="w-full absolute justify-center flex z-30 m-auto flex-col items-center bg-white h-32">
                <BeatLoader color="#36d7b7" size={20} />
              </div>
            )}

            {getListArtikelResult ? (
              <ul>
                {getListArtikelResult &&
                  getListArtikelResult.results.map((artikel, index) => {
                    return (
                      <li key={artikel.id}>
                        <button
                          className={`${
                            selectedHelp === artikel.slug
                              ? "text-lightBlue-400"
                              : "text-lightBlue-600"
                          } background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 `}
                          onClick={(e) => {
                            setSelectedHelp(artikel.slug);
                            detailArtikel(artikel.slug);
                          }}
                        >
                          {artikel.judul}
                        </button>
                      </li>
                    );
                  })}
              </ul>
            ) : null}
          </div>
          <div className="p-2 w-full md:w-3/4">
            {getDetailArtikelResult ? (
              <div>
                <h6 className="text-xl font-normal leading-normal mt-0 mb-2 text-lightBlue-800">
                  {getDetailArtikelResult.judul}
                </h6>
                <p className="text-base font-light leading-relaxed mt-0 mb-4 text-lightBlue-800">
                  {parse(getDetailArtikelResult.body)}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </motion.div>
  );
};
const mapStateToProps = (state) => {
  return {
    getListArtikelLoading: state.artikels.getListArtikelLoading,
    getListArtikelResult: state.artikels.getListArtikelResult,
    getListArtikelError: state.artikels.getListArtikelError,
    getDetailArtikelLoading: state.artikels.getDetailArtikelLoading,
    getDetailArtikelResult: state.artikels.getDetailArtikelResult,
    getDetailArtikelError: state.artikels.getDetailArtikelError,
  };
};

const mapDispatchToProps = { listArtikel, detailArtikel };

export default connect(mapStateToProps, mapDispatchToProps)(Help);
