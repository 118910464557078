import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getDetailPejabat,
  updatePejabat,
  addPejabat,
} from "../../../actions/pejabats";
import { format } from "date-fns";
import Select from "react-select";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import { ErrorAlert } from "../../Alerts/ErrorAlert";
import DateField from "../../Inputs/DateField";
import KabupatenSelect from "../../Selects/KabupatenSelect";

const CardPejabat = ({
  getDetailPejabatResult,
  getDetailPejabat,
  addPejabat,
  updatePejabat,
  getDetailPejabatLoading,
  updatePejabatLoading,
  updatePejabatResult,
  errors,
}) => {
  //id, nama_notaris, gelar_depan, gelar_belakang, gelar_depan_pendek, gelar_belakang_pendek, gender, status_pernikahan, tempatlahir, tanggal_lahir, nik, npwp, alamat, nama_jalan, alamat_kantor, telp, email, user, nomor_sk_notaris, nomor_sk_ppat, tanggal_sk_notaris
  const GENDER_OPTIONS = [
    { value: "Laki-Laki", label: "Laki-Laki" },
    { value: "Perempuan", label: "Perempuan" },
  ];
  const STATUS_PERNIKAHAN_OPTIONS = [
    { value: "Belum Menikah", label: "Belum Menikah" },
    { value: "Menikah", label: "Menikah" },
    { value: "Cerai Mati", label: "Cerai Mati" },
    { value: "Cerai Tercatat", label: "Cerai Tercatat" },
  ];
  const initValue = {
    id: 0,
    nama_notaris: "",
    gelar_depan: "",
    gelar_belakang: "",
    gelar_depan_pendek: "",
    gelar_belakang_pendek: "",
    gender: GENDER_OPTIONS[0],
    status_pernikahan: STATUS_PERNIKAHAN_OPTIONS[0],
    tempatlahir: "",
    tanggal_lahir: new Date(),
    nik: "",
    npwp: "",
    alamat: "",
    alamat_kantor: "",
    telp: "",
    email: "",
    user: 0,
    kedudukan: {},
    nomor_sk_notaris: "",
    nomor_sk_ppat: "",
    tanggal_sk_notaris: new Date(),
    tanggal_sk_ppat: new Date(),
    handphone: "",
    kode_pos: "",
  };
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState(initValue);

  const onSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Apakah ingin menyimpan perubahan ?",
      text: "update data pejabat!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, save it!",
    }).then((result) => {
      if (isEdit) {
        let newPejabat = { ...formData };
        newPejabat.tanggal_lahir = format(tanggal_lahir, "yyyy-MM-dd");
        newPejabat.tanggal_sk_notaris = format(
          tanggal_sk_notaris,
          "yyyy-MM-dd"
        );
        newPejabat.tanggal_sk_ppat = format(tanggal_sk_ppat, "yyyy-MM-dd");
        newPejabat.gender = gender.value ? gender.value : null;
        newPejabat.status_pernikahan = status_pernikahan.value
          ? status_pernikahan.value
          : null;
        newPejabat.kedudukan = kedudukan.id ? kedudukan.id : null;
        updatePejabat(newPejabat);
      } else {
        let newPejabat = { ...formData };
        newPejabat.tanggal_lahir = format(tanggal_lahir, "yyyy-MM-dd");
        newPejabat.tanggal_sk_notaris = format(
          tanggal_sk_notaris,
          "yyyy-MM-dd"
        );
        newPejabat.tanggal_sk_ppat = format(tanggal_sk_ppat, "yyyy-MM-dd");
        newPejabat.gender = gender.value ? gender.value : null;
        newPejabat.status_pernikahan = status_pernikahan.value
          ? status_pernikahan.value
          : null;
        newPejabat.kedudukan = kedudukan.id ? kedudukan.id : null;
        addPejabat(newPejabat);
      }
    });
  };
  const {
    id,
    nama_notaris,
    gelar_depan,
    gelar_belakang,
    gelar_depan_pendek,
    gelar_belakang_pendek,
    gender,
    status_pernikahan,
    tempatlahir,
    tanggal_lahir,
    nik,
    alamat,
    alamat_kantor,
    telp,
    email,
    nomor_sk_notaris,
    nomor_sk_ppat,
    tanggal_sk_notaris,
    tanggal_sk_ppat,
    kedudukan,
    handphone,
    kode_pos,
  } = formData;
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    getDetailPejabat();
  }, []);

  useEffect(() => {
    if (getDetailPejabatResult) {
      setIsEdit(true);
      let newPejabat = { ...getDetailPejabatResult };
      newPejabat.tanggal_lahir = new Date(newPejabat.tanggal_lahir);
      newPejabat.tanggal_sk_notaris = new Date(newPejabat.tanggal_sk_notaris);
      newPejabat.tanggal_sk_ppat = new Date(newPejabat.tanggal_sk_ppat);
      const gd = newPejabat.gender;
      const ngd = {
        value: gd,
        label: gd,
      };
      newPejabat.gender = ngd;
      const sp = newPejabat.status_pernikahan;
      const nsp = {
        value: sp,
        label: sp,
      };
      newPejabat.status_pernikahan = nsp;
      setFormData(newPejabat);
    } else {
      setIsEdit(false);
    }
  }, [getDetailPejabatResult]);

  return (
    <>
      {getDetailPejabatLoading && (
        <div className="w-full absolute justify-center flex z-30 m-auto flex-col items-center bg-white h-32 overflow-auto">
          <BeatLoader color="#36d7b7" size={20} />
        </div>
      )}

      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              DATA PEJABAT
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          {errors && errors.msg && errors.status && errors.status === 400 ? (
            <ErrorAlert errors={Object.entries(errors.msg)} />
          ) : null}

          {formData && !getDetailPejabatLoading ? (
            <form onSubmit={onSubmit}>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Identitas PPAT
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Nama Lengkap
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={nama_notaris}
                      onChange={onChange}
                      name="nama_notaris"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Tempat Lahir
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={tempatlahir}
                      onChange={onChange}
                      name="tempatlahir"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      TANGGAL LAHIR
                    </label>
                    <DateField
                      selected={tanggal_lahir}
                      value={tanggal_sk_ppat}
                      onChange={(date) => onSelectChange("tanggal_lahir", date)}
                      className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/2 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Kelamin
                    </label>
                    <Select
                      classNamePrefix="select"
                      name="gender"
                      options={GENDER_OPTIONS}
                      onChange={(e) => onSelectChange("gender", e)}
                      value={gender}
                    />
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/2 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Status Pernikahan
                    </label>
                    <Select
                      classNamePrefix="select"
                      name="status_pernikahan"
                      options={STATUS_PERNIKAHAN_OPTIONS}
                      onChange={(e) => onSelectChange("status_pernikahan", e)}
                      value={status_pernikahan}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      NIK
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={nik}
                      onChange={onChange}
                      name="nik"
                    />
                  </div>
                </div>
              </div>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                JABATAN
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Gelar Depan
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={gelar_depan}
                      onChange={onChange}
                      name="gelar_depan"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Gelar Belakang
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={gelar_belakang}
                      onChange={onChange}
                      name="gelar_belakang"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Singkatan Gelar Depan
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={gelar_depan_pendek}
                      onChange={onChange}
                      name="gelar_depan_pendek"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      SINGKATAN GELAR BELAKANG
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={gelar_belakang_pendek}
                      onChange={onChange}
                      name="gelar_belakang_pendek"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      NOMOR SK PPAT
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={nomor_sk_ppat}
                      onChange={onChange}
                      name="nomor_sk_ppat"
                    />
                  </div>
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      TANGGAL SK PPAT
                    </label>
                    <DateField
                      selected={tanggal_sk_ppat}
                      value={tanggal_sk_ppat}
                      onChange={(date) =>
                        onSelectChange("tanggal_sk_ppat", date)
                      }
                      className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      NOMOR SK NOTARIS
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={nomor_sk_notaris}
                      onChange={onChange}
                      name="nomor_sk_notaris"
                    />
                  </div>
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      TANGGAL SK NOTARIS
                    </label>
                    <DateField
                      selected={tanggal_sk_notaris}
                      value={tanggal_sk_notaris}
                      onChange={(date) =>
                        onSelectChange("tanggal_sk_notaris", date)
                      }
                      className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Kantor Kedudukan
                    </label>
                    <KabupatenSelect
                      namaKabupaten={""}
                      value={kedudukan}
                      onChange={(e) => onSelectChange("kedudukan", e)}
                    />
                  </div>
                </div>
              </div>

              <hr className="mt-6 border-b-1 border-blueGray-300" />

              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Contact Information
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Alamat
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={alamat}
                      onChange={onChange}
                      name="alamat"
                    />
                  </div>
                </div>
                {/* <div className="w-full lg:w-4/12 px-4"> */}
                {/* <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Daerah Kerja
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={kedudukan}
                      name="kedudukan"
                      onChange={onChange}
                    />
                  </div>
                </div> */}

                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Telp
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={telp}
                      onChange={onChange}
                      name="telp"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Handphone
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={handphone}
                      name="handphone"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={email}
                      name="email"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Kode Pos
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={kode_pos}
                      name="kode_pos"
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Alamat Kantor
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={alamat_kantor}
                      name="alamat_kantor"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="flex w-full justify-end p-3">
                  <button
                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="submit"
                    disabled={updatePejabatLoading}
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getDetailPejabatResult: state.pejabats.getDetailPejabatResult,
    getDetailPejabatLoading: state.pejabats.getDetailPejabatLoading,
    updatePejabatLoading: state.pejabats.updatePejabatLoading,
    updatePejabatResult: state.pejabats.updatePejabatResult,
    errors: state.pejabats.tambahPejabatError
      ? state.pejabats.tambahPejabatError
      : state.pejabats.updatePejabatError,
  };
};

export default connect(mapStateToProps, {
  getDetailPejabat,
  updatePejabat,
  addPejabat,
})(CardPejabat);
