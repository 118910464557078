import React from "react";
import { Link } from "react-router-dom";
import { useCommonDropdown } from "./CommonDropdown";
const DropDownItem = ({ title, cmd = null, url = null }) => {
  const { closeDropdownPopover } = useCommonDropdown();
  if (url) {
    return (
      <Link
        className={
          "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-center"
        }
        to={url}
      >
        {title}
      </Link>
    );
  }
  if (typeof cmd === "function") {
    return (
      <button
        href="/#"
        className={
          "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        }
        onClick={(e) => {
          e.preventDefault();
          closeDropdownPopover();
          cmd();
        }}
      >
        {title}
      </button>
    );
  }
  return (
    <div className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
      {title}
    </div>
  );
};

export default DropDownItem;
