import { motion } from "framer-motion";
import { dropIn } from "../Animations";
import { useEffect, useRef, useState } from "react";
import Backdrop from "../Backdrop";
import { connect } from "react-redux";
import SingleSelectDbImage from "../../Selects/SingleSelectDbImage";

const UpdateFormatSuratModal = ({
  handleClose,
  errors,
  handleSave,
}) => {

  const [formData, setFormData] = useState({
    id: null,
    formatsurat: null,
  });

  const { formatsurat } = formData;

  const onSelectChange = (name, data) => {
    setFormData({ ...formData, [name]: data });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if(formData.formatsurat){
      handleSave(formData.formatsurat.value)
      handleClose()
    }
  };

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setRefresh(true);
    return () => setRefresh(false);
  }, [errors]);

  useEffect(() => {
  }, []);

  return (
    <Backdrop>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="border-0 rounded-lg shadow-lg absolute flex flex-col w-1/2 md:w-1/4 outline-none focus:outline-none shadow-slate-700"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="flex items-start justify-between p-3 border-b border-solid bg-blueGray-100 rounded-t">
          <h3 className="text-xl font-semibold text-blueGray-400 uppercase">
            Format Indet List
          </h3>
          <button
            className="p-1 ml-auto bg-transparent border-0 text-blueGray-400 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            onClick={handleClose}
          >
            <span className="bg-transparent text-blueGray-500 h-6 w-6 text-xl block outline-none focus:outline-none">
              ×
            </span>
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className="relative p-2 flex-auto bg-blueGray-200">
            <div className="flex-auto px-5 lg:px-5 py-5 pt-0">

              <div className="w-full lg:w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-formatsurat"
                  >
                    Format Surat
                  </label>
                  <SingleSelectDbImage
                    url={`/api/formatsurats/list`}
                    valueField="id"
                    labelField="caption"
                    imageField="img"
                    onChange={(e) => onSelectChange("formatsurat", e)}
                    value={formatsurat}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b bg-blueGray-100">
            <button
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded-2xl shadow-lg shadow-slate-500 hover:shadow-slate-800 outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded-2xl shadow-lg shadow-slate-500 hover:shadow-slate-800 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="submit"
            >
              Simpan
            </button>
          </div>
        </form>
      </motion.div>
    </Backdrop>
  );
};

const mapStateToProps = (state) => ({
  // errors: state.tindakanpemohons.tambahTindakanpemohonError
  //   ? state.tindakanpemohons.tambahTindakanpemohonError
  //   : state.tindakanpemohons.updateTindakanpemohonError,
  // isOpenTindakanpemohon: state.tindakanpemohons.isOpenTindakanpemohon,
});

export default connect(mapStateToProps, {
  // addTindakanpemohon,
  // updateTindakanpemohon,
})(UpdateFormatSuratModal);
