import React from "react";
import Chart from "chart.js/auto";
import { connect } from "react-redux";
import { listChartPermohonan } from "../../actions/charts";

export function CardLineChart({ getListChartResult, listChartPermohonan }) {
  React.useEffect(() => {
    if (!getListChartResult) {
      listChartPermohonan();
    }
    if (getListChartResult) {
      const labels = getListChartResult.map((d, i) => d.nama_jenis_permohonan);
      const datas = getListChartResult.map((d, i) => d.jumlah);
      const labelNames = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      let datasa = getListChartResult.map((d, i) => {
        var randomColor = Math.floor(Math.random() * 16777215).toString(16);
        let datas = {
          label: d.nama_jenis_permohonan,
          backgroundColor: `#${randomColor}`,
          borderColor: `#${randomColor}`,
          data: [d.jumlah],
          fill: false,
        };
        return datas;
      });
      var randomColor = Math.floor(Math.random() * 16777215).toString(16);

      var config = {
        type: "bar",
        data: {
          labels: [...labels],
          datasets: [
            {
              label: new Date().getFullYear(),
              backgroundColor: `#${randomColor}`,
              borderColor: `#${randomColor}`,
              data: [...datas],
              fill: false,
              barThickness: 60,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: false,
            text: "Sales Charts",
            fontColor: "white",
          },
          legend: {
            labels: {
              fontColor: "white",
            },
            align: "end",
            position: "bottom",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          scales: {
            x: {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Month",
                fontColor: "white",
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(0, 0, 0, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
            y: {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
                fontColor: "white",
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: "rgba(255, 255, 255, 0.15)",
                zeroLineColor: "rgba(33, 37, 41, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          },
        },
      };
    }
    var ctx = document.getElementById("line-chart").getContext("2d");
    var myChart = new Chart(ctx, config);
    return () => {
      myChart.destroy();
    };
  }, [getListChartResult]);
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
                Grafik
              </h6>
              <h2 className="text-white text-xl font-semibold">
                Jumlah Permohonan
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-350-px">
            <canvas id="line-chart"></canvas>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  getListChartError: state.charts.getListChartError,
  getListChartLoading: state.charts.getListChartLoading,
  getListChartResult: state.charts.getListChartResult,
});

export default connect(mapStateToProps, {
  listChartPermohonan,
})(CardLineChart);
