import React, { useEffect } from "react";
import { createPopper } from "@popperjs/core";

const CommonDropdownContext = React.createContext({});
export const useCommonDropdown = () => React.useContext(CommonDropdownContext);

const CommonDropdown = ({ children }) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const wrapperRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "left-start",
      strategy: "fixed",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
        // closeDropdownPopover();
      } else {
        closeDropdownPopover();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);
  const value = {
    closeDropdownPopover,
  };

  return (
    <CommonDropdownContext.Provider value={value}>
      <div ref={wrapperRef}>
        <a
          className="text-blueGray-500 py-1 px-3"
          href="#pablo"
          ref={btnDropdownRef}
          onClick={(e) => {
            e.preventDefault();
            // dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
            dropdownPopoverShow
              ? closeDropdownPopover()
              : openDropdownPopover();
          }}
        >
          <i className="fas fa-ellipsis-v"></i>
        </a>
        <div
          ref={popoverDropdownRef}
          className={
            (dropdownPopoverShow ? "block " : "hidden ") +
            "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
          }
        >
          {children}
        </div>
      </div>
    </CommonDropdownContext.Provider>
  );
};
export default CommonDropdown;
