import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

const KabupatenSelect = (props) => {
  const [inputNamaKabupaten, setInputNamaKabupaten] = useState("");
  useEffect(() => {
    setInputNamaKabupaten(props.namaKabupaten);
  }, [props.namaKabupaten]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const response = await fetch(
      `/api/kabupatens/list?q=${inputNamaKabupaten}&page=${page}&pageSize=10`
    );
    const responseJSON = await response.json();
    return {
      options: responseJSON,
      hasMore: responseJSON.length >= 1,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <AsyncPaginate
      key={JSON.stringify(inputNamaKabupaten.id)}
      value={props.value && props.value.id ? props.value : null}
      loadOptions={loadOptions}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) =>
        `${option.nama_kabupaten} - ${option.nama_propinsi}`
      }
      onInputChange={(e) => {
        setInputNamaKabupaten(e);
      }}
      onChange={onChange}
      isSearchable={true}
      placeholder="Select Kabupaten"
      additional={{
        page: 1,
      }}
    />
  );
};

KabupatenSelect.defaultProps = {
  value: {},
};

KabupatenSelect.propTypes = {
  namaKabupaten: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default KabupatenSelect;
