import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

const AlamatSelect = (props) => {
  const [inputNamaDesa, setInputNamaDesa] = useState("");
  useEffect(() => {
    setInputNamaDesa(props.namaDesa);
  }, [props.inputNamaDesa]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const response = await fetch(
      `/api/alamats/list?q=${inputNamaDesa}&page=${page}&pageSize=10`
    );
    const responseJSON = await response.json();
    return {
      options: responseJSON,
      hasMore: responseJSON.length >= 1,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <AsyncPaginate
      key={JSON.stringify(inputNamaDesa.id)}
      value={props.value.id ? props.value : null}
      loadOptions={loadOptions}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) =>
        `${option.nama_desa} - ${option.nama_kecamatan}`
      }
      onInputChange={(e) => {
        setInputNamaDesa(e);
      }}
      onChange={onChange}
      isSearchable={true}
      placeholder="Select Alamat"
      additional={{
        page: 1,
      }}
    />
  );
};

AlamatSelect.defaultProps = {
  value: {},
};

AlamatSelect.propTypes = {
  namaDesa: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default AlamatSelect;
