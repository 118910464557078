import React, { useState } from "react";
import FormAlert, { ALERT_ERROR } from "./FormAlert";
import { AnimatePresence, motion } from "framer-motion";
export const ErrorAlert = ({ errors }) => {
  const [ferrors, setFerrors] = useState(errors);
  const list = {
    hidden: { opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delayChildren: 0, // this will set a delay before the children start animating
        staggerChildren: 0.2, // this will set the time inbetween children animation
      },
    },
  };
  const item = {
    hidden: { y: -50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        transition: { delay: 0.2 },
      },
    },
  };

  return (
    <div>
      <motion.div
        className="flex-column justify-between p-2"
        initial={"hidden"}
        animate={"visible"}
        variants={list}
      >
        <AnimatePresence>
          {ferrors &&
            ferrors.map((a, i) => {
              return (
                <FormAlert
                  key={i}
                  alertType={ALERT_ERROR}
                  variants={item}
                  title={JSON.stringify(a[0])}
                  text={JSON.stringify(a[1][0])}
                  onRemove={(e) => {
                    const fltr = ferrors.filter((itm) => itm[0] !== a[0]);
                    setFerrors(fltr);
                  }}
                />
              );
            })}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};
