import {
  GET_LIST_OBYEK,
  TAMBAH_OBYEK,
  UPDATE_OBYEK,
  DELETE_OBYEK,
  CLOSE_OBYEK,
  OPEN_OBYEK,
  GET_DETAIL_OBYEK,
  NEXT_PAGE_OBYEK,
  PREV_PAGE_OBYEK,
  FIRST_PAGE_OBYEK,
} from "../actions/obyeks";

const initialState = {
  getListObyekLoading: false,
  getListObyekResult: false,
  getListObyekError: false,

  tambahObyekLoading: false,
  tambahObyekResult: false,
  tambahObyekError: false,

  getDetailObyekLoading: false,
  getDetailObyekResult: false,
  getDetailObyekError: false,

  updateObyekLoading: false,
  updateObyekResult: false,
  updateObyekError: false,

  deleteObyekLoading: false,
  deleteObyekResult: false,
  deleteObyekError: false,

  isOpenObyek: false,
  pageObyek: 1,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OBYEK:
      return {
        ...state,
        getListObyekLoading: action.payload.loading,
        getListObyekResult: action.payload.data,
        getListObyekError: action.payload.errorMessage,
      };
    case GET_DETAIL_OBYEK:
      return {
        ...state,
        getDetailObyekLoading: action.payload.loading,
        getDetailObyekResult: action.payload.data,
        getDetailObyekError: action.payload.errorMessage,
      };

    case TAMBAH_OBYEK:
      return {
        ...state,
        tambahObyekLoading: action.payload.loading,
        tambahObyekResult: action.payload.data,
        tambahObyekError: action.payload.errorMessage,
      };

    case UPDATE_OBYEK:
      return {
        ...state,
        updateObyekLoading: action.payload.loading,
        updateObyekResult: action.payload.data,
        updateObyekError: action.payload.errorMessage,
      };

    case DELETE_OBYEK:
      return {
        ...state,
        deleteObyekLoading: action.payload.loading,
        deleteObyekResult: action.payload.data,
        deleteObyekError: action.payload.errorMessage,
      };
    case OPEN_OBYEK:
      return {
        ...state,
        isOpenObyek: true,
        updateObyekResult: false,
        tambahObyekResult: false,
      };
    case CLOSE_OBYEK:
      return {
        ...state,
        isOpenObyek: false,
        updateObyekError: false,
        tambahObyekError: false,
        pageObyek: 1,
      };
    case NEXT_PAGE_OBYEK:
      const newnpage =
        state.pageObyek < 3 ? state.pageObyek + 1 : state.pageObyek;
      return {
        ...state,
        pageObyek: newnpage,
      };
    case PREV_PAGE_OBYEK:
      const newppage =
        state.pageObyek > 1 ? state.pageObyek - 1 : state.pageObyek;
      return {
        ...state,
        pageObyek: newppage,
      };
    case FIRST_PAGE_OBYEK:
      const newfpage = 1;
      return {
        ...state,
        pageObyek: newfpage,
      };
    default:
      return state;
  }
}
