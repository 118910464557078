// import './App.css';
import React from "react";

// importing components from another files
import { ListComponents } from "./ListComponent";

// imports related to DND
import { DragDropContext } from "@hello-pangea/dnd";

function CardPilihSurat({
  obyid,
  suratinsuratobyek,
  suratnotinsuratobyek,
  updateListSuratobyek,
  listSuratNotInSuratobyek,
}) {
  // List 1 consisting of all MARVEL super heroes
  const [list1, setList1] = React.useState(suratnotinsuratobyek);

  // List 2 consisting of all DC super heroes
  const [list2, setList2] = React.useState(suratinsuratobyek);

  // Function for deleting items from list using index
  const deleteItem = (list, index) => {
    return list.splice(index, 1);
  };
  let newList = [...suratnotinsuratobyek];

  // Function for filtering items from list using index
  const filterList = (keyword) => {
    // const newList = [...list1];
    if (keyword.length > 0) {
      const result = newList.filter((lst) => {
        let srcNamaSurat = lst.nama_surat.toLowerCase();
        return srcNamaSurat.indexOf(keyword.toLowerCase()) > -1 ? lst : false;
      });
      setList1(result);
    } else {
      // listSuratNotInSuratobyek(obyid);
      setList1(newList);
    }
  };

  const updateData = () => {
    if (list2.length > 0) {
      updateListSuratobyek(obyid, list2);
    }
  };
  // Function called when Drag Ends
  const onDragEnd = (result) => {
    // getting the source and destination object
    const { source, destination } = result;
    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "Marvel_drop_area") {
        let tempList = list1;
        const removed = deleteItem(tempList, source.index);
        tempList.splice(destination.index, 0, removed[0]);
        setList1(tempList);
      } else {
        let tempList = list2;
        const removed = deleteItem(tempList, source.index);
        tempList.splice(destination.index, 0, removed[0]);
        setList2(tempList);
        updateData();
      }
    } else {
      let tempList1 = list1;
      let tempList2 = list2;
      if (source.droppableId === "Marvel_drop_area") {
        const removed = deleteItem(tempList1, source.index);
        tempList2.splice(destination.index, 0, removed[0]);
        setList1(tempList1);
        setList2(tempList2);
        const idx = suratnotinsuratobyek.findIndex(
          (v) => v.id === removed[0].id
        );
        if (idx !== -1) {
          suratnotinsuratobyek.splice(idx, 1);
        }
        updateData();
      } else {
        const removed = deleteItem(tempList2, source.index);
        tempList1.splice(destination.index, 0, removed[0]);
        suratnotinsuratobyek.splice(destination.index, 0, removed[0]);
        setList1(tempList1);
        setList2(tempList2);
        updateData();
      }
    }
  };

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full pb-2 shadow-lg rounded-lg bg-blueGray-100 border-0">
      <div className="rounded-t bg-white mb-0 px-6 py-6 h-full">
        <div className="text-center flex justify-between">
          <h6 className="text-blueGray-700 text-xl font-bold">Pilih Surat</h6>
          {/* <button
            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => updateData()}
          >
            Simpan
          </button> */}
        </div>
      </div>
      <div className="max-h-[472px]">
        <DragDropContext onDragEnd={onDragEnd}>
          <ListComponents Marvel={list1} filterList={filterList} DC={list2} />
        </DragDropContext>
      </div>
    </div>
  );
}

export default CardPilihSurat;
