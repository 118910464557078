import { dispatchError, dispatchLoading, dispatchSuccess } from "../utils";
import axios from "axios";
import { returnErrors } from "./messages";

export const GET_LIST_ARTIKEL = "GET_LIST_ARTIKEL";
export const TAMBAH_ARTIKEL = "TAMBAH_ARTIKEL";
export const GET_DETAIL_ARTIKEL = "GET_DETAIL_ARTIKEL";
export const UPDATE_ARTIKEL = "UPDATE_ARTIKEL";
export const DELETE_ARTIKEL = "DELETE_ARTIKEL";

export const listArtikel =
  ({ page = 1, order = "id", search = "" } = {}) =>
  (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_ARTIKEL);

    axios
      .get(
        `/api/artikels/?page=${page} ${order ? "&order_by=" + order : ""}${
          search ? "&judul=" + search : ""
        }`,
        tokenConfig()
      )
      .then((res) => {
        dispatchSuccess(dispatch, GET_LIST_ARTIKEL, res.data);
      })
      .catch((err) => {
        dispatchError(
          dispatch,
          GET_LIST_ARTIKEL,
          returnErrors(err.response.data, err.response.status)
        );
      });
  };

export const detailArtikel = (slug) => (dispatch) => {
  dispatchLoading(dispatch, GET_DETAIL_ARTIKEL);
  axios
    .get(`/api/artikels/${slug}`, tokenConfig())
    .then((res) => {
      dispatchSuccess(dispatch, GET_DETAIL_ARTIKEL, res.data);
    })
    .catch((err) => {
      dispatchError(dispatch, GET_DETAIL_ARTIKEL, {
        msg: err.response.data,
        status: err.response.status,
      });
    });
};

export const tokenConfig = () => {
  // Get token from state

  // Headers
  const config = {
    // baseURL: "http://127.0.0.1:8000",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  // if (token) {
  //   config.headers["Authorization"] = `Token ${token}`;
  // }

  return config;
};
