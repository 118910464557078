import React from "react";
import { motion } from "framer-motion";
// components

// import CardProfile from "../../../components/Cards/CardProfile.js";
import CardPejabat from "../../../components/Cards/Pengaturan/CardPejabat.js";

export default function Settings() {
  return (
    <motion.div
      className="flex flex-wrap"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1.5 }}
      transition={{ duration: 0.3 }}
    >
      <div className="w-full lg:w-8/12 px-4">
        <CardPejabat />
      </div>
      {/* <div className="w-full lg:w-4/12 px-4">
        <CardProfile />
      </div> */}
    </motion.div>
  );
}
