import {
  GET_LIST_ALAMAT,
  TAMBAH_ALAMAT,
  UPDATE_ALAMAT,
  DELETE_ALAMAT,
} from "../actions/alamats";

const initialState = {
  getListAlamatLoading: false,
  getListAlamatResult: false,
  getListAlamatError: false,

  tambahAlamatLoading: false,
  tambahAlamatResult: false,
  tambahAlamatError: false,

  getDetailAlamatLoading: false,
  getDetailAlamatResult: false,
  getDetailAlamatError: false,

  updateAlamatLoading: false,
  updateAlamatResult: false,
  updateAlamatError: false,

  deleteAlamatLoading: false,
  deleteAlamatResult: false,
  deleteAlamatError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_ALAMAT:
      return {
        ...state,
        getListAlamatLoading: action.payload.loading,
        getListAlamatResult: action.payload.data,
        getListAlamatError: action.payload.errorMessage,
      };

    case TAMBAH_ALAMAT:
      return {
        ...state,
        tambahAlamatLoading: action.payload.loading,
        tambahAlamatResult: action.payload.data,
        tambahAlamatError: action.payload.errorMessage,
      };

    case UPDATE_ALAMAT:
      return {
        ...state,
        updateAlamatLoading: action.payload.loading,
        updateAlamatResult: action.payload.data,
        updateAlamatError: action.payload.errorMessage,
      };

    case DELETE_ALAMAT:
      return {
        ...state,
        deleteAlamatLoading: action.payload.loading,
        deleteAlamatResult: action.payload.data,
        deleteAlamatError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
