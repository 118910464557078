import React, { useEffect, useState } from "react";
import { deletePemohon, listPemohon } from "../../../actions/pemohons";
import ReactPaginate from "react-paginate";
import CommonDropdown from "../../Dropdowns/CommonDropdown";
import DropDownItem from "../../Dropdowns/DropDownItem";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";

export const CardTablePemohons = ({
  openModalPemohon,
  permId,
  getListPemohonError,
  getListPemohonLoading,
  getListPemohonResult,
  deletePemohonResult,
  listPemohon,
  deletePemohon,
  isOpenPemohon,
  tambahPemohonResult,
  updatePemohonResult,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const color = "light";
  const [order, setOrder] = useState("id");
  const [search, setSearch] = useState("");
  useEffect(() => {
    let params = { perm_id: permId, page: currentPage + 1, order, search };
    listPemohon(params);
    return()=>console.log(permId)
  }, [currentPage, order, search, deletePemohonResult, permId]);
  useEffect(() => {
    let params = { perm_id: permId, page: currentPage + 1, order, search };
    if (isOpenPemohon === false && updatePemohonResult) {
      listPemohon(params);
    }
    if (isOpenPemohon === false && tambahPemohonResult) {
      listPemohon(params);
    }
    return()=>console.log(permId)    
  }, [
    currentPage,
    order,
    search,
    isOpenPemohon,
    updatePemohonResult,
    tambahPemohonResult,
  ]);

  const deleteConfirmDialog = ({ pemohon }) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deletePemohon(pemohon.id);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          timer: 2000,
          timerProgressBar: true,
        });
        Toast.fire(
          "Deleted!",
          `${pemohon.nama_pemohon} telah dihapus`,
          "success"
        );
      }
    });
  };
  if (getListPemohonError) {
    return (
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-3">
          <h1>{getListPemohonError.message}</h1>
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-3 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="text-center flex justify-between w-full">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                DAFTAR PEMOHON
              </h3>
              <div className="relative flex flex-wrap items-stretch mb-3 justify-between gap-1">
                <span className="z-10 h-full leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-2 py-1">
                  <i className="fas fa-search"></i>
                </span>
                <input
                  type="text"
                  placeholder="Cari Pemohon"
                  className="px-2 py-1 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline pl-10 w-full md:w-7/12"
                  onChange={(e) => {
                    setTimeout(() => {
                      setSearch(e.target.value);
                    }, 500);
                  }}
                />
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-3 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150 w-full md:w-2/5"
                  type="button"
                  onClick={openModalPemohon}
                >
                  Pemohon Baru
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {getListPemohonLoading && (
            <div className="w-full absolute justify-center flex z-30 m-auto flex-col items-center bg-white h-32">
              <BeatLoader color="#36d7b7" size={20} />
            </div>
          )}

          {getListPemohonResult ? (
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    className={
                      "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    <span>NO</span>
                  </th>
                  <th
                    className={
                      "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    <a
                      href="/#"
                      className="flex justify-between"
                      onClick={(e) => {
                        // const pattern = /^\-{0,}?(id)$/g;
                        e.preventDefault();
                        setOrder(
                          `${
                            order === "nama_pemohon"
                              ? "-nama_pemohon"
                              : "nama_pemohon"
                          }`
                        );
                      }}
                    >
                      <span>Nama Pemohon</span>
                      <i
                        className={`fa-solid fa-sort${
                          /^-{0,}?(nama_pemohon)$/g.test(order)
                            ? order === "nama_pemohon"
                              ? "-up"
                              : "-down"
                            : ""
                        }`}
                      ></i>
                    </a>
                  </th>
                  <th
                    className={
                      "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    <a
                      href="/#"
                      className="flex justify-between"
                      onClick={(e) => {
                        // const pattern = /^\-{0,}?(id)$/g;
                        e.preventDefault();
                        setOrder(
                          `${order === "gender" ? "-gender" : "gender"}`
                        );
                      }}
                    >
                      <span>Gender</span>
                      <i
                        className={`fa-solid fa-sort${
                          /^-{0,}?(gender)$/g.test(order)
                            ? order === "gender"
                              ? "-up"
                              : "-down"
                            : ""
                        }`}
                      ></i>
                    </a>
                  </th>
                  <th
                    className={
                      "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    <a
                      href="/#"
                      className="flex justify-between"
                      onClick={(e) => {
                        // const pattern = /^\-{0,}?(id)$/g;
                        e.preventDefault();
                        setOrder(`${order === "nik" ? "-nik" : "nik"}`);
                      }}
                    >
                      <span>NIK</span>
                      <i
                        className={`fa-solid fa-sort${
                          /^-{0,}?(nik)$/g.test(order)
                            ? order === "nik"
                              ? "-up"
                              : "-down"
                            : ""
                        }`}
                      ></i>
                    </a>
                  </th>
                  <th
                    className={
                      "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    <a
                      href="/#"
                      className="flex justify-between"
                      onClick={(e) => {
                        // const pattern = /^\-{0,}?(id)$/g;
                        e.preventDefault();
                        setOrder(
                          `${
                            order === "alamat__nama_desa"
                              ? "-alamat__nama_desa"
                              : "alamat__nama_desa"
                          }`
                        );
                      }}
                    >
                      <span>Alamat</span>
                      <i
                        className={`fa-solid fa-sort${
                          /^-{0,}?(alamat__nama_desa)$/g.test(order)
                            ? order === "alamat__nama_desa"
                              ? "-up"
                              : "-down"
                            : ""
                        }`}
                      ></i>
                    </a>
                  </th>

                  <th
                    className={
                      "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    Menu
                  </th>
                </tr>
              </thead>
              <tbody>
                {getListPemohonResult.results &&
                  getListPemohonResult.results.map((pemohon, index) => {
                    return (
                      <tr key={pemohon.id}>
                        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3">
                          {currentPage * 10 + index + 1}.
                        </td>
                        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3">
                          <i className="fas fa-user text-orange-500 mr-2"></i>{" "}
                          {pemohon.nama_pemohon}
                        </td>
                        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3">
                          {pemohon.gender}
                        </td>
                        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3">
                          {pemohon.nik}
                        </td>
                        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3">
                          {pemohon.alamat.nama_desa},{" "}
                          {pemohon.alamat.nama_kecamatan}
                        </td>
                        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-right">
                          <CommonDropdown>
                            <DropDownItem
                              title="Edit"
                              cmd={(e) =>
                                openModalPemohon({
                                  selectedPemohon: pemohon,
                                  mode: "edit",
                                })
                              }
                            />
                            <DropDownItem
                              title="Hapus"
                              cmd={(e) => deleteConfirmDialog({ pemohon })}
                            />
                          </CommonDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : null}
        </div>
        {getListPemohonResult ? (
          <>
            <div className="py-4 px-2 justify-end flex bg-white">
              <nav className="block">
                <ReactPaginate
                  previousLabel={<i className="fas fa-chevron-left -ml-px"></i>}
                  nextLabel={<i className="fas fa-chevron-right -mr-px"></i>}
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={Math.ceil(getListPemohonResult.count / 10)}
                  pageRangeDisplayed={4}
                  marginPagesDisplayed={2}
                  onPageChange={(e) => {
                    setCurrentPage(e.selected);
                  }}
                  containerClassName="flex pl-0 rounded list-none flex-wrap"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  activeClassName="page-link-active"
                  forcePage={currentPage}
                  onClick={(clickEvent) => {
                    // setCurrentPage(clickEvent.selected + 1);
                  }}
                />
              </nav>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getListPemohonError: state.pemohons.getListPemohonError,
  getListPemohonLoading: state.pemohons.getListPemohonLoading,
  getListPemohonResult: state.pemohons.getListPemohonResult,
  deletePemohonResult: state.pemohons.deletePemohonResult,
  isOpenPemohon: state.pemohons.isOpenPemohon,
  tambahPemohonResult: state.pemohons.tambahPemohonResult,
  updatePemohonResult: state.pemohons.updatePemohonResult,
});

export default connect(mapStateToProps, { listPemohon, deletePemohon })(
  CardTablePemohons
);
